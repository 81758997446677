import React from 'react';

const JobRequestForm = () => {
  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <h2>Request a Job Listing</h2>
      <p>Let us know what kind of job opportunities you'd like to see added.</p>
      <div style={{ position: 'relative', height: '1956px', maxWidth: '100%', background: '#f9f9f9', borderRadius: '8px', boxShadow: '0 0 20px rgba(0,0,0,0.1)' }}>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSduJguDCp-tk8NZFoQ_zOoUEMedNn8cyGhsSjusM2er8xhb9w/viewform?embedded=true"
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '1956px', border: 'none' }}
          title="Job Request Form"
          allowFullScreen
        >
          Loading…
        </iframe>
      </div>
    </div>
  );
};

export default JobRequestForm;
