import React from 'react';

const Privacy = () => {
  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p className="mb-4">
        Welcome to Internify Job Portal. This Privacy Policy describes how we collect, use, and share your personal information when you use our services. 
      </p>

      <h2 className="text-2xl font-bold mb-4">Beta Disclaimer</h2>
      <p className="mb-4">
        Please note that the Internify Job Portal is currently in beta. As such, you may experience occasional bugs, glitches, or other issues as we continue to develop and improve our platform. We appreciate your understanding and feedback during this phase.
      </p>

      <h2 className="text-2xl font-bold mb-4">Information We Collect</h2>
      <p className="mb-4">
        We collect information that you provide to us directly, such as when you create an account, fill out a form, or apply for a job. This information may include your name, email address, phone number, resume, and other details you provide.
      </p>
      <p className="mb-4">
        Additionally, we may collect information automatically when you use our services, such as your IP address, browser type, operating system, referring URLs, and other technical information. We also use cookies and similar technologies to collect information about your interactions with our site.
      </p>
      
      <h2 className="text-2xl font-bold mb-4">How We Use Your Information</h2>
      <p className="mb-4">
        We use the information we collect to operate, maintain, and improve our services. This includes using your information to:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Provide job matching services.</li>
        <li>Communicate with you about your account and our services.</li>
        <li>Send you updates and other promotional materials.</li>
        <li>Understand how users interact with our platform to improve user experience.</li>
        <li>Analyze site usage and trends to enhance our services.</li>
        <li>Cross-sell and market our own products and services.</li>
        <li>Personalize your experience on our site.</li>
        <li>Provide customer support and respond to your inquiries.</li>
        <li>Prevent fraudulent activities and enhance security measures.</li>
        <li>Comply with legal obligations and enforce our terms of service.</li>
      </ul>

      <h2 className="text-2xl font-bold mb-4">Marketing and Advertising</h2>
      <p className="mb-4">
        We may use your information for marketing purposes, such as sending you promotional emails or notifications about our products and services. We may also use your information to display targeted advertisements on our site or third-party sites.
      </p>
      <p className="mb-4">
        For marketing purposes, we may:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Analyze your preferences and behavior to tailor marketing messages.</li>
        <li>Use cookies and similar technologies to track your interactions with our site and ads.</li>
        <li>Share data with third-party marketing partners to improve our advertising efforts.</li>
      </ul>

      <h2 className="text-2xl font-bold mb-4">Advertisement Disclaimer</h2>
      <p className="mb-4">
        Our website relies on ad revenue. If there are any misleading ads displayed, we are not responsible. However, reporting such ads can help us to avoid them in the future. Please note that we depend on third-party advertisers and therefore have no control over the ads displayed.
      </p>

      <h2 className="text-2xl font-bold mb-4">Cross-Selling</h2>
      <p className="mb-4">
        We may use your information to cross-sell our own products and services. This means that we may recommend products or services that we believe may be of interest to you based on your usage of our platform.
      </p>
      <p className="mb-4">
        For cross-selling purposes, we may:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Analyze your activity and preferences on our platform.</li>
        <li>Recommend related services and products that complement your interests.</li>
        <li>Send you personalized offers and promotions.</li>
      </ul>

      <h2 className="text-2xl font-bold mb-4">Third-Party Services</h2>
      <p className="mb-4">
        We use third-party services to help us understand how our users interact with our platform and to provide certain functionalities. These third-party services include:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li><b>Microsoft Clarity:</b> We use Microsoft Clarity to analyze user behavior and improve our site. Microsoft Clarity collects data on user interactions, such as clicks, scrolls, and page views.</li>
        <li><b>Google Analytics:</b> We use Google Analytics to track and report on site traffic and user interactions. Google Analytics collects data such as IP addresses, browser types, and referring URLs.</li>
        <li><b>Tawk Chat Bot:</b> We use Tawk Chat Bot to provide real-time customer support and interaction. Tawk Chat Bot may collect information such as your name, email address, and chat transcripts.</li>
        <li><b>Web Push Notifications:</b> We use web push notifications to send you updates and alerts. Web push notifications may collect information such as your IP address and browser type.</li>
      </ul>
      <p className="mb-4">
        These third-party services help us to:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Understand user behavior and improve our platform.</li>
        <li>Provide better customer support and engagement.</li>
        <li>Deliver personalized content and notifications.</li>
        <li>Ensure the security and performance of our site.</li>
      </ul>

      <h2 className="text-2xl font-bold mb-4">Sharing Your Information</h2>
      <p className="mb-4">
        We may share your information with third parties for various reasons, including to provide you with our services, comply with legal obligations, or with your consent. We do not sell your personal information to third parties.
      </p>
      <p className="mb-4">
        We may share your information with the following entities:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Service Providers: We may share your information with third-party service providers who perform services on our behalf, such as hosting providers, payment processors, and email service providers.</li>
        <li>Business Partners: We may share your information with our business partners to offer you certain products, services, or promotions.</li>
        <li>Legal Authorities: We may disclose your information to law enforcement or other governmental authorities in response to a legal request or to comply with legal obligations.</li>
        <li>Affiliates: We may share your information with our affiliates for business purposes, such as providing joint content and services.</li>
      </ul>
      <p className="mb-4">
        Additionally, we may share your information:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>To comply with any applicable law, regulation, legal process, or governmental request.</li>
        <li>To enforce our terms of service, including investigation of potential violations.</li>
        <li>To detect, prevent, or otherwise address fraud, security, or technical issues.</li>
        <li>To protect the rights, property, or safety of Internify, our users, or the public as required or permitted by law.</li>
      </ul>

      <h2 className="text-2xl font-bold mb-4">External Job Listings</h2>
      <p className="mb-4">
        Some job listings on Internify Job Portal may originate from external job boards such as Naukri, LinkedIn, and others. We do not have control over these external job listings and do not claim any copyright over the content provided by these external sources. Our intention is to facilitate job searches by aggregating listings from various sources for your convenience.
      </p>
      <p className="mb-4">
        If you are a copyright holder and believe that any content on our site infringes on your copyrights, please contact us at <a href="mailto:onboarding@internify.online" className="text-blue-500">onboarding@internify.online</a>. We will promptly address your concerns and remove any infringing content upon verification.
      </p>

      <h2 className="text-2xl font-bold mb-4">Your Rights</h2>
      <p className="mb-4">
        You have certain rights regarding your personal information, including the right to access, correct, or delete your data. You can also opt-out of receiving promotional communications from us at any time. If you wish to exercise any of these rights, please contact us at <a href="mailto:onboarding@internify.online" className="text-blue-500">onboarding@internify.online</a>.
      </p>
      <p className="mb-4">
        Your rights include:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Right to Access: You have the right to request a copy of the personal information we hold about you.</li>
        <li>Right to Correction: You have the right to request the correction of any inaccurate or incomplete information we hold about you.</li>
        <li>Right to Deletion: You have the right to request the deletion of your personal information under certain circumstances.</li>
        <li>Right to Object: You have the right to object to the processing of your personal information for direct marketing purposes.</li>
        <li>Right to Withdraw Consent: If we process your personal information based on your consent, you have the right to withdraw your consent at any time.</li>
        <li>Right to Data Portability: You have the right to request the transfer of your personal information to another service provider in a structured, commonly used, and machine-readable format.</li>
        <li>Right to Restrict Processing: You have the right to request the restriction of the processing of your personal information under certain circumstances.</li>
      </ul>

      <h2 className="text-2xl font-bold mb-4">Security</h2>
      <p className="mb-4">
        We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.
      </p>
      <p className="mb-4">
        Our security measures include:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Encryption: We use encryption to protect sensitive information transmitted online.</li>
        <li>Access Controls: We restrict access to personal information to authorized personnel only.</li>
        <li>Security Audits: We conduct regular security audits to identify and address vulnerabilities.</li>
        <li>Data Anonymization: Where possible, we anonymize data to minimize the risk of unauthorized access.</li>
      </ul>
      <p className="mb-4">
        Additionally, we employ the following practices:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Employee Training: We train our employees on data protection best practices.</li>
        <li>Incident Response: We have a response plan in place for potential data breaches.</li>
        <li>Continuous Monitoring: We continuously monitor our systems for potential security threats.</li>
        <li>Regular Updates: We regularly update our systems and software to address security vulnerabilities.</li>
      </ul>

      <h2 className="text-2xl font-bold mb-4">Data Retention</h2>
      <p className="mb-4">
        We retain your personal information for as long as necessary to provide our services, comply with legal obligations, resolve disputes, and enforce our agreements. When we no longer need to use your information, we will take steps to delete it or anonymize it.
      </p>
      <p className="mb-4">
        Our data retention policy includes:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Retention Periods: We retain personal information for varying periods depending on the nature of the information and the purpose for which it was collected.</li>
        <li>Deletion Procedures: We have established procedures for securely deleting personal information when it is no longer needed.</li>
        <li>Data Anonymization: Where appropriate, we anonymize personal information to retain its usefulness while protecting your privacy.</li>
      </ul>
      <p className="mb-4">
        In specific scenarios, our data retention practices include:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Job Application Data: We retain job application data for a period necessary to consider your application and fulfill our reporting obligations.</li>
        <li>Account Information: We retain account information for as long as your account is active and for a reasonable period thereafter in case you decide to reactivate our services.</li>
        <li>Marketing Data: We retain marketing data until you opt-out of receiving promotional communications from us.</li>
      </ul>

      <h2 className="text-2xl font-bold mb-4">Children's Privacy</h2>
      <p className="mb-4">
        Our services are not intended for children under the age of 13. We do not knowingly collect or solicit personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will delete that information as quickly as possible.
      </p>
      <p className="mb-4">
        Our practices regarding children's privacy include:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Parental Consent: We require parental consent for any user under the age of 13 who wishes to use our services.</li>
        <li>Data Minimization: We minimize the collection of personal information from users under the age of 13.</li>
        <li>Deletion Requests: Parents can request the deletion of their child's personal information by contacting us at <a href="mailto:onboarding@internify.online" className="text-blue-500">onboarding@internify.online</a>.</li>
      </ul>

      <h2 className="text-2xl font-bold mb-4">International Data Transfers</h2>
      <p className="mb-4">
        Your information may be transferred to and maintained on servers or databases located outside your country or other governmental jurisdiction where the privacy laws may not be as protective as those in your jurisdiction. By using our services, you consent to the transfer of your information to these facilities.
      </p>
      <p className="mb-4">
        Our practices regarding international data transfers include:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Compliance: We comply with legal requirements for data transfers, including using standard contractual clauses approved by relevant authorities.</li>
        <li>Data Security: We ensure that transferred data is subject to appropriate security measures to protect your privacy.</li>
        <li>Data Integrity: We maintain the integrity of your personal information during and after the transfer process.</li>
      </ul>

      <h2 className="text-2xl font-bold mb-4">Links to Other Sites</h2>
      <p className="mb-4">
        Our site may contain links to other websites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
      </p>
      <p className="mb-4">
        When visiting third-party sites, we recommend:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Reviewing the privacy policies of those sites to understand how they collect and use your data.</li>
        <li>Ensuring that the site is secure before providing any personal information.</li>
        <li>Being cautious about sharing sensitive information on unfamiliar websites.</li>
      </ul>

      <h2 className="text-2xl font-bold mb-4">Changes to This Privacy Policy</h2>
      <p className="mb-4">
        We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. You are advised to review this Privacy Policy periodically for any changes. Continued use of our services after any changes to this Privacy Policy constitutes your acceptance of the new terms.
      </p>
      <p className="mb-4">
        When making changes to this Privacy Policy, we will:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Post the updated policy on our website with a revised "Last Updated" date.</li>
        <li>Notify you via email or a prominent notice on our site prior to the change becoming effective.</li>
        <li>Provide a summary of the key changes to help you understand the updates.</li>
      </ul>

      <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
      <p className="mb-4">
        If you have any questions about this Privacy Policy, or if you wish to request the removal of any content, please contact us at: 
        <a href="mailto:onboarding@internify.online" className="text-blue-500"> onboarding@internify.online</a>
      </p>
      <p className="mb-4">
        For any further assistance, please feel free to reach out to our customer support team through the contact information provided above. We are committed to addressing your concerns and ensuring the protection of your personal information.
      </p>
      <p className="mb-4">
        We value your feedback and are always here to help with any questions or concerns you may have about your privacy and data protection.
      </p>
    </div>
  );
};

export default Privacy;
