import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { db } from '../firebase/firebaseConfig';
import { addDoc, collection, getDocs, query, where, updateDoc, serverTimestamp } from 'firebase/firestore';
import CreatableSelect from 'react-select/creatable';
import dayjs from 'dayjs';

const JobScraper = () => {
  const [url, setUrl] = useState('');
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedJob, setSelectedJob] = useState(null); // For previewing job details
  const [companyInput, setCompanyInput] = useState({ value: '', label: '', categories: [] });
  const [location, setLocation] = useState('');
  const [salary, setSalary] = useState('Not Disclosed');
  const [salaryType, setSalaryType] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('Internship');
  const [experience, setExperience] = useState('Freshers');
  const [expiryDate, setExpiryDate] = useState(() => {
    const defaultExpiryDate = new Date();
    defaultExpiryDate.setDate(defaultExpiryDate.getDate() + 21);
    return defaultExpiryDate.toISOString().split('T')[0];
  });
  const [jobStatus, setJobStatus] = useState('Active');
  const [featured, setFeatured] = useState(false);
  const [externalJob, setExternalJob] = useState(false);
  const [categories, setCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state

  useEffect(() => {
    const fetchCategoriesAndCompanies = async () => {
      try {
        const categoriesSnapshot = await getDocs(collection(db, 'jobCategories'));
        const categoriesList = categoriesSnapshot.docs.map(doc => ({
          value: doc.data().name,
          label: doc.data().name
        }));
        setAllCategories(categoriesList);

        const companiesSnapshot = await getDocs(collection(db, 'companycategories'));
        const companiesList = companiesSnapshot.docs.map(doc => ({
          value: doc.data().name,
          label: doc.data().name
        }));
        setAllCompanies(companiesList);
      } catch (error) {
        console.error('Error fetching categories or companies:', error);
      }
    };
    fetchCategoriesAndCompanies();
  }, []);

  const fetchJobs = async () => {
    if (!url) {
      setError('Please enter a valid URL.');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const response = await axios.get(`http://localhost:1989/api/scrape-jobs?url=${encodeURIComponent(url)}`);
      setJobs(response.data);
    } catch (err) {
      console.error('Error fetching jobs:', err);
      setError('Failed to fetch jobs.');
    } finally {
      setLoading(false);
    }
  };

  const fetchJobDetails = async (jobUrl) => {
    try {
      const response = await axios.get(`http://localhost:1989/api/fetch-job-details?jobUrl=${encodeURIComponent(jobUrl)}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching job details:', error);
      return { description: 'N/A', responsibilities: 'Check company website for more details.', requirements: 'Check company website for more details.', benefits: 'Check company website for more details.', closingNote: 'N/A' };
    }
  };

  const addJobToPortal = async (job) => {
    const company = job.company || companyInput.value;
    if (!categories || categories.length === 0) {
      setCategories([]);
    }

    try {
      const selectedCategories = categories && categories.length > 0 ? categories.map(category => category.label) : [];
      const companiesRef = collection(db, 'companycategories');
      const q = query(companiesRef, where('name', '==', company));
      const existingCompanies = await getDocs(q);

      if (existingCompanies.empty) {
        await addDoc(companiesRef, { name: company, categories: companyInput.categories || [] });
      } else {
        const existingCompanyDoc = existingCompanies.docs[0];
        await updateDoc(existingCompanyDoc.ref, { categories: companyInput.categories || [] });
      }

      await addDoc(collection(db, 'jobs'), {
        title: job.title || 'N/A',
        company,
        location: location || job.location,
        salary: salaryType ? `${salary} ${salaryType}` : salary,
        description: description || job.description || 'N/A',
        type,
        link: job.link || 'N/A',
        featured,
        experience,
        expiryDate,
        jobStatus,
        externalJob,
        categories: selectedCategories,
        date: serverTimestamp(),
      });

      alert(`Job "${job.title}" has been added to the portal.`);
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error adding job:', error);
      alert('Failed to add job.');
    }
  };

  const handlePreview = async (job) => {
    const jobDetails = await fetchJobDetails(job.link);
    setSelectedJob({ ...job, ...jobDetails, location: job.location, company: companyInput.value || job.company });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Job Scraper</h1>

      <input
        className="border p-2 w-full mb-2"
        type="text"
        placeholder="Enter job listing URL"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
      />

      <button className="bg-blue-500 text-white px-4 py-2 rounded mb-4" onClick={fetchJobs}>
        Fetch Jobs
      </button>

      {error && <p className="text-red-500">{error}</p>}
      {loading && <div>Loading...</div>}

      {jobs.length > 0 && (
        <div>
          <h2 className="text-xl font-bold mb-4">Jobs Found:</h2>
          <ul>
            {jobs.map((job, index) => (
              <li key={index} className="border p-4 mb-4">
                <h3 className="text-lg font-bold">{job.title}</h3>
                <div>Company: {job.company || <CreatableSelect value={companyInput} onChange={setCompanyInput} options={allCompanies} placeholder="Select or create company..." />}</div>
                <div>Location: {job.location}</div>
                <div>Category: {categories.map(cat => cat.label).join(', ') || 'N/A'}</div>
                <div>Salary: {salary}</div>
                <div>Description: {job.description}</div>

                <button className="bg-green-500 text-white px-4 py-2 rounded mt-2" onClick={() => handlePreview(job)}>
                  Preview & Confirm
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 overflow-auto">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-3xl mx-auto max-h-[80%] overflow-auto">
            <h2 className="text-xl font-bold mb-4">Confirm Job Details</h2>
            {selectedJob && (
              <>
                <div><strong>Title:</strong> {selectedJob.title}</div>
                <div><strong>Company:</strong> {selectedJob.company}</div>
                <div>
                  <strong>Location:</strong> 
                  <input type="text" className="border p-2 w-full mb-2" value={location} onChange={(e) => setLocation(e.target.value)} />
                </div>
                <div>
                  <strong>Salary:</strong> 
                  <input type="text" className="border p-2 w-full mb-2" value={salary} onChange={(e) => setSalary(e.target.value)} />
                </div>
                <div>
                  <strong>Type:</strong>
                  <select className="border p-2 w-full mb-2" value={type} onChange={(e) => setType(e.target.value)}>
                    <option value="Job">Job</option>
                    <option value="Internship">Internship</option>
                  </select>
                </div>
                <div>
                  <strong>Experience:</strong>
                  <select className="border p-2 w-full mb-2" value={experience} onChange={(e) => setExperience(e.target.value)}>
                    <option value="Freshers">Freshers</option>
                    <option value="0-1 years">0-1 years</option>
                    <option value="1-2 years">1-2 years</option>
                    <option value="2+ years">2+ years</option>
                  </select>
                </div>
                <div>
                  <strong>Expiry Date:</strong> 
                  <input type="date" className="border p-2 w-full mb-2" value={expiryDate} onChange={(e) => setExpiryDate(e.target.value)} />
                </div>
                <div><strong>Description:</strong> {selectedJob.description}</div>
                <div><strong>Responsibilities:</strong> {selectedJob.responsibilities || 'Check further details on the company website'}</div>
                <div><strong>Requirements:</strong> {selectedJob.requirements || 'Check further details on the company website'}</div>
                <div><strong>Benefits:</strong> {selectedJob.benefits || 'Check further details on the company website'}</div>
                <div><strong>Closing Note:</strong> {selectedJob.closingNote || 'N/A'}</div>

                <div className="flex justify-between mt-4">
                  <button className="bg-blue-500 text-white px-4 py-2 rounded" onClick={() => addJobToPortal(selectedJob)}>
                    Confirm & Add Job
                  </button>
                  <button className="bg-gray-500 text-white px-4 py-2 rounded" onClick={closeModal}>
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default JobScraper;
