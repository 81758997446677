import React from 'react';

const ExternalJobNote = () => {
  return (
    <div className="mt-4 p-4 bg-yellow-100 text-yellow-800 border border-yellow-300 rounded">
<p>
  <strong>Important Notice:</strong> The job listings provided here are sourced from external websites. While we strive to keep the information accurate and up-to-date, we do not have control over the content on those sites. Job details, including application links and deadlines, may change without notice. Please verify all information directly on the external website before applying. We are not responsible for any discrepancies or changes after redirection.
</p>
<br></br>
<p>
  These opportunities were not posted on our platform and are from the official career page of their respective companies.
</p>
<br></br>
<p>
  We have filtered these jobs for you so that you get everything in one place on our platform. Share these opportunities with your friends as well to let them know.
</p>
<br></br>
<p>
  - Team Internify
</p>

    </div>
  );
};

export default ExternalJobNote;
