import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link, useLocation } from 'react-router-dom';
import jobData from '../data/jobs.json'; // Importing the jobs data

const randomColors = [
  "#fae5d3", "#d3fae5", "#e5d3fa", "#f9f9d1", "#d1f9e3", "#d1e1f9", 
  "#f9d1d1", "#d1f9d9", "#f9e8d1", "#f2f2d1", "#d1f2f2", "#f2d1f2", 
  "#e0f2d1", "#d1f2e0", "#f2d1e0", "#e0d1f2", "#f2e0d1", "#d1f2e8"
];

const jobsPerPage = 12;

const isMobile = () => {
  return window.innerWidth <= 768; // Adjust the width according to your mobile breakpoint
};

const AdBanner = ({ id }) => {
  useEffect(() => {
    const adContainer = document.getElementById(id);

    if (adContainer) {
      while (adContainer.firstChild) {
        adContainer.removeChild(adContainer.firstChild);
      }

      const adScript = document.createElement('script');
      adScript.async = true;
      adScript.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4408090261163070";
      adScript.crossOrigin = "anonymous";

      const adIns = document.createElement('ins');
      adIns.className = "adsbygoogle";
      adIns.style.display = "block";
      adIns.setAttribute("data-ad-format", "fluid");
      adIns.setAttribute("data-ad-layout-key", "-gw-3+1f-3d+2z");
      adIns.setAttribute("data-ad-client", "ca-pub-4408090261163070");
      adIns.setAttribute("data-ad-slot", "2223508191");

      const adPushScript = document.createElement('script');
      adPushScript.innerHTML = "(adsbygoogle = window.adsbygoogle || []).push({});";

      adContainer.appendChild(adIns);
      adContainer.appendChild(adScript);
      adContainer.appendChild(adPushScript);
    }
  }, [id]);

  return <div id={id}></div>;
};

const JobSearch = () => {
  const [jobs, setJobs] = useState([]);
  const [visibleJobs, setVisibleJobs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState("Loading Internify website...");
  const [jobType, setJobType] = useState('All');
  const [sortBy, setSortBy] = useState('date');
  const [seoData, setSeoData] = useState({});
  const [city, setCity] = useState('');

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryFromUrl = queryParams.get('search') || '';
    setSearchQuery(queryFromUrl);

    const fetchJobs = () => {
      setLoading(true);
      try {
        const sortedJobs = jobData
          .map((job, index) => ({
            ...job,
            color: randomColors[index % randomColors.length],
            date: new Date(job.date.seconds * 1000) // Convert the Unix timestamp to a Date object
          }))
          .sort((a, b) => b.date - a.date); // Sort by newest first

        setJobs(sortedJobs);
        setVisibleJobs(sortedJobs.slice(0, jobsPerPage));
      } catch (error) {
        console.error("Error loading jobs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
    fetchCity();
    fetchSEOData();
  }, [location.search]);

  const fetchCity = async () => {
    try {
      const response = await fetch('https://ipapi.co/json/');
      const data = await response.json();
      setCity(data.city || 'Your City');
    } catch (error) {
      console.error("Error fetching city:", error);
      setCity('Your City');
    }
  };

  const fetchSEOData = async () => {
    setSeoData({
      pageTitle: "Internify - The best Internships & Job portal in India",
      metaDescription: "Find the best jobs in India on Internify.",
      metaKeywords: "jobs, internships, India"
    });
  };

  const filteredJobs = jobs.filter(job => {
    const matchesType = jobType === 'All' || job.type === jobType;
    const matchesSearchQuery = searchQuery === '' || 
      job.title.toLowerCase().includes(searchQuery.toLowerCase()) || 
      job.company.toLowerCase().includes(searchQuery.toLowerCase()) ||
      job.location.toLowerCase().includes(searchQuery.toLowerCase());
    
    return matchesType && matchesSearchQuery;
  });

  const handleLoadMore = () => {
    const nextPage = currentPage + 1;
    const startIndex = currentPage * jobsPerPage;
    const endIndex = startIndex + jobsPerPage;
    const moreJobs = filteredJobs.slice(startIndex, endIndex);
    
    setVisibleJobs(prevJobs => [...prevJobs, ...moreJobs]);
    setCurrentPage(nextPage);
  };

  const hasMoreJobs = visibleJobs.length < filteredJobs.length;

  const isRecentlyPosted = (jobDate) => {
    const currentDate = new Date();
    const timeDifference = currentDate - jobDate;
    const hoursDifference = timeDifference / (1000 * 60 * 60);
    return hoursDifference <= 24;
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-white">
        <div className="text-center">
          <div className="text-2xl font-semibold mb-4 text-gray-900">
            {loadingText}
          </div>
          <div id="loading-text" className="text-lg text-gray-600 transition-opacity duration-300">
            {loadingText}
          </div>
        </div>
      </div>
    );
  }

  return (
    <HelmetProvider>
      <Helmet>
        <title>{seoData.pageTitle}</title>
        <meta name="description" content={seoData.metaDescription} />
        <meta name="keywords" content={seoData.metaKeywords} />
      </Helmet>
      <div className="max-w-4xl mx-auto p-4">
        <div className="mb-4 flex flex-col sm:flex-row sm:items-center">
          <input
            type="text"
            className="border border-gray-400 rounded py-2 px-4 mb-2 sm:mb-0 sm:mr-2"
            placeholder="Search by job title, company, or city"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              setCurrentPage(1);
              setVisibleJobs(filteredJobs.slice(0, jobsPerPage));
            }}
          />
          <select
            className="border border-gray-400 rounded py-2 px-4 mb-2 sm:mb-0 sm:mr-2"
            value={jobType}
            onChange={(e) => {
              setJobType(e.target.value);
              setCurrentPage(1);
              setVisibleJobs(filteredJobs.slice(0, jobsPerPage));
            }}
          >
            <option value="All">Jobs & Internships</option>
            <option value="Job">Jobs</option>
            <option value="Internship">Internships</option>
          </select>
          <select
            className="border border-gray-400 rounded py-2 px-4"
            value={sortBy}
            onChange={(e) => {
              setSortBy(e.target.value);
              setCurrentPage(1);
              setVisibleJobs(filteredJobs.slice(0, jobsPerPage));
            }}
          >
            <option value="date">Sort by: Latest</option>
            <option value="relevance">Sort by: Relevance</option>
          </select>
        </div>
        {visibleJobs.length === 0 ? (
          <p>No jobs available.</p>
        ) : (
          <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
            {visibleJobs.map((job, index) => (
              <React.Fragment key={job.id}>
                <div
                  className="rounded-lg shadow-md flex flex-col justify-between"
                  style={{
                    borderRadius: "15px",
                    border: "1px solid #e0e0e0",
                    overflow: "hidden",
                  }}
                >
                  <div
                    className="p-4"
                    style={{
                      backgroundColor: job.color,
                      borderBottom: "1px solid #e0e0e0",
                      borderRadius: "15px 15px 0 0",
                    }}
                  >
                    <div className="mb-4">
                      <div className="text-left">
                        <h2 className="text-2xl font-bold mb-1">{job.title}</h2>
                        <div className="text-gray-500 text-lg">{job.company}</div>
                      </div>
                    </div>
                    <div className="flex flex-wrap mb-4">
                      <span className="bg-white text-gray-600 px-3 py-1 rounded-full mr-2 mb-2">
                        {job.type || ""}
                      </span>
                      <span className="bg-white text-gray-600 px-3 py-1 rounded-full mr-2 mb-2">
                        {job.experience || ""}
                      </span>
                    </div>
                    <div className="flex items-center mb-2 text-gray-600">
                      <span>{job.location}</span>
                    </div>
                    {isRecentlyPosted(job.date) && (
                      <span className="bg-blue-300 text-white px-3 py-1 rounded-full">
                        Recently Added
                      </span>
                    )}
                  </div>
                  <div
                    className="flex justify-between items-center bg-white p-4"
                    style={{
                      borderRadius: "0 0 15px 15px",
                    }}
                  >
                    <div className="text-gray-600 flex items-center">
                      <span className="text-lg font-bold">
                      {job.salary ? `₹${job.salary}` : "Not disclosed"}
                      </span>
                    </div>
                    <Link
                      to={`/job/${job.id}`}
                      className="bg-black text-white px-4 py-2 rounded-full"
                    >
                      Details
                    </Link>
                  </div>
                </div>

                {isMobile() && (
                  <div key={`ad-${job.id}-${index}`} className="my-4">
                    <AdBanner id={`ad-container-${index}`} />
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        )}
        {hasMoreJobs && (
          <div className="flex justify-center mt-4">
            <button
              onClick={handleLoadMore}
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Load More
            </button>
          </div>
        )}
      </div>
    </HelmetProvider>
  );
};

export default JobSearch;
