import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { db } from '../firebase/firebaseConfig';
import { collection, getDocs, query, orderBy, limit, startAfter, where, doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../context/AuthContext';

const randomColors = [
  "#fae5d3", "#d3fae5", "#e5d3fa", "#f9f9d1", "#d1f9e3", "#d1e1f9", 
  "#f9d1d1", "#d1f9d9", "#f9e8d1", "#f2f2d1", "#d1f2f2", "#f2d1f2", 
  "#e0f2d1", "#d1f2e0", "#f2d1e0", "#e0d1f2", "#f2e0d1", "#d1f2e8"
];

const AdBanner = ({ id, delay = 0 }) => {
  useEffect(() => {
    const loadAdScript = () => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = `
        atOptions = {
          'key' : '',
          'format' : 'iframe',
          'height' : 50,
          'width' : 320,
          'params' : {}
        };
      `;
      
      const invokeScript = document.createElement('script');
      invokeScript.type = 'text/javascript';
      // invokeScript.src = 'https://www.topcreativeformat.com/64318eeb6cc3ea5bfe475637cfbc3b3b/invoke.js';
      invokeScript.async = true;

      const adContainer = document.getElementById(id);
      if (adContainer) {
        adContainer.innerHTML = ''; // Clear any previous content
        adContainer.appendChild(script);
        adContainer.appendChild(invokeScript);
      }
    };

    const timeoutId = setTimeout(loadAdScript, delay);

    return () => clearTimeout(timeoutId); // Clean up the timeout if the component is unmounted
  }, [id, delay]);

  return <div id={id}></div>;
};

const isMobile = () => {
  return window.innerWidth <= 768; // Adjust the width according to your mobile breakpoint
};

const JobList = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState("Loading Internify website...");
  const [city, setCity] = useState('');
  const [moreJobsAvailable, setMoreJobsAvailable] = useState(true);
  const [lastVisible, setLastVisible] = useState(null);
  const [jobType, setJobType] = useState('All');
  const [sortBy, setSortBy] = useState('date');
  const [seoData, setSeoData] = useState({});
  const [currentUserName, setCurrentUserName] = useState('');
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const jobsPerPage = 6;

  const loadingTexts = [
    "Loading Internify website...",
    // Add additional loading texts in different languages...
  ];

  useEffect(() => {
    const loadingInterval = setInterval(() => {
      setLoadingText((prevText) => {
        const currentIndex = loadingTexts.indexOf(prevText);
        return loadingTexts[(currentIndex + 1) % loadingTexts.length];
      });
    }, 100);

    return () => clearInterval(loadingInterval);
  }, []);

  useEffect(() => {
    if (currentUser) {
      fetchCurrentUserDetails();
    }
  }, [currentUser]);

  const fetchCurrentUserDetails = async () => {
    try {
      const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
      const userName = userDoc.exists() ? userDoc.data()?.username || "" : "";
      setCurrentUserName(userName);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const handleProfileUpdate = () => {
    navigate('/profile');
  };

  const fetchJobs = async () => {
    setLoading(true);
    try {
      const jobCollection = collection(db, "jobs");
      let jobQuery;

      if (jobType === 'All') {
        jobQuery = query(jobCollection, orderBy('date', 'desc'), limit(jobsPerPage));
      } else {
        jobQuery = query(
          jobCollection,
          where('type', '==', jobType),
          orderBy('date', 'desc'),
          limit(jobsPerPage)
        );
      }

      if (lastVisible) {
        if (jobType === 'All') {
          jobQuery = query(
            jobCollection,
            orderBy('date', 'desc'),
            startAfter(lastVisible),
            limit(jobsPerPage)
          );
        } else {
          jobQuery = query(
            jobCollection,
            where('type', '==', jobType),
            orderBy('date', 'desc'),
            startAfter(lastVisible),
            limit(jobsPerPage)
          );
        }
      }

      const jobSnapshot = await getDocs(jobQuery);

      const jobsData = jobSnapshot.docs.map((doc, index) => {
        const data = doc.data();
        const currentDate = new Date();
        let expiryDate = null;

        if (data.expiryDate) {
          if (data.expiryDate.seconds) {
            expiryDate = new Date(data.expiryDate.seconds * 1000);
          } else {
            expiryDate = new Date(data.expiryDate);
          }
        }

        if ((!expiryDate || expiryDate >= currentDate) && data.jobStatus !== 'Inactive') {
          return {
            ...data,
            id: doc.id,
            color: randomColors[(jobs.length + index) % randomColors.length],
            formattedExpiryDate: expiryDate ? formatExpiryDate(expiryDate) : null,
          };
        }
        return null;
      }).filter(job => job !== null);

      if (jobSnapshot.docs.length < jobsPerPage) {
        setMoreJobsAvailable(false);
      } else {
        setLastVisible(jobSnapshot.docs[jobSnapshot.docs.length - 1]);
      }

      setJobs((prevJobs) => {
        const newJobIds = new Set(prevJobs.map(job => job.id));
        const uniqueJobs = jobsData.filter(job => !newJobIds.has(job.id));
        return [...prevJobs, ...uniqueJobs];
      });
    } catch (error) {
      console.error("Error fetching jobs:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSEOData = async () => {
    const queryParams = new URLSearchParams(location.search);
    const pageIdentifier = queryParams.get('seo') || 'default';

    try {
      const seoQuery = query(collection(db, 'seo'), where('pageIdentifier', '==', pageIdentifier));
      const seoSnapshot = await getDocs(seoQuery);
      const seoDoc = seoSnapshot.docs[0];
      if (seoDoc) {
        setSeoData(seoDoc.data());
      } else {
        setSeoData({});
      }
    } catch (error) {
      console.error("Error fetching SEO data:", error);
      setSeoData({});
    }
  };

  const formatExpiryDate = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    const daySuffix = day % 10 === 1 && day !== 11 ? 'st' : day % 10 === 2 && day !== 12 ? 'nd' : day % 10 === 3 && day !== 13 ? 'rd' : 'th';
    return `Last date to apply: ${day}${daySuffix} ${month} ${year}`;
  };

  const fetchCity = async () => {
    try {
      const response = await fetch('https://ipapi.co/json/');
      const data = await response.json();
      setCity(data.city || 'Your City');
    } catch (error) {
      console.error("Error fetching city:", error);
      setCity('Your City');
    }
  };

  useEffect(() => {
    setJobs([]);
    setLastVisible(null);
    setMoreJobsAvailable(true);
    fetchJobs();
    fetchCity();
    fetchSEOData();
  }, [jobType, sortBy, location]);

  const sortedJobs = jobs.sort((a, b) => {
    if (a.featured && !b.featured) return -1;
    if (!a.featured && b.featured) return 1;
    return 0;
  });

  if (loading && jobs.length === 0) {
    return (
      <div className="flex items-center justify-center h-screen bg-white">
        <div className="text-center">
          <div className="text-2xl font-semibold mb-4 text-gray-900">
            {loadingText}
          </div>
          <div id="loading-text" className="text-lg text-gray-600 transition-opacity duration-300">
            {loadingText}
          </div>
        </div>
      </div>
    );
  }

  return (
    <HelmetProvider>
      <Helmet>
        <title>{seoData.pageTitle || `Internify - The best Internships & Job portal in India`}</title>
        <meta name="description" content={seoData.metaDescription || `Find the best jobs in India on Internify.`} />
        <meta name="keywords" content={seoData.metaKeywords || ""} />
      </Helmet>
      <div className="max-w-4xl mx-auto p-4">
        {currentUser && !currentUserName && (
          <div className="bg-yellow-100 p-4 rounded-lg mb-4 text-center">
            <p className="text-lg text-red-500 font-bold">
              Some of your Details are missing. Please update your details on the{' '}
              <button onClick={handleProfileUpdate} className="text-blue-500 underline">Profile</button> page.
            </p>
          </div>
        )}

        <div className="mb-4 flex flex-col sm:flex-row sm:items-center">
          <select
            className="border border-gray-400 rounded py-2 px-4 mb-2 sm:mb-0 sm:mr-2"
            value={jobType}
            onChange={(e) => {
              setJobs([]);
              setLastVisible(null);
              setMoreJobsAvailable(true);
              setJobType(e.target.value);
            }}
          >
            <option value="All">Jobs & Internships</option>
            <option value="Job">Jobs</option>
            <option value="Internship">Internships</option>
          </select>
          <select
            className="border border-gray-400 rounded py-2 px-4"
            value={sortBy}
            onChange={(e) => {
              setJobs([]);
              setLastVisible(null);
              setMoreJobsAvailable(true);
              setSortBy(e.target.value);
            }}
          >
            <option value="date">Sort by: Latest</option>
            <option value="relevance">Sort by: Relevance</option>
          </select>
        </div>
        {sortedJobs.length === 0 ? (
          <p>No jobs available.</p>
        ) : (
          <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
            {sortedJobs.map((job, index) => (
              <React.Fragment key={job.id}>
                <div
                  className="rounded-lg shadow-md flex flex-col justify-between"
                  style={{
                    borderRadius: "15px",
                    border: "1px solid #e0e0e0",
                    overflow: "hidden",
                  }}
                >
                  <div
                    className="p-4"
                    style={{
                      backgroundColor: job.color,
                      borderBottom: "1px solid #e0e0e0",
                      borderRadius: "15px 15px 0 0",
                    }}
                  >
                    <div className="mb-4">
                      <div className="text-left">
                        <h2 className="text-2xl font-bold mb-1">{job.title}</h2>
                        <div className="text-gray-500 text-lg">{job.company}</div>
                      </div>
                    </div>
                    <div className="flex flex-wrap mb-4">
                      <span className="bg-white text-gray-600 px-3 py-1 rounded-full mr-2 mb-2">
                        {job.type || ""}
                      </span>
                      <span className="bg-white text-gray-600 px-3 py-1 rounded-full mr-2 mb-2">
                        {job.experience || ""}
                      </span>
                      {/* <span className="bg-white text-gray-600 px-3 py-1 rounded-full mr-2 mb-2 text-sm">
                        {job.formattedExpiryDate || ""}
                      </span> */}
                    </div>
                    <div className="flex items-center mb-2 text-gray-600">
                      <span>{job.location}</span>
                    </div>
                  </div>
                  <div
                    className="flex justify-between items-center bg-white p-4"
                    style={{
                      borderRadius: "0 0 15px 15px",
                    }}
                  >
                    <div className="text-gray-600 flex items-center">
                      <span className="text-lg font-bold">
                        {job.salary ? `₹${job.salary}` : "Not disclosed"}
                      </span>
                    </div>
                    <Link
                      to={`/job/${job.id}`}
                      className="bg-black text-white px-4 py-2 rounded-full"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Details
                    </Link>
                  </div>
                </div>

                {/* Ad banner between job cards, only visible on mobile */}
                {isMobile() && (
                  <div key={`ad-${job.id}-${index}`} className="my-4">
                    <AdBanner id={`ad-container-${index}`} delay={index * 1000} />
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        )}
        {moreJobsAvailable && (
          <div className="flex justify-center mt-4">
            <button
              onClick={fetchJobs}
              className="bg-blue-500 text-white px-4 py-2 rounded"
              disabled={loading}
            >
              {loading ? "Loading..." : "Show More"}
            </button>
          </div>
        )}
      </div>
    </HelmetProvider>
  );
};

export default JobList;
