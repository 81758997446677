import React, { useEffect, useState } from 'react';

const Project = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [countdown, setCountdown] = useState(7);

  useEffect(() => {
    if (countdown > 0 && isPopupVisible) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [countdown, isPopupVisible]);

  useEffect(() => {
    // Load AdSense script if not already loaded
    const loadAdsenseScript = () => {
      if (
        !window.adsbygoogle &&
        !document.querySelector('script[src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"]')
      ) {
        const adScript = document.createElement('script');
        adScript.src = '//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
        adScript.async = true;
        adScript.crossOrigin = 'anonymous';
        document.body.appendChild(adScript);
      }
    };
    loadAdsenseScript();
  }, []);

  useEffect(() => {
    // Initialize ads after a short delay to ensure the ad container has rendered
    const timer = setTimeout(() => {
      try {
        const ads = document.getElementsByClassName('adsbygoogle');
        for (let i = 0; i < ads.length; i++) {
          const ad = ads[i];
          // Check if the ad has already been initialized
          if (!ad.getAttribute('data-ad-status')) {
            window.adsbygoogle.push({});
            ad.setAttribute('data-ad-status', 'initialized');
          }
        }
      } catch (e) {
        console.error('Adsbygoogle push error:', e);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [isPopupVisible]);

  const handleDownloadClick = () => {
    setIsPopupVisible(true);
  };

  const handlePopupClose = () => {
    setIsPopupVisible(false);
    setCountdown(7);
  };

  const handleDownload = () => {
    // Trigger file download
    const link = document.createElement('a');
    link.href = '/project.pdf';
    link.download = 'project.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Close the popup after download
    handlePopupClose();
  };

  return (
    <div className="min-h-screen px-4 sm:px-6 lg:px-8 pt-4">
      {/* Header Section */}
      <h1 className="text-3xl font-bold text-center mb-4">
        Download 150 projects on HTML/CSS and JavaScript
      </h1>

      <div className="flex justify-center">
        <button
          onClick={handleDownloadClick}
          className="px-6 py-3 mb-6 text-lg font-semibold rounded bg-black text-white hover:bg-gray-800 transition"
        >
          Download Now
        </button>
      </div>

      {/* WhatsApp Promotion Section */}
      <div className="bg-green-500 text-white rounded-lg p-6 mb-6 text-center">
        <p className="text-lg font-bold">
          Join our WhatsApp group & Get updates of paid Internships, Jobs, and Live Workshops.
        </p>
        <a
          href="https://beacons.ai/internify"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block mt-4 px-6 py-3 bg-green-700 text-white font-semibold rounded-lg"
        >
          Follow us on WhatsApp
        </a>
      </div>

      {/* AdSense Ad */}
      <div className="w-full mt-4">
        <ins
          className="adsbygoogle"
          style={{ display: 'block', textAlign: 'center', width: '100%' }}
          data-ad-layout="in-article"
          data-ad-format="fluid"
          data-ad-client="ca-pub-4408090261163070"
          data-ad-slot="2348154364"
          data-adtest="on" // Remove in production
        ></ins>
      </div>

{/* Popup Section */}
{isPopupVisible && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-auto relative">
      <span
        className="absolute top-2 right-3 text-gray-700 cursor-pointer text-2xl font-bold"
        onClick={handlePopupClose}
      >
        &times;
      </span>
      <h3 className="text-xl font-semibold mb-4 text-center">
        Your download will start soon
      </h3>

      <div className="w-full mt-4 mb-4">
        <ins
          className="adsbygoogle"
          style={{ display: 'block', width: '100%' }}
          data-ad-client="ca-pub-4408090261163070"
          data-ad-slot="2640604301"
          data-ad-format="auto"
          data-full-width-responsive="true"
          data-adtest="on" // Remove in production
        ></ins>
      </div>

      {countdown > 0 ? (
        <p className="mb-4 text-center">Please wait... {countdown} seconds</p>
      ) : (
        <button
          onClick={handleDownload}
          className="px-6 py-4 mt-12 mb-6 rounded bg-green-700 text-white w-full"
        >
          Download Now
        </button>
      )}
    </div>
  </div>
)}


    </div>
  );
};

export default Project;
