import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Sidebar from './Sidebar';

const Navbar = () => {
  const { currentUser, logout } = useAuth();
  const isAdmin = currentUser && currentUser.email === 'jobs@synergyworks.online';
  const [isOpen, setIsOpen] = useState(false);
  const [bannerIndex, setBannerIndex] = useState(0);
  const location = useLocation();

  const banners = [
    {
      // message: "Follow our Free WhatsApp channel for Job and Internship updates",
      // buttonText: "Join Now",
      // buttonLink: "https://whatsapp.com/channel/0029Vaiy5MY5q08WdHGwzc0j",
      // bgColor: "bg-green-600"
    }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setBannerIndex((prevIndex) => (prevIndex + 1) % banners.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = async () => {
    const confirmed = window.confirm('Are you sure you want to logout?');
    if (confirmed) {
      try {
        await logout();
      } catch (error) {
        console.error('Failed to logout:', error);
      }
    }
  };

  return (
    <header className="bg-white shadow relative">
      <div className={`${banners[bannerIndex].bgColor} text-white text-center py-2`}>
        {banners[bannerIndex].message}
        <button 
          onClick={() => window.location.href = banners[bannerIndex].buttonLink} 
          className="ml-2 bg-white text-black px-2 py-1 rounded"
        >
          {banners[bannerIndex].buttonText}
        </button>
      </div>
      <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 items-center justify-between">
          <div className="flex-1 md:flex md:items-center md:gap-12">
            <Link to="/" className="text-lg font-bold">
              <span className="block text-black text-2xl">Internify</span>
              <span className="block text-orange-600 text-sm">Internship & Jobs Portal</span>
            </Link>
          </div>
          <div className="md:hidden">
            <button
              onClick={toggleMenu}
              className="text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600"
            >
              <svg className="h-8 w-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={!isOpen ? "M4 6h16M4 12h16M4 18h16" : "M6 18L18 6M6 6l12 12"}></path>
              </svg>
            </button>
          </div>
          <div className="hidden md:flex md:items-center md:gap-12">
            <nav aria-label="Global" className="hidden md:block">
              <ul className="flex items-center gap-6 text-sm">
                <li>
                  <Link className="text-gray-500 transition hover:text-gray-500/75" to="/jobs-search">Jobs</Link>
                </li>

                {isAdmin && (
                  <>
                    <li>
                      <Link className="text-gray-500 transition hover:text-gray-500/75" to="/admin">Dashboard</Link>
                    </li>
                    <li>
                      <Link className="text-gray-500 transition hover:text-gray-500/75" to="/users">Users</Link>
                    </li>
                    <li>
                      <Link className="text-gray-500 transition hover:text-gray-500/75" to="/add-job">Add Job</Link>
                    </li>
                    <li>
                      <Link className="text-gray-500 transition hover:text-gray-500/75" to="/aj">Applied Jobs</Link>
                    </li>
                    <li>
                      <Link className="text-gray-500 transition hover:text-gray-500/75" to="/seo">SEO</Link>
                    </li>
                    <li>
                      <Link className="text-gray-500 transition hover:text-gray-500/75" to="/logs">Logs</Link>
                    </li>
                    <li>
                      <Link className="text-gray-500 transition hover:text-gray-500/75" to="/requested-jobs">Requested Jobs</Link>
                    </li>
                  </>
                )}
                {currentUser && (
                  <li>
                    <Link className="text-gray-500 transition hover:text-gray-500/75" to="/profile">Profile</Link>
                  </li>
                )}
              </ul>
            </nav>
            <div className="flex items-center gap-4">
              {currentUser ? (
                <button onClick={handleLogout} className="bg-red-600 text-white px-4 py-2 rounded">
                  Logout
                </button>
              ) : (
                <>
                  <Link to={`/login?redirect=${location.pathname}`} className="bg-blue-500 text-white px-4 py-2 rounded">
                    Login
                  </Link>
                  <Link to={`/register?redirect=${location.pathname}`} className="bg-gray-200 text-gray-800 px-4 py-2 rounded">
                    Register
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {isOpen && <Sidebar toggleMenu={toggleMenu} />}
    </header>
  );
};

export default Navbar;
