import React, { useState, useEffect } from 'react';
import { db } from '../firebase/firebaseConfig';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import moment from 'moment';

const WaitlistLogs = () => {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const q = query(collection(db, 'waitlist'), orderBy('tokenNumber', 'desc'));
        const querySnapshot = await getDocs(q);
        const apps = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setApplications(apps);
      } catch (error) {
        console.error('Error fetching applications:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchApplications();
  }, []);

  return (
    <div className="min-h-screen bg-black text-white flex flex-col items-center justify-start px-4 pt-8">
      <div className="text-center w-full max-w-4xl">
        <h1 className="text-3xl md:text-4xl font-bold mb-6">Waitlist Logs</h1> {/* Adjusted font size for mobile */}

        <div className="mb-4">
          <h2 className="text-lg md:text-xl font-semibold">Key Performance Indicator (KPI)</h2> {/* Adjusted font size for mobile */}
          <p>Total Applications: <span className="font-bold">{applications.length}</span></p>
        </div>

        <div className="bg-white shadow-md rounded-lg p-4 overflow-x-auto"> {/* Added overflow-x-auto for horizontal scrolling */}
          <table className="w-full text-left text-black">
            <thead>
              <tr className="border-b-2">
                <th className="py-2 px-2 md:px-4 text-sm md:text-base">Token No</th> {/* Adjusted padding and font size for mobile */}
                <th className="py-2 px-2 md:px-4 text-sm md:text-base">Name</th>
                <th className="py-2 px-2 md:px-4 text-sm md:text-base">Email</th>
                <th className="py-2 px-2 md:px-4 text-sm md:text-base">Location</th>
                <th className="py-2 px-2 md:px-4 text-sm md:text-base">Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="5" className="text-center py-4 text-sm md:text-base">Loading...</td>
                </tr>
              ) : applications.length > 0 ? (
                applications.map((app) => (
                  <tr key={app.id} className="border-b">
                    <td className="py-2 px-2 md:px-4 text-sm md:text-base">{app.tokenNumber}</td>
                    <td className="py-2 px-2 md:px-4 text-sm md:text-base">{app.name}</td>
                    <td className="py-2 px-2 md:px-4 text-sm md:text-base">{app.email}</td>
                    <td className="py-2 px-2 md:px-4 text-sm md:text-base">{app.location}</td>
                    <td className="py-2 px-2 md:px-4 text-sm md:text-base">
                      {app.timestamp ? moment(app.timestamp.toDate()).format('MMMM Do YYYY, h:mm:ss a') : 'N/A'}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center py-4 text-sm md:text-base">No applications found.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default WaitlistLogs;
