import React from 'react';

const Contact = () => {
  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Contact Us</h1>
      <p className="mb-4">
        We value your feedback and are here to assist you with any questions or concerns you may have. Please feel free to reach out to us using the contact information provided below.
      </p>
      
      <h2 className="text-2xl font-bold mb-4">Email</h2>
      <p className="mb-4">
        For support and inquiries, you can email us at: 
        <a href="mailto:onboarding@internify.online" className="text-blue-500"> onboarding@internify.online</a>
      </p>

      
      <h2 className="text-2xl font-bold mb-4">Office Hours</h2>
      <p className="mb-4">
        Our office hours are Monday to Friday, 9:00 AM to 6:00 PM (IST).
      </p>
    </div>
  );
};

export default Contact;
