import React, { useState, useEffect } from "react";
import { db } from "../firebase/firebaseConfig";
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";
import CreatableSelect from "react-select/creatable";
import { usePapaParse } from "react-papaparse";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import * as XLSX from 'xlsx';

const UploadJobs = () => {
  const [csvData, setCsvData] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [error, setError] = useState("");
  const { readString } = usePapaParse();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const companiesRef = collection(db, "companycategories");
        const companiesSnapshot = await getDocs(companiesRef);
        const companiesList = companiesSnapshot.docs.map((doc) => ({
          value: doc.data().name,
          label: doc.data().name,
        }));
        setAllCompanies(companiesList);

        const categoriesRef = collection(db, "jobCategories");
        const categoriesSnapshot = await getDocs(categoriesRef);
        const categoriesList = categoriesSnapshot.docs.map((doc) => ({
          value: doc.data().name,
          label: doc.data().name,
        }));
        setAllCategories(categoriesList);
      } catch (err) {
        console.error("Error fetching companies/categories:", err);
      }
    };

    fetchData();
  }, []);

  const handleExcelUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet, { defval: "" });
        
        const formattedData = parsedData.map((row) => ({
          Title: row.Title || "",
          Description: row.Description ? row.Description.replace(/\n/g, "<br />") : "",
          URL: row.URL || "",
          Location: row.Location || "",
          "Company Name": row["Company Name"] || "",
          Salary: row.Salary || "Not Disclosed",
          "Salary Type": row["Salary Type"] || "",
          Categories: row.Categories || "",
          Experience: row.Experience || "Freshers",
          "Job Type": row["Job Type"] || "Job",
          "Expiry Date": row["Expiry Date"] || getTwoWeeksFromToday(),
          Featured: row.Featured || "No",
          "Job Status": row["Job Status"] || "Active",
          "External Job": row["External Job"] || "No",
        }));
  
        setCsvData(formattedData);
      };
      reader.readAsArrayBuffer(file);
    }
  };
  
  

  const getTwoWeeksFromToday = () => {
    const today = new Date();
    today.setDate(today.getDate() + 14);
    return today.toISOString().split("T")[0];
  };

  const handleFieldChange = (index, field, value) => {
    const updatedData = [...csvData];
    updatedData[index][field] = value;
    setCsvData(updatedData);
  };

  const handleUpload = async (index) => {
    const job = csvData[index];
    const missingFields = [];
    if (!job.Title) missingFields.push("Title");
    if (!job.Description) missingFields.push("Description");
    if (!job.URL) missingFields.push("URL");
    if (!job.Location) missingFields.push("Location");
    if (!job["Company Name"]) missingFields.push("Company Name");

    if (missingFields.length > 0) {
      setError(`Missing fields: ${missingFields.join(", ")}`);
      return;
    }

    try {
      const companiesRef = collection(db, "companycategories");
      const q = query(companiesRef, where("name", "==", job["Company Name"]));
      const existingCompanies = await getDocs(q);

      if (existingCompanies.empty) {
        await addDoc(companiesRef, { name: job["Company Name"], categories: [] });
      }

      const salary = job.Salary ? `${job.Salary} ${job["Salary Type"] || ""}` : "Not Disclosed";
      const selectedCategories = job.Categories ? job.Categories.split(",").map(cat => cat.trim()) : [];

      await addDoc(collection(db, "jobs"), {
        title: job.Title,
        company: job["Company Name"],
        location: job.Location,
        salary,
        description: job.Description,
        type: job["Job Type"],
        link: job.URL,
        featured: job.Featured === "Yes",
        experience: job.Experience,
        expiryDate: job["Expiry Date"],
        jobStatus: job["Job Status"],
        externalJob: job["External Job"] === "Yes",
        categories: selectedCategories,
        companyCategories: [],
        date: new Date(),
      });

      setCsvData(csvData.filter((_, i) => i !== index));
      setError("");
    } catch (err) {
      console.error("Error uploading job:", err);
      setError("Failed to upload job. Please try again.");
    }
  };

  const handleDelete = (index) => {
    const updatedData = csvData.filter((_, i) => i !== index);
    setCsvData(updatedData);
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Upload Jobs via xls or xlsx</h1>

      {error && <p className="text-red-500 mb-4">{error}</p>}

      <input
  type="file"
  accept=".xlsx, .xls"
  onChange={handleExcelUpload}
  className="mb-4"
/>


      {csvData.length > 0 && (
        <>
          <h2 className="text-xl font-bold my-4">Preview and Edit Jobs</h2>
          {csvData.map((job, index) => (
            <div key={index} className="border p-4 mb-4">
              <div className="mb-2">
                <label>
                  Job Title
                  {!job.Title && <span className="text-red-500"> (Required)</span>}
                </label>
                <input
                  type="text"
                  className="border p-2 w-full"
                  value={job.Title || ""}
                  onChange={(e) => handleFieldChange(index, "Title", e.target.value)}
                  required
                />
              </div>

              <div className="mb-2">
                <label>
                  Description
                  {!job.Description && <span className="text-red-500"> (Required)</span>}
                </label>
                <ReactQuill
                  value={job.Description || ""}
                  onChange={(value) => handleFieldChange(index, "Description", value)}
                  placeholder="Enter job description..."
                  className="mb-2"
                />
              </div>

              <div className="mb-2">
                <label>
                  Location
                  {!job.Location && <span className="text-red-500"> (Required)</span>}
                </label>
                <input
                  type="text"
                  className="border p-2 w-full"
                  value={job.Location || ""}
                  onChange={(e) => handleFieldChange(index, "Location", e.target.value)}
                  required
                />
              </div>

              <div className="mb-2">
                <label>
                  Company Name
                  {!job["Company Name"] && <span className="text-red-500"> (Required)</span>}
                </label>
                <CreatableSelect
                  value={{ label: job["Company Name"], value: job["Company Name"] }}
                  onChange={(selectedOption) =>
                    handleFieldChange(index, "Company Name", selectedOption.value)
                  }
                  options={allCompanies}
                  placeholder="Select or create company"
                  className="react-select-container"
                  classNamePrefix="react-select"
                />
              </div>

              <div className="mb-2">
                <label>Salary</label>
                <input
                  type="text"
                  className="border p-2 w-full"
                  value={job.Salary || ""}
                  onChange={(e) => handleFieldChange(index, "Salary", e.target.value)}
                />
                <select
                  className="border p-2 w-full"
                  value={job["Salary Type"] || ""}
                  onChange={(e) => handleFieldChange(index, "Salary Type", e.target.value)}
                >
                  <option value="">Select Salary Type</option>
                  <option value="Monthly">Monthly</option>
                  <option value="Yearly">Yearly</option>
                </select>
              </div>

              <div className="mb-2">
                <label>Categories</label>
                <CreatableSelect
                  isMulti
                  value={job.Categories.split(',').map(cat => ({ value: cat, label: cat }))}
                  onChange={(selectedOptions) => handleFieldChange(index, "Categories", selectedOptions.map(opt => opt.label).join(","))}
                  options={allCategories}
                  placeholder="Select or create categories"
                  className="react-select-container"
                  classNamePrefix="react-select"
                />
              </div>

              <div className="mb-2">
                <label>
                  Experience
                </label>
                <select
                  className="border p-2 w-full"
                  value={job.Experience || "Freshers"}
                  onChange={(e) => handleFieldChange(index, "Experience", e.target.value)}
                >
                  <option value="Freshers">Freshers</option>
                  <option value="0-1 years">0-1 years</option>
                  <option value="1-2 years">1-2 years</option>
                  <option value="2+ years">2+ years</option>
                </select>
              </div>

              <div className="mb-2">
                <label>
                  Job Type
                </label>
                <select
                  className="border p-2 w-full"
                  value={job["Job Type"] || "Job"}
                  onChange={(e) => handleFieldChange(index, "Job Type", e.target.value)}
                >
            <option value="Job">Job</option>
            <option value="Internship">Internship</option>
                </select>
              </div>

              <div className="mb-2">
                <label>
                  URL
                </label>
                <input
                  type="text"
                  className="border p-2 w-full"
                  value={job.URL || ""}
                  onChange={(e) => handleFieldChange(index, "URL", e.target.value)}
                />
              </div>

              <div className="flex justify-between">
                <button
                  className="bg-green-500 text-white px-4 py-2 rounded"
                  onClick={() => handleUpload(index)}
                >
                  Verify & Upload
                </button>
                <button
                  className="bg-red-500 text-white px-4 py-2 rounded"
                  onClick={() => handleDelete(index)}
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default UploadJobs;
