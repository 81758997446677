import React, { useState, useEffect } from 'react';
import { db } from '../firebase/firebaseConfig';
import { collection, getDocs, doc, getDoc, query, orderBy } from 'firebase/firestore';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import moment from 'moment-timezone';
import 'tailwindcss/tailwind.css';

const AppliedJ = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [todaysApplications, setTodaysApplications] = useState(0);
  const [yesterdaysApplications, setYesterdaysApplications] = useState(0);
  const [totalApplications, setTotalApplications] = useState(0);

  useEffect(() => {
    if (!currentUser || currentUser.email !== 'jobs@synergyworks.online') {
      navigate('/'); // Redirect to home page if not an admin
      return;
    }

    const fetchKPIs = async () => {
      try {
        const totalApplicationsSnapshot = await getDocs(collection(db, 'appliedJobs'));
        const totalApplicationsCount = totalApplicationsSnapshot.size;
        setTotalApplications(totalApplicationsCount);

        const allJobsData = totalApplicationsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          appliedAt: doc.data().appliedAt.toDate(),
        }));

        const today = moment.tz('Asia/Kolkata').startOf('day');
        const yesterday = moment.tz('Asia/Kolkata').subtract(1, 'days').startOf('day');

        const todaysApplicationsCount = allJobsData.filter(job =>
          moment.tz(job.appliedAt, 'Asia/Kolkata').isSame(today, 'day')
        ).length;

        const yesterdaysApplicationsCount = allJobsData.filter(job =>
          moment.tz(job.appliedAt, 'Asia/Kolkata').isSame(yesterday, 'day')
        ).length;

        setTodaysApplications(todaysApplicationsCount);
        setYesterdaysApplications(yesterdaysApplicationsCount);
      } catch (error) {
        console.error("Error fetching KPI data:", error);
      }
    };

    fetchKPIs();
  }, [currentUser, navigate]);

  useEffect(() => {
    const fetchAppliedJobs = async () => {
      setLoading(true);
      try {
        const jobsQuery = query(collection(db, 'appliedJobs'), orderBy('appliedAt', 'desc'));
        const appliedJobsSnapshot = await getDocs(jobsQuery);

        const appliedJobsData = await Promise.all(appliedJobsSnapshot.docs.map(async (jobDoc) => {
          const jobData = { id: jobDoc.id, ...jobDoc.data() };
          const userRef = doc(db, 'users', jobData.userId);
          const userDoc = await getDoc(userRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            jobData.userName = userData.name || 'Unknown';
            jobData.userPhone = userData.phone || 'N/A';
            jobData.userEmail = userData.email || 'N/A';
          } else {
            jobData.userName = 'Unknown';
            jobData.userPhone = 'N/A';
            jobData.userEmail = 'N/A';
          }

          // Convert Firestore Timestamp to JavaScript Date object
          if (jobData.appliedAt && jobData.appliedAt.toDate) {
            jobData.appliedAt = jobData.appliedAt.toDate();
          } else {
            console.error('Invalid Timestamp:', jobData.appliedAt);
            jobData.appliedAt = null;
          }

          return jobData;
        }));

        setAppliedJobs(appliedJobsData);
      } catch (error) {
        console.error("Error fetching applied jobs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAppliedJobs();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="container mx-auto px-4 sm:px-8">
      <h1 className="text-3xl font-bold mb-4">Applied Jobs</h1>
      <div className="mb-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
        <div className="p-4 bg-blue-500 text-white rounded-lg shadow-sm flex justify-between items-center">
          <div>
            <div className="text-sm font-semibold">Today's Applications</div>
            <div className="text-3xl font-bold">{todaysApplications}</div>
          </div>
        </div>
        <div className="p-4 bg-blue-500 text-white rounded-lg shadow-sm flex justify-between items-center">
          <div>
            <div className="text-sm font-semibold">Yesterday's Applications</div>
            <div className="text-3xl font-bold">{yesterdaysApplications}</div>
          </div>
        </div>
        <div className="p-4 bg-blue-500 text-white rounded-lg shadow-sm flex justify-between items-center">
          <div>
            <div className="text-sm font-semibold">Total Applications</div>
            <div className="text-3xl font-bold">{totalApplications}</div>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full leading-normal">
          <thead>
            <tr>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                User Name
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                User ID
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Email
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Phone Number
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Job Title
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Applied At
              </th>
            </tr>
          </thead>
          <tbody>
            {appliedJobs.map(job => (
              <tr key={job.id}>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p className="text-gray-900 whitespace-no-wrap">{job.userName}</p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p className="text-gray-900 whitespace-no-wrap">{job.userId}</p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p className="text-gray-900 whitespace-no-wrap">{job.userEmail}</p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p className="text-gray-900 whitespace-no-wrap">{job.userPhone}</p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <Link to={`/job/${job.jobId}`} className="text-blue-500 hover:text-blue-700">
                    {job.title}
                  </Link>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p className="text-gray-900 whitespace-no-wrap">
                    {job.appliedAt ? moment(job.appliedAt).format('Do MMMM YYYY, h:mm a') : 'Invalid date'}
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AppliedJ;
