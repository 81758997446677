import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import { collection, getDocs, query, orderBy, limit, startAfter, where } from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig';
import { useAuth } from "../context/AuthContext";
import { TbShare2 } from "react-icons/tb";
import { FaCopy, FaWhatsapp } from "react-icons/fa";

const colors = [
  "#fae5d3", "#d3fae5", "#e5d3fa", "#f9f9d1", "#d1f9e3", "#d1e1f9", 
  "#f9d1d1", "#d1f9d9", "#f9e8d1", "#f2f2d1", "#d1f2f2", "#f2d1f2", 
  "#e0f2d1", "#d1f2e0", "#f2d1e0", "#e0d1f2", "#f2e0d1", "#d1f2e8"
];

const JobReel = ({ job, isActive, color, onShare, onApply }) => {
  return (
    <div
      className={`absolute top-0 left-0 w-full h-full flex flex-col justify-between text-center transition-opacity duration-500 ${isActive ? 'opacity-100' : 'opacity-0'}`}
      style={{ backgroundColor: color }}
    >
      {/* Top Section: Internify Branding */}
      <div className="w-full px-4 py-2 absolute top-0 flex justify-between items-center">
        <div className="text-left">
          <p className="font-bold text-lg">Internify</p>
          <p className="text-sm">Beta</p>
        </div>
        <div>
          <p className="text-sm">•••</p>
        </div>
      </div>

      {/* Middle Section: Job Details */}
      <div className="flex flex-col justify-center items-center flex-grow mt-24">
        <p className="text-xl font-semibold">{job.title}</p>
        <p className="text-lg">Salary: {job.salary}</p>
        <p className="text-lg">{job.type}</p>
      </div>

      {/* Bottom Section: Company Info */}
      <div className="w-full px-4 mb-8">
        <div className="flex items-center text-left mb-16">
          <div>
            <p className="font-bold text-lg">{job.company}</p>
            <p className="font text-lg">{job.location}</p>
          </div>
        </div>
      </div>

      {/* Bottom Section: Apply Now Button and Share Icon */}
      <div className="w-full absolute bottom-0 bg-black py-4 flex justify-center items-center">
        <button
          className="bg-white text-black font-bold py-2 px-10 rounded-full shadow-md"
          onClick={onApply}
        >
          Apply Now
        </button>
        <TbShare2 className="text-white ml-4 h-6 w-6 cursor-pointer" onClick={onShare} />
      </div>
    </div>
  );
};

const ShareModal = ({ jobUrl, onClose }) => {
  const handleCopyLink = () => {
    navigator.clipboard.writeText(jobUrl);
    alert("Link copied to clipboard!");
  };

  const handleShareWhatsapp = () => {
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(jobUrl)}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-4 rounded-lg shadow-lg w-80">
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-semibold">Share this Job</h2>
          <button onClick={onClose} className="text-black">
            &times;
          </button>
        </div>
        <div className="mt-4">
          <input
            type="text"
            value={jobUrl}
            readOnly
            className="w-full p-2 border rounded-lg"
          />
          <button
            onClick={handleCopyLink}
            className="mt-2 w-full flex justify-between items-center bg-gray-200 p-2 rounded-lg"
          >
            <span>Copy Link</span>
            <FaCopy className="h-5 w-5" />
          </button>
          <button
            onClick={handleShareWhatsapp}
            className="mt-2 w-full flex justify-between items-center bg-green-100 p-2 rounded-lg"
          >
            <span>Share on WhatsApp</span>
            <FaWhatsapp className="h-5 w-5 text-green-500" />
          </button>
        </div>
      </div>
    </div>
  );
};

const JobReelsPage = () => {
  const { currentUser } = useAuth(); 
  const [jobs, setJobs] = useState([]);
  const [currentReel, setCurrentReel] = useState(0);
  const [loading, setLoading] = useState(true);
  const [lastVisible, setLastVisible] = useState(null);
  const [showShareModal, setShowShareModal] = useState(false);
  const [currentJobUrl, setCurrentJobUrl] = useState('');
  const [fetchingMore, setFetchingMore] = useState(false); // New state to prevent duplicate fetches

  const checkIfApplied = async (jobId) => {
    if (currentUser) {
      const q = query(
        collection(db, "appliedJobs"),
        where("userId", "==", currentUser.uid),
        where("jobId", "==", jobId)
      );
      const querySnapshot = await getDocs(q);
      return !querySnapshot.empty;
    }
    return false;
  };

  const fetchJobs = async (startAfterDoc = null) => {
    if (fetchingMore) return; // Prevent duplicate fetches
    setLoading(true);
    setFetchingMore(true); // Set fetchingMore to true while fetching

    try {
      const jobCollection = collection(db, "jobs");
      const jobQuery = startAfterDoc
        ? query(jobCollection, orderBy('date', 'desc'), startAfter(startAfterDoc), limit(3))
        : query(jobCollection, orderBy('date', 'desc'), limit(3));

      const jobSnapshot = await getDocs(jobQuery);
      const jobsData = [];

      for (let doc of jobSnapshot.docs) {
        const jobData = { ...doc.data(), id: doc.id };
        const hasApplied = await checkIfApplied(jobData.id);

        if (!hasApplied) {
          jobsData.push(jobData);
        }
      }

      if (!jobSnapshot.empty) {
        setLastVisible(jobSnapshot.docs[jobSnapshot.docs.length - 1]);
      }
      setJobs((prevJobs) => [...prevJobs, ...jobsData]);
    } catch (error) {
      console.error("Error fetching jobs:", error);
    } finally {
      setLoading(false);
      setFetchingMore(false); // Reset fetchingMore after fetching
    }
  };

  useEffect(() => {
    fetchJobs();
    
    document.body.style.marginTop = '0';
    document.body.style.paddingTop = '0';
    document.documentElement.style.marginTop = '0';
    document.documentElement.style.paddingTop = '0';
    document.body.style.overflow = 'hidden';

    const rootElement = document.getElementById('root');
    if (rootElement) {
      rootElement.style.marginTop = '0';
      rootElement.style.paddingTop = '0';
    }

    const header = document.querySelector('header');
    const adNotice = document.getElementById('adNotice');
    const navBar = document.querySelector('.mx-auto.max-w-screen-xl');

    if (header) {
      header.style.display = 'none';
    }
    if (adNotice) {
      adNotice.style.display = 'none';
    }
    if (navBar) {
      navBar.style.display = 'none';
    }

    return () => {
      document.body.style.overflow = '';
      if (header) {
        header.style.display = 'block';
      }
      if (adNotice) {
        adNotice.style.display = 'block';
      }
      if (navBar) {
        navBar.style.display = 'block';
      }
    };
  }, []);

  useEffect(() => {
    if (jobs.length > 0) {
      const currentJob = jobs[currentReel];
      const jobUrl = `${window.location.origin}/job/${currentJob.id}`;
      setCurrentJobUrl(jobUrl);
    }
  }, [currentReel, jobs]);

  const handlers = useSwipeable({
    onSwipedUp: () => {
      setCurrentReel((prev) => {
        const nextIndex = prev + 1;
        if (nextIndex >= jobs.length - 1 && !fetchingMore) { 
          fetchJobs(lastVisible); // Load more jobs when reaching the last loaded job
        }
        return nextIndex; // Allow the next job to be shown
      });
    },
    onSwipedDown: () => setCurrentReel((prev) => Math.max(prev - 1, 0)),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const handleShareClick = () => {
    setShowShareModal(true);
  };

  return (
    <div {...handlers} className="relative h-screen w-screen overflow-hidden">
      {jobs.map((job, index) => (
        <JobReel
          key={job.id}
          job={job}
          isActive={index === currentReel}
          color={colors[index % colors.length]}
          onShare={handleShareClick}
          onApply={() => window.location.href = currentJobUrl}
        />
      ))}
      {loading && (
        <div className="flex justify-center items-center h-screen w-full absolute top-0">
          <p className="text-gray-600">Loading more jobs...</p>
        </div>
      )}
      {showShareModal && (
        <ShareModal
          jobUrl={currentJobUrl}
          onClose={() => setShowShareModal(false)}
        />
      )}
    </div>
  );
};

export default JobReelsPage;
