// src/components/RequestedJobs.js
import React, { useEffect, useState } from "react";
import { db } from "../firebase/firebaseConfig";
import { collection, getDocs, updateDoc, doc, addDoc, serverTimestamp } from "firebase/firestore";

const RequestedJobs = () => {
  const [requestedJobs, setRequestedJobs] = useState([]);

  useEffect(() => {
    fetchRequestedJobs();
  }, []);

  const fetchRequestedJobs = async () => {
    const querySnapshot = await getDocs(collection(db, "requestedJobs"));
    const jobs = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setRequestedJobs(jobs);
  };

  const handleApprove = async (job) => {
    const { id, ...jobData } = job;
    await updateDoc(doc(db, "requestedJobs", id), {
      status: "Approved",
      approvedAt: serverTimestamp(),
    });
    await addDoc(collection(db, "jobs"), {
      ...jobData,
      date: serverTimestamp(),
    });
    fetchRequestedJobs();
  };

  const handleReject = async (jobId) => {
    await updateDoc(doc(db, "requestedJobs", jobId), {
      status: "Rejected",
      rejectedAt: serverTimestamp(),
    });
    fetchRequestedJobs();
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Requested Jobs</h1>
      {requestedJobs.length > 0 ? (
        <table className="min-w-full leading-normal">
          <thead>
            <tr>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Title
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Company
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Location
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Salary
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Salary Type
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Description
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Type
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Link
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Experience
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Expiry Date
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Status
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                External Job
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                User Details
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {requestedJobs.map((job) => (
              <tr key={job.id}>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  {job.title}
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  {job.company}
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  {job.location}
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  {job.salary}
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  {job.salaryType}
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  {job.description}
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  {job.type}
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <a href={job.link} target="_blank" rel="noopener noreferrer">
                    {job.link}
                  </a>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  {job.experience}
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  {job.expiryDate}
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  {job.status}
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  {job.externalJob ? "Yes" : "No"}
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p>Name: {job.userName}</p>
                  <p>Company: {job.userCompany}</p>
                  <p>Address: {job.userAddress}</p>
                  <p>
                    Map Link:{" "}
                    <a href={job.userMapLink} target="_blank" rel="noopener noreferrer">
                      View
                    </a>
                  </p>
                  <p>Phone: {job.userPhone}</p>
                  <p>Email: {job.userEmail}</p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  {job.status === "Pending" ? (
                    <>
                      <button
                        className="bg-green-500 text-white px-4 py-2 rounded mr-2"
                        onClick={() => handleApprove(job)}
                      >
                        Approve
                      </button>
                      <button
                        className="bg-red-500 text-white px-4 py-2 rounded"
                        onClick={() => handleReject(job.id)}
                      >
                        Reject
                      </button>
                    </>
                  ) : (
                    <p>Status: {job.status}</p>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No job requests available.</p>
      )}
    </div>
  );
};

export default RequestedJobs;
