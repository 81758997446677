import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainLayout from './components/MainLayout';
import Home from './pages/Home';
import Login from './components/Login';
import Register from './components/Register';
import ResetPassword from './components/ResetPassword';
import JobDetails from './components/JobDetails';
import Admin from './components/Admin';
import AddSEO from './components/AddSEO';
import AddJob from './components/AddJob';
import EditJob from './components/EditJob';
import AppliedJobs from './pages/AppliedJobs';
import JobList from './components/JobList';
import LoginLogs from './components/LoginLogs';
import Users from './components/Users'; // New Import
import AppliedJ from './components/AppliedJ'; // New Import
import Profile from './components/Profile'; // New Import
import TermsAndConditions from './pages/TermsAndConditions'; // New Import
import PrivacyPolicy from './pages/PrivacyPolicy'; // New Import
import About from './pages/About'; // New Import
import ContactUs from './pages/ContactUs'; // New Import
import PrivateRoute from './components/PrivateRoute';
import AdminRoute from './components/AdminRoute';
import { AuthProvider } from './context/AuthContext';
import RequestJob from './pages/RequestJob'; // New Import
import RequestedJobs from './components/RequestedJobs'; // New Import
import RedirectJob from './components/RedirectJob';
import Scoreboard from './components/Scoreboard';
import Waitlist from './components/Waitlist';
import WaitlistLogs from './components/WaitlistLogs';
import JobSearch from './components/JobSearch'; // Import the new JobSearch component
import LoiForm from './components/LoiForm'; 
import Categories from './components/Categories'; 
import JobReels from './components/JobReels';
import ReelLayout from './components/ReelLayout'; // Import the ReelLayout component
import JobHiringPlatforms from './components/JobHiringPlatforms'; // Import the ReelLayout component
import JobRequestForm from './components/JobRequestForm'; // Import the ReelLayout component
import CompanyJobs from './components/CompanyJobs'; // Make sure the path is correct
import JobScraper from './components/JobScraper'; // Make sure the path is correct
import UploadJobs from './components/UploadJobs'; // Make sure the path is correct
import Project from './components/Project'; // Make sure the path is correct




function App() {
  return (
    <AuthProvider>
      <Router>
        <MainLayout>
          <Routes>
            <Route path="/" element={<JobSearch />} />
            <Route path="/login" element={<Login />} />
            <Route path="/top-hiring-platforms" element={<JobHiringPlatforms />} />
            <Route path="/register" element={<Register />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/job/:jobId" element={<JobDetails />} />
            <Route path="/jobs-list" element={<JobList />} />
            <Route path="/loi" element={<LoiForm />} />
            <Route path="/jobs-search" element={<JobSearch />} />
            <Route path="/categories/:categoryName" element={<Categories />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/jobrequest" element={<JobRequestForm />} />
            <Route path="/companies" element={<CompanyJobs />} />
            <Route path="/project" element={<Project />} />
            <Route path="/companies/:companyName" element={<CompanyJobs />} />

            



            <Route
              path="/admin"
              element={
                <AdminRoute>
                  <Admin />
                </AdminRoute>
              }
            />
            <Route
              path="/add-job"
              element={
                <AdminRoute>
                  <AddJob />
                </AdminRoute>
              }
            />
                        <Route
              path="/upload-jobs"
              element={
                <AdminRoute>
                  <UploadJobs />
                </AdminRoute>
              }
            />
                        <Route
              path="/scrapper"
              element={
                <AdminRoute>
                  <JobScraper />
                </AdminRoute>
              }
            />
                        <Route
              path="/waitlist-logs"
              element={
                <AdminRoute>
                  <WaitlistLogs />
                </AdminRoute>
              }
            />
            <Route
              path="/edit-job/:jobId"
              element={
                <AdminRoute>
                  <EditJob />
                </AdminRoute>
              }
            />
            <Route
              path="/applied-jobs"
              element={
                <PrivateRoute>
                  <AppliedJobs />
                </PrivateRoute>
              }
            />
                        <Route
              path="/redirect/:jobId"
              element={
                <PrivateRoute>
                  <RedirectJob />
                </PrivateRoute>
              }
            />
             <Route
              path="/scoreboard"
              element={
                <PrivateRoute>
                  <Scoreboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/aj"
              element={
                <AdminRoute>
                  <AppliedJ />
                </AdminRoute>
              }
            />
            <Route
              path="/seo"
              element={
                <AdminRoute>
                  <AddSEO />
                </AdminRoute>
              }
            />
            <Route
              path="/logs"
              element={
                <AdminRoute>
                  <LoginLogs />
                </AdminRoute>
              }
            />
                        <Route
              path="/requested-jobs"
              element={
                <AdminRoute>
                  <RequestedJobs />
                </AdminRoute>
              }
            />
            <Route
              path="/users"
              element={
                <AdminRoute>
                  <Users />
                </AdminRoute>
              }
            />
            
            <Route
              path="/profile"
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
            />
                      <Route path="/reel" element={<ReelLayout />}>
            <Route index element={<JobReels />} />
          </Route>
            
            <Route path="/terms" element={<TermsAndConditions />} /> {/* New Route */}
            <Route path="/privacy" element={<PrivacyPolicy />} /> {/* New Route */}
            <Route path="/about" element={<About />} /> {/* New Route */}
            <Route path="/contact" element={<ContactUs />} /> {/* New Route */}
            <Route path="/request-job" element={<RequestJob />} /> {/* New Route */}
            <Route path="/waitlist" element={<Waitlist />} /> {/* New Route */}

            
          </Routes>        
        </MainLayout>
      </Router>
    </AuthProvider>
  );
}

export default App;
