import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, fetchSignInMethodsForEmail } from 'firebase/auth';
import { auth, db } from '../firebase/firebaseConfig';
import { useAuth } from '../context/AuthContext';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(true); // State to toggle password visibility
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser) {
      navigate('/jobs-search');
    }
  }, [currentUser, navigate]);

  const logLoginAttempt = async (email, status, message, userDetails = null) => {
    try {
      await addDoc(collection(db, 'loginLogs'), {
        email,
        status,
        message,
        userDetails,
        timestamp: serverTimestamp(),
      });
    } catch (logError) {
      console.error('Error logging login attempt:', logError);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await signInWithEmailAndPassword(auth, email, password);
      await logLoginAttempt(email, 'success', 'Logged in successfully');
      navigate('/jobs-search');
    } catch (error) {
      console.error('Error logging in:', error);
      let errorMessage = 'Error logging in. Please try again.';
      let userDetails = null;

      switch (error.code) {
        case 'auth/user-not-found':
          errorMessage = 'No user found with this email.';
          break;
        case 'auth/wrong-password':
          errorMessage = 'Incorrect password.';
          break;
        case 'auth/invalid-email':
          errorMessage = 'Invalid email address.';
          break;
        case 'auth/invalid-credential':
          errorMessage = 'Invalid credential. Please enter correct email and password.';
          break;
        default:
          errorMessage = error.message;
          break;
      }

      try {
        const signInMethods = await fetchSignInMethodsForEmail(auth, email);
        if (signInMethods.length > 0) {
          userDetails = { email, signInMethods };
        }
      } catch (fetchError) {
        console.error('Error fetching sign-in methods:', fetchError);
      }

      setError(errorMessage);
      await logLoginAttempt(email, 'failure', errorMessage, userDetails);
    }
  };

  return (
    <section className="min-h-screen flex flex-col items-center justify-center bg-white px-8 py-4"> {/* Added py-4 for less upper white space */}
      <div className="text-center mb-8">
        <h2 className="text-2xl font-bold text-gray-900">Hi, Welcome back <span role="img" aria-label="smile">😊</span> <span role="img" aria-label="wave">👋</span></h2>
      </div>

      {error && (
        <div className="text-red-500 text-sm mb-4">
          {error}
        </div>
      )}

      <form onSubmit={handleLogin} className="w-full max-w-lg space-y-6">
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email address</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full mt-1 px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div className="relative">
          <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
          <div className="relative">
            <input
              type={showPassword ? 'text' : 'password'} // Show password by default
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full mt-1 px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            />
            <button
              type="button"
              className="absolute right-3 top-3 text-gray-600"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? 'Hide' : 'Show'}
            </button>
          </div>
        </div>

        <div className="text-right">
          <a href="/reset-password" className="text-sm text-blue-600 hover:underline">Forgot password?</a>
        </div>

        <button type="submit" className="w-full bg-blue-600 text-white py-2 rounded-md text-sm font-medium hover:bg-blue-700">
          Login
        </button>

        <div className="text-center text-sm">
          Don’t have an account? <a href="/register" className="font-medium text-blue-600 hover:underline">Create Now</a>
        </div>
      </form>
    </section>
  );
};

export default Login;
