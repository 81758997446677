import React from 'react';
import { FaInstagram, FaLinkedin, FaWhatsapp } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-gray-100">
      <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
        <div
          className="flex flex-col items-center gap-4 rounded-lg bg-green-500 p-6 shadow-lg sm:flex-row sm:justify-between"
        >
          <strong className="text-xl text-white sm:text-xl"> Join our WhatsApp group to receive job updates! </strong>

          <a
            className="inline-flex items-center gap-2 rounded-full border border-white bg-white px-8 py-3 text-green-600 hover:bg-transparent hover:text-white focus:outline-none focus:ring active:bg-white/90"
            href="https://beacons.ai/internify"
          >
            <span className="text-sm font-medium"> Join Now </span>

            <FaWhatsapp className="text-xl" />
          </a>
        </div>

        <div className="mt-16 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
          <div className="text-center sm:text-left">
            <p className="text-lg font-medium text-gray-900">Popular Cities & Internships</p>

            <ul className="mt-8 space-y-4 text-sm">
              <li>
                <a href="/jobs-list?location=new-delhi" className="text-gray-700 transition hover:text-gray-700/75">
                  Internships in New Delhi
                </a>
              </li>
              <li>
                <a href="/jobs-list?location=kolkata" className="text-gray-700 transition hover:text-gray-700/75">
                  Internships in Kolkata
                </a>
              </li>
              <li>
                <a href="/jobs-list?location=bangalore" className="text-gray-700 transition hover:text-gray-700/75">
                  Internships in Bangalore
                </a>
              </li>
              <li>
                <a href="/jobs-list?location=mumbai" className="text-gray-700 transition hover:text-gray-700/75">
                  Internships in Mumbai
                </a>
              </li>
              <li>
                <a href="/jobs-list?location=hyderabad" className="text-gray-700 transition hover:text-gray-700/75">
                  Internships in Hyderabad
                </a>
              </li>
              <li>
                <a href="/jobs-list?location=chennai" className="text-gray-700 transition hover:text-gray-700/75">
                  Internships in Chennai
                </a>
              </li>
              <li>
                <a href="/jobs-list?location=ahmedabad" className="text-gray-700 transition hover:text-gray-700/75">
                  Internships in Ahmedabad
                </a>
              </li>
              <li>
                <a href="/jobs-list?location=pune" className="text-gray-700 transition hover:text-gray-700/75">
                  Internships in Pune
                </a>
              </li>
              <li>
                <a href="/jobs-list?location=noida" className="text-gray-700 transition hover:text-gray-700/75">
                  Internships in Noida
                </a>
              </li>
              <li>
                <a href="/jobs-list?location=gurgaon" className="text-gray-700 transition hover:text-gray-700/75">
                  Internships in Gurgaon
                </a>
              </li>
              <li>
                <a href="/jobs-list?location=chandigarh" className="text-gray-700 transition hover:text-gray-700/75">
                  Internships in Chandigarh
                </a>
              </li>
              <li>
                <a href="/jobs-list?location=jaipur" className="text-gray-700 transition hover:text-gray-700/75">
                  Internships in Jaipur
                </a>
              </li>
            </ul>
          </div>

          <div className="text-center sm:text-left">
            <p className="text-lg font-medium text-gray-900">Popular Jobs & Internships</p>

            <ul className="mt-8 space-y-4 text-sm">
              <li>
                <a href="/jobs-search?search=software%20development" className="text-gray-700 transition hover:text-gray-700/75">
                  Software Development Jobs
                </a>
              </li>
              <li>
                <a href="/jobs-search?search=software%20development" className="text-gray-700 transition hover:text-gray-700/75">
                  Software Development Internships
                </a>
              </li>
              <li>
                <a href="/jobs-search?search=product%20manager" className="text-gray-700 transition hover:text-gray-700/75">
                  Product Manager jobs
                </a>
              </li>
              <li>
                <a href="/jobs-search?search=ui" className="text-gray-700 transition hover:text-gray-700/75">
                  UX/UI Designer Internships
                </a>
              </li>
              <li>
                <a href="/jobs-search?search=data%20science" className="text-gray-700 transition hover:text-gray-700/75">
                  Data Science Internships
                </a>
              </li>
              <li>
                <a href="/jobs-search?search=marketing" className="text-gray-700 transition hover:text-gray-700/75">
                  Marketing Internships
                </a>
              </li>
              <li>
                <a href="/jobs-search?search=content%20writing" className="text-gray-700 transition hover:text-gray-700/75">
                  Content Writing Internships
                </a>
              </li>
              <li>
                <a href="/jobs-search?search=finance" className="text-gray-700 transition hover:text-gray-700/75">
                  Finance Internships
                </a>
              </li>
              <li>
                <a href="/jobs-search?search=hr" className="text-gray-700 transition hover:text-gray-700/75">
                  HR Internships
                </a>
              </li>
            </ul>
          </div>

          <div className="text-center sm:text-left">
            <p className="text-lg font-medium text-gray-900">Jobseekers</p>

            <ul className="mt-8 space-y-4 text-sm">
              <li>
                <a href="/login" className="text-gray-700 transition hover:text-gray-700/75">
                  Login
                </a>
              </li>
              <li>
                <a href="/register" className="text-gray-700 transition hover:text-gray-700/75">
                  Register
                </a>
              </li>
              <li>
                <a href="/jobs-search" className="text-gray-700 transition hover:text-gray-700/75">
                  Find Jobs
                </a>
              </li>
            </ul>
          </div>

          <div className="text-center sm:text-left">
            <p className="text-lg font-medium text-gray-900">Employers</p>

            <ul className="mt-8 space-y-4 text-sm">
              <li>
                <a href="/request-job" className="text-gray-700 transition hover:text-gray-700/75">
                  Post Your Jobs/Internships (Free)
                </a>
              </li>
            </ul>

            <div className="mt-8">
              <p className="text-lg font-medium text-gray-900">Quick Links</p>
              <ul className="mt-8 space-y-4 text-sm">
                <li>
                  <a href="/about" className="text-gray-700 transition hover:text-gray-700/75">
                    About Us
                  </a>
                </li>
                <li>
                  <a href="/terms" className="text-gray-700 transition hover:text-gray-700/75">
                    Terms and Conditions
                  </a>
                </li>
                <li>
                  <a href="/privacy" className="text-gray-700 transition hover:text-gray-700/75">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="/contact" className="text-gray-700 transition hover:text-gray-700/75">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="mt-16 sm:flex sm:items-center sm:justify-between">
          <ul className="flex justify-center gap-6 sm:justify-end">
            <li>
              <a
                href="https://www.instagram.com/internify_online"
                target="_blank"
                rel="noopener noreferrer"
                className="text-teal-700 transition hover:text-teal-700/75"
              >
                <span className="sr-only">Instagram</span>
                <FaInstagram className="text-2xl" />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/internify-online/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-teal-700 transition hover:text-teal-700/75"
              >
                <span className="sr-only">LinkedIn</span>
                <FaLinkedin className="text-2xl" />
              </a>
            </li>
            <li>
              <a
                href="https://beacons.ai/internify"
                target="_blank"
                rel="noopener noreferrer"
                className="text-teal-700 transition hover:text-teal-700/75"
              >
                <span className="sr-only">WhatsApp</span>
                <FaWhatsapp className="text-2xl" />
              </a>
            </li>
          </ul>

          <p className="mt-4 text-center text-sm text-gray-500 sm:mt-0 sm:text-right">
            Copyright &copy; 2024. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
