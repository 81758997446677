import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link, useLocation } from 'react-router-dom';
import { db } from '../firebase/firebaseConfig';
import { collection, getDocs, query, where, orderBy, limit, startAfter } from 'firebase/firestore';

const randomColors = [
  "#fae5d3", "#d3fae5", "#e5d3fa", "#f9f9d1", "#d1f9e3", "#d1e1f9", 
  "#f9d1d1", "#d1f9d9", "#f9e8d1", "#f2f2d1", "#d1f2f2", "#f2d1f2", 
  "#e0f2d1", "#d1f2e0", "#f2d1e0", "#e0d1f2", "#f2e0d1", "#d1f2e8"
];

const AdBanner = ({ id, delay = 0 }) => {
  useEffect(() => {
    const loadAdScript = () => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = `
        atOptions = {
          'key' : '64318eeb6cc3ea5bfe475637cfbc3b3b',
          'format' : 'iframe',
          'height' : 50,
          'width' : 320,
          'params' : {}
        };
      `;
      
      const invokeScript = document.createElement('script');
      invokeScript.type = 'text/javascript';
      invokeScript.src = 'https://www.topcreativeformat.com/64318eeb6cc3ea5bfe475637cfbc3b3b/invoke.js';
      invokeScript.async = true;

      const adContainer = document.getElementById(id);
      if (adContainer) {
        adContainer.innerHTML = ''; // Clear any previous content
        adContainer.appendChild(script);
        adContainer.appendChild(invokeScript);
      }
    };

    const timeoutId = setTimeout(loadAdScript, delay);

    return () => clearTimeout(timeoutId); // Clean up the timeout if the component is unmounted
  }, [id, delay]);

  return <div id={id}></div>;
};

const isMobile = () => {
  return window.innerWidth <= 768; // Adjust the width according to your mobile breakpoint
};

const Companies = () => {
  const [jobs, setJobs] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState("Loading jobs...");
  const [moreJobsAvailable, setMoreJobsAvailable] = useState(true);
  const [lastVisible, setLastVisible] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const location = useLocation();
  const jobsPerPage = 6;

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const company = decodeURIComponent(pathSegments[pathSegments.length - 1]);
    if (company === "companies") {
      fetchCompaniesWithCounts();
    } else {
      setCompanyName(company);
      setJobs([]);
      setLastVisible(null);
      setMoreJobsAvailable(true);
      fetchJobs(company);
    }
  }, [location.pathname]);

  const fetchCompaniesWithCounts = async () => {
    setLoading(true);
    try {
      const companiesRef = collection(db, "companycategories");
      const companiesSnapshot = await getDocs(companiesRef);
      const companiesList = [];

      for (const doc of companiesSnapshot.docs) {
        const companyName = doc.data().name;
        const jobsQuery = query(collection(db, "jobs"), where('company', '==', companyName));
        const jobsSnapshot = await getDocs(jobsQuery);
        
        if (jobsSnapshot.size > 0) { // Only add companies with jobs available
          companiesList.push({
            name: companyName,
            count: jobsSnapshot.size,
          });
        }
      }

      setCompanies(companiesList);
    } catch (error) {
      console.error("Error fetching companies:", error.message, error.code, error);
    } finally {
      setLoading(false);
    }
  };

  const fetchJobs = async (company) => {
    setLoading(true);
    try {
      const jobCollection = collection(db, "jobs");
      let jobQuery;

      jobQuery = query(
        jobCollection,
        where('company', '==', company),
        orderBy('date', 'desc'),
        limit(jobsPerPage)
      );

      if (lastVisible) {
        jobQuery = query(
          jobCollection,
          where('company', '==', company),
          orderBy('date', 'desc'),
          startAfter(lastVisible),
          limit(jobsPerPage)
        );
      }

      const jobSnapshot = await getDocs(jobQuery);

      const jobsData = jobSnapshot.docs.map((doc, index) => {
        const data = doc.data();
        const currentDate = new Date();
        let expiryDate = null;

        if (data.expiryDate) {
          if (data.expiryDate.seconds) {
            expiryDate = new Date(data.expiryDate.seconds * 1000);
          } else {
            expiryDate = new Date(data.expiryDate);
          }
        }

        if ((!expiryDate || expiryDate >= currentDate) && data.jobStatus !== 'Inactive') {
          return {
            ...data,
            id: doc.id,
            color: randomColors[(jobs.length + index) % randomColors.length],
            formattedExpiryDate: expiryDate ? formatExpiryDate(expiryDate) : null,
          };
        }
        return null;
      }).filter(job => job !== null);

      if (jobSnapshot.docs.length < jobsPerPage) {
        setMoreJobsAvailable(false);
      } else {
        setLastVisible(jobSnapshot.docs[jobSnapshot.docs.length - 1]);
      }

      setJobs((prevJobs) => {
        const newJobIds = new Set(prevJobs.map(job => job.id));
        const uniqueJobs = jobsData.filter(job => !newJobIds.has(job.id));
        return [...prevJobs, ...uniqueJobs];
      });
    } catch (error) {
      console.error("Error fetching jobs:", error);
    } finally {
      setLoading(false);
    }
  };

  const formatExpiryDate = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    const daySuffix = day % 10 === 1 && day !== 11 ? 'st' : day % 10 === 2 && day !== 12 ? 'nd' : day % 10 === 3 && day !== 13 ? 'rd' : 'th';
    return `Last date to apply: ${day}${daySuffix} ${month} ${year}`;
  };

  if (loading && companies.length === 0 && jobs.length === 0) {
    return (
      <div className="flex items-center justify-center h-screen bg-white">
        <div className="text-center">
          <div className="text-2xl font-semibold mb-4 text-gray-900">
            {loadingText}
          </div>
          <div id="loading-text" className="text-lg text-gray-600 transition-opacity duration-300">
            {loadingText}
          </div>
        </div>
      </div>
    );
  }

  return (
    <HelmetProvider>
      <Helmet>
        <title>{companyName ? `${companyName} Jobs - Internify` : "Companies - Internify"}</title>
        <meta name="description" content={companyName ? `Find the best jobs at ${companyName} on Internify.` : `Browse companies to find the best jobs on Internify.`} />
        <meta name="keywords" content={companyName ? `${companyName}, jobs, internships` : "companies, jobs, internships"} />
      </Helmet>
      <div className="max-w-4xl mx-auto p-4">
        {companyName ? (
          <>
            <h1 className="text-3xl font-bold mb-4">{companyName} Jobs</h1>
            {jobs.length === 0 ? (
              <p>No jobs available for {companyName}.</p>
            ) : (
              <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
                {jobs.map((job, index) => (
                  <React.Fragment key={job.id}>
                    <div
                      className="rounded-lg shadow-md flex flex-col justify-between"
                      style={{
                        borderRadius: "15px",
                        border: "1px solid #e0e0e0",
                        overflow: "hidden",
                      }}
                    >
                      <div
                        className="p-4"
                        style={{
                          backgroundColor: job.color,
                          borderBottom: "1px solid #e0e0e0",
                          borderRadius: "15px 15px 0 0",
                        }}
                      >
                        <div className="mb-4">
                          <div className="text-left">
                            <h2 className="text-2xl font-bold mb-1">{job.title}</h2>
                            <div className="text-gray-500 text-lg">{job.company}</div>
                          </div>
                        </div>
                        <div className="flex flex-wrap mb-4">
                          <span className="bg-white text-gray-600 px-3 py-1 rounded-full mr-2 mb-2">
                            {job.type || ""}
                          </span>
                          <span className="bg-white text-gray-600 px-3 py-1 rounded-full mr-2 mb-2">
                            {job.experience || ""}
                          </span>
                        </div>
                        <div className="flex items-center mb-2 text-gray-600">
                          <span>{job.location}</span>
                        </div>
                      </div>
                      <div
                        className="flex justify-between items-center bg-white p-4"
                        style={{
                          borderRadius: "0 0 15px 15px",
                        }}
                      >
                        <div className="text-gray-600 flex items-center">
                          <span className="text-lg font-bold">
                            {job.salary ? `₹${job.salary}` : "Not disclosed"}
                          </span>
                        </div>
                        <Link
                          to={`/job/${job.id}`}
                          className="bg-black text-white px-4 py-2 rounded-full"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Details
                        </Link>
                      </div>
                    </div>

                    {/* Ad banner between job cards, only visible on mobile */}
                    {isMobile() && (
                      <div key={`ad-${job.id}-${index}`} className="my-4">
                        <AdBanner id={`ad-container-${index}`} delay={index * 1000} />
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            )}
            {moreJobsAvailable && (
              <div className="flex justify-center mt-4">
                <button
                  onClick={() => fetchJobs(companyName)}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Show More"}
                </button>
              </div>
            )}
          </>
        ) : (
          <>
            <h1 className="text-3xl font-bold mb-4">Companies</h1>
            <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
              {companies.map((company) => (
                <Link to={`/companies/${encodeURIComponent(company.name)}`} key={company.name} className="bg-white shadow-md rounded-lg p-4 hover:bg-gray-100 transition transform hover:scale-105 flex items-center">
                  <div>
                    <h2 className="text-xl font-bold mb-2">{company.name.charAt(0).toUpperCase() + company.name.slice(1)}</h2>
                    <p>{company.count} jobs available</p>
                  </div>
                </Link>
              ))}
            </div>
          </>
        )}
      </div>
    </HelmetProvider>
  );
};

export default Companies;
