// src/pages/RequestJob.js
import React, { useState } from "react";
import { db } from "../firebase/firebaseConfig";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

const RequestJob = () => {
  const [jobTitle, setJobTitle] = useState("");
  const [jobCompany, setJobCompany] = useState("");
  const [jobLocation, setJobLocation] = useState("");
  const [jobSalary, setJobSalary] = useState("");
  const [jobSalaryType, setJobSalaryType] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [jobLink, setJobLink] = useState("");
  const [jobType, setJobType] = useState("Job");
  const [experience, setExperience] = useState("");
  const [expiryDate, setExpiryDate] = useState("");

  const [userName, setUserName] = useState("");
  const [userCompany, setUserCompany] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [userMapLink, setUserMapLink] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const [errors, setErrors] = useState({});

  const validateFields = () => {
    const errors = {};
    if (!jobTitle) errors.jobTitle = "Job title is required";
    if (!jobCompany) errors.jobCompany = "Company name is required";
    if (!jobLocation) errors.jobLocation = "Location is required";
    if (!jobSalary) errors.jobSalary = "Salary is required";
    if (!jobDescription) errors.jobDescription = "Job description is required";
    if (!jobLink || !/^https?:\/\/[^\s]+$/.test(jobLink)) errors.jobLink = "Valid link is required";
    if (!userName) errors.userName = "Your name is required";
    if (!userCompany) errors.userCompany = "Your company name is required";
    if (!userAddress) errors.userAddress = "Company address is required";
    if (!userMapLink || !/^https?:\/\/[^\s]+$/.test(userMapLink)) errors.userMapLink = "Valid Google map link is required";
    if (!userPhone || !/^\d{10}$/.test(userPhone)) errors.userPhone = "Valid 10-digit phone number is required";
    if (!userEmail || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userEmail)) errors.userEmail = "Valid email address is required";
    return errors;
  };

  const handleRequestJob = async (e) => {
    e.preventDefault();
    const fieldErrors = validateFields();
    if (Object.keys(fieldErrors).length > 0) {
      setErrors(fieldErrors);
      return;
    }

    const formattedDescription = jobDescription.split("\n").join("<br/>");

    await addDoc(collection(db, "requestedJobs"), {
      title: jobTitle,
      company: jobCompany,
      location: jobLocation,
      salary: jobSalaryType ? `${jobSalary} ${jobSalaryType}` : jobSalary,
      description: formattedDescription,
      link: jobLink,
      type: jobType,
      experience,
      expiryDate,
      userName,
      userCompany,
      userAddress,
      userMapLink,
      userPhone,
      userEmail,
      date: serverTimestamp(),
      status: "Pending",
    });

    alert("Job request submitted successfully!");
    setJobTitle("");
    setJobCompany("");
    setJobLocation("");
    setJobSalary("");
    setJobSalaryType("");
    setJobDescription("");
    setJobLink("");
    setJobType("Job");
    setExperience("");
    setExpiryDate("");
    setUserName("");
    setUserCompany("");
    setUserAddress("");
    setUserMapLink("");
    setUserPhone("");
    setUserEmail("");
    setErrors({});
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Request Job</h1>
      <form onSubmit={handleRequestJob}>
        <input
          className="border p-2 w-full mb-2"
          type="text"
          placeholder="Job Title"
          value={jobTitle}
          onChange={(e) => setJobTitle(e.target.value)}
        />
        {errors.jobTitle && <p className="text-red-500">{errors.jobTitle}</p>}
        <input
          className="border p-2 w-full mb-2"
          type="text"
          placeholder="Company"
          value={jobCompany}
          onChange={(e) => setJobCompany(e.target.value)}
        />
        {errors.jobCompany && <p className="text-red-500">{errors.jobCompany}</p>}
        <input
          className="border p-2 w-full mb-2"
          type="text"
          placeholder="Work Location"
          value={jobLocation}
          onChange={(e) => setJobLocation(e.target.value)}
        />
        {errors.jobLocation && <p className="text-red-500">{errors.jobLocation}</p>}
        <div className="flex mb-2">
          <input
            className="border p-2 w-full"
            type="text"
            placeholder="Salary/Stipend (INR)"
            value={jobSalary}
            onChange={(e) => setJobSalary(e.target.value)}
          />
          <select
            className="border p-2"
            value={jobSalaryType}
            onChange={(e) => setJobSalaryType(e.target.value)}
          >
            <option value="">Select Salary Type</option>
            <option value="Monthly">Monthly</option>
            <option value="Yearly">Yearly</option>
          </select>
        </div>
        {errors.jobSalary && <p className="text-red-500">{errors.jobSalary}</p>}
        <textarea
          className="border p-2 w-full mb-2"
          placeholder="Job Description"
          value={jobDescription}
          onChange={(e) => setJobDescription(e.target.value)}
        />
        {errors.jobDescription && <p className="text-red-500">{errors.jobDescription}</p>}
        <input
          className="border p-2 w-full mb-2"
          type="text"
          placeholder="Link (External link where candidates will apply)"
          value={jobLink}
          onChange={(e) => setJobLink(e.target.value)}
        />
        {errors.jobLink && <p className="text-red-500">{errors.jobLink}</p>}
        <select
          className="border p-2 w-full mb-2"
          value={jobType}
          onChange={(e) => setJobType(e.target.value)}
        >
          <option value="Job">Job</option>
          <option value="Internship">Internship</option>
        </select>
        <select
          className="border p-2 w-full mb-2"
          value={experience}
          onChange={(e) => setExperience(e.target.value)}
        >
          <option value="">Select Experience Level</option>
          <option value="Freshers">Freshers</option>
          <option value="0-1 years">0-1 years</option>
          <option value="2+ years">2+ years</option>
        </select>
        <label className="block mb-2">
          Last Date to Apply:
          <input
            className="border p-2 w-full"
            type="date"
            value={expiryDate}
            onChange={(e) => setExpiryDate(e.target.value)}
          />
        </label>
        <h2 className="text-xl font-bold mb-4 mt-4">
          Please enter below details to verify job (Please note we don't spam you 🙂)
        </h2>
        <input
          className="border p-2 w-full mb-2"
          type="text"
          placeholder="Your Name"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
        />
        {errors.userName && <p className="text-red-500">{errors.userName}</p>}
        <input
          className="border p-2 w-full mb-2"
          type="text"
          placeholder="Your Company Name"
          value={userCompany}
          onChange={(e) => setUserCompany(e.target.value)}
        />
        {errors.userCompany && <p className="text-red-500">{errors.userCompany}</p>}
        <input
          className="border p-2 w-full mb-2"
          type="text"
          placeholder="Company Address"
          value={userAddress}
          onChange={(e) => setUserAddress(e.target.value)}
        />
        {errors.userAddress && <p className="text-red-500">{errors.userAddress}</p>}
        <input
          className="border p-2 w-full mb-2"
          type="text"
          placeholder="Google Map Link"
          value={userMapLink}
          onChange={(e) => setUserMapLink(e.target.value)}
        />
        {errors.userMapLink && <p className="text-red-500">{errors.userMapLink}</p>}
        <input
          className="border p-2 w-full mb-2"
          type="text"
          placeholder="Your Phone Number"
          value={userPhone}
          onChange={(e) => setUserPhone(e.target.value)}
        />
        {errors.userPhone && <p className="text-red-500">{errors.userPhone}</p>}
        <input
          className="border p-2 w-full mb-2"
          type="text"
          placeholder="Your Email"
          value={userEmail}
          onChange={(e) => setUserEmail(e.target.value)}
        />
        {errors.userEmail && <p className="text-red-500">{errors.userEmail}</p>}
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded"
          type="submit"
        >
          Request Job
        </button>
      </form>
    </div>
  );
};

export default RequestJob;
