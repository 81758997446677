import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase/firebaseConfig";
import { collection, addDoc, getDocs, serverTimestamp, query, where, updateDoc } from "firebase/firestore";
import ReactQuill from 'react-quill';
import CreatableSelect from 'react-select/creatable';
import 'react-quill/dist/quill.snow.css';

const AddJob = () => {
  const navigate = useNavigate();

  // Default values
  const defaultExpiryDate = new Date();
  defaultExpiryDate.setDate(defaultExpiryDate.getDate() + 21); // 3 weeks from current date

  const [title, setTitle] = useState("");
  const [company, setCompany] = useState({ value: "", label: "", categories: [] });
  const [location, setLocation] = useState("");
  const [salary, setSalary] = useState("Not Disclosed"); // Default to "Not Disclosed"
  const [salaryType, setSalaryType] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("Internship");
  const [link, setLink] = useState("");
  const [featured, setFeatured] = useState(false);
  const [experience, setExperience] = useState("Freshers"); // Default to "Freshers"
  const [expiryDate, setExpiryDate] = useState(defaultExpiryDate.toISOString().split('T')[0]); // Default to 3 weeks from today
  const [jobStatus, setJobStatus] = useState("Active");
  const [externalJob, setExternalJob] = useState(false); // New state variable for "External Job"
  const [categories, setCategories] = useState([]); // Job categories
  const [allCategories, setAllCategories] = useState([]); // All available categories from Firestore
  const [allCompanies, setAllCompanies] = useState([]); // All available companies from Firestore
  const [error, setError] = useState(""); // Error message state
  const [showPopup, setShowPopup] = useState(false); // Popup visibility state

  useEffect(() => {
    document.title = "Add Job - Internify Job Portal";
  
    const fetchCategoriesAndCompanies = async () => {
      try {
        // Fetch job categories
        const categoriesRef = collection(db, "jobCategories");
        const categoriesSnapshot = await getDocs(categoriesRef);
        const categoriesList = categoriesSnapshot.docs.map(doc => ({
          value: doc.data().name,
          label: doc.data().name
        }));
        setAllCategories(categoriesList);
  
        // Fetch companies
        const companiesRef = collection(db, "companycategories");
        const companiesSnapshot = await getDocs(companiesRef);
        const companiesList = companiesSnapshot.docs.map(doc => ({
          value: doc.data().name,
          label: doc.data().name
        }));
        setAllCompanies(companiesList);
      } catch (error) {
        console.error("Error fetching categories or companies:", error);
      }
    };
  
    fetchCategoriesAndCompanies();
  }, []);
  

  const formatSalary = (value) => {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleSalaryChange = (e) => {
    const value = e.target.value.replace(/,/g, '');
    setSalary(formatSalary(value));
  };

  const handleAddJob = async (e) => {
    e.preventDefault();
    
    if (!link) {
      setError("Job link is required.");
      return;
    }
  
    try {
      // Check if the company already exists
      const companiesRef = collection(db, "companycategories");
      const q = query(companiesRef, where("name", "==", company.value));
      const existingCompanies = await getDocs(q);
  
      if (existingCompanies.empty) {
        // If the company does not exist, create a new one and save it to Firestore
        await addDoc(companiesRef, { name: company.value, categories: company.categories });
      } else {
        // If the company exists, update its categories
        const existingCompanyDoc = existingCompanies.docs[0];
        await updateDoc(existingCompanyDoc.ref, {
          categories: company.categories
        });
      }
  
      const selectedCategories = categories.map(category => category.label);
  
      await addDoc(collection(db, "jobs"), {
        title,
        company: company.value, // Save company name, not ID
        location,
        salary: salaryType ? `${salary} ${salaryType}` : salary,
        description,
        type,
        link,
        featured,
        experience,
        expiryDate,
        jobStatus,
        externalJob,
        categories: selectedCategories,
        companyCategories: company.categories, // Save company categories with the job
        date: serverTimestamp(),
      });
  
      // Ensure all selected job categories exist in the "jobCategories" collection
      for (const category of categories) {
        if (!allCategories.map(c => c.value).includes(category.label)) {
          await addDoc(collection(db, "jobCategories"), { name: category.label });
          setAllCategories([...allCategories, { value: category.label, label: category.label }]);
        }
      }
  
      setShowPopup(true);
  
    } catch (error) {
      setError("Failed to add job. Please try again.");
      console.error("Error adding job:", error);
    }
  };
  

  const handleCompanyChange = async (selectedOption) => {
    try {
      const companiesRef = collection(db, "companycategories");
      const q = query(companiesRef, where("name", "==", selectedOption.value));
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        const existingCompany = querySnapshot.docs[0].data();
        setCompany({ value: existingCompany.name, label: existingCompany.name, categories: existingCompany.categories || [] });
      } else {
        setCompany(selectedOption);
      }
    } catch (error) {
      console.error("Error fetching company:", error);
    }
  };
  

  const handleCreateCompany = async (inputValue) => {
    const newCompany = { value: inputValue, label: inputValue, categories: [] };
    setCompany(newCompany);
  
    try {
      const companiesRef = collection(db, "companycategories");
      await addDoc(companiesRef, { name: inputValue, categories: [] });
      setAllCompanies(prevCompanies => [...prevCompanies, newCompany]);
    } catch (error) {
      console.error("Error creating company:", error);
    }
  };
  

  // Handle creation of new job categories
  const handleCreateCategory = async (inputValue) => {
    const newCategory = { value: inputValue, label: inputValue };
    setAllCategories(prevCategories => [...prevCategories, newCategory]);
    setCategories(prevCategories => [...prevCategories, newCategory]);
    await addDoc(collection(db, "jobCategories"), { name: inputValue });
  };

  const handlePopupClose = () => {
    // Reset the form and close the popup
    setTitle("");
    setCompany({ value: "", label: "", categories: [] });
    setLocation("");
    setSalary("Not Disclosed");
    setSalaryType("");
    setDescription("");
    setType("Internship");
    setLink("");
    setFeatured(false);
    setExperience("Freshers");
    setExpiryDate(defaultExpiryDate.toISOString().split('T')[0]);
    setJobStatus("Active");
    setExternalJob(false);
    setCategories([]);
    setShowPopup(false);
  };

  return (
    <>
      <div className="max-w-4xl mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">Add Job</h1>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <form onSubmit={handleAddJob}>
          <input
            className="border p-2 w-full mb-2"
            type="text"
            placeholder="Job Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <CreatableSelect
            value={company}
            onChange={handleCompanyChange}
            onCreateOption={handleCreateCompany}
            options={allCompanies} // List fetched companies
            placeholder="Select or create company..."
            className="react-select-container mb-2"
            classNamePrefix="react-select"
          />
          <input
            className="border p-2 w-full mb-2"
            type="text"
            placeholder="Location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            required
          />
          <div className="flex mb-2">
            <input
              className="border p-2 w-full"
              type="text"
              placeholder="Salary (INR)"
              value={salary}
              onChange={handleSalaryChange}
            />
            <select
              className="border p-2"
              value={salaryType}
              onChange={(e) => setSalaryType(e.target.value)}
            >
              <option value="">Select Salary Type</option>
              <option value="Monthly">Monthly</option>
              <option value="Yearly">Yearly</option>
            </select>
          </div>
          <ReactQuill
            className="border p-2 w-full mb-2"
            value={description}
            onChange={setDescription}
            placeholder="Description"
          />
          <select
            className="border p-2 w-full mb-2"
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <option value="Job">Job</option>
            <option value="Internship">Internship</option>
          </select>
          <select
            className="border p-2 w-full mb-2"
            value={experience}
            onChange={(e) => setExperience(e.target.value)}
          >
            <option value="Freshers">Freshers</option>
            <option value="0-1 years">0-1 years</option>
            <option value="1-2 years">1-2 years</option>
            <option value="2+ years">2+ years</option>
          </select>
          <label className="block mb-2">
            Expiry Date:
            <input
              className="border p-2 w-full"
              type="date"
              value={expiryDate}
              onChange={(e) => setExpiryDate(e.target.value)}
            />
          </label>
          <select
            className="border p-2 w-full mb-2"
            value={jobStatus}
            onChange={(e) => setJobStatus(e.target.value)}
          >
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </select>
          <input
            className="border p-2 w-full mb-2"
            type="text"
            placeholder="Link"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            required
          />
          <div className="flex items-center mb-2">
            <input
              type="checkbox"
              checked={featured}
              onChange={(e) => setFeatured(e.target.checked)}
              className="mr-2"
            />
            <label>Featured Job</label>
          </div>
          <div className="flex items-center mb-2">
            <input
              type="checkbox"
              checked={externalJob}
              onChange={(e) => setExternalJob(e.target.checked)}
              className="mr-2"
            />
            <label>External Job</label>
          </div>

          <label className="block mb-2">
            Job Categories:
            <CreatableSelect
              isMulti
              options={allCategories}
              value={categories}
              onChange={setCategories}
              onCreateOption={handleCreateCategory}
              placeholder="Select or create categories..."
              className="react-select-container"
              classNamePrefix="react-select"
            />
          </label>

          <button
            className="bg-blue-500 text-white px-4 py-2 rounded"
            type="submit"
          >
            Add Job
          </button>
        </form>
      </div>

      {/* Popup Modal */}
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-lg font-bold mb-4">Job Added Successfully!</h2>
            <p>Your job has been added.🙂 </p>
            <button
              className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
              onClick={handlePopupClose}
            >
              OK
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default AddJob;
