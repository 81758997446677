import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const Sidebar = ({ toggleMenu }) => {
  const { currentUser, logout } = useAuth();
  const isAdmin = currentUser && currentUser.email === 'jobs@synergyworks.online';

  const handleLogout = async () => {
    const confirmed = window.confirm('Are you sure you want to logout?');
    if (confirmed) {
      try {
        await logout();
        toggleMenu();
      } catch (error) {
        console.error('Failed to logout:', error);
      }
    }
  };

  return (
    <div className="fixed inset-0 bg-white z-50 p-4">
      <div className="flex justify-between items-center mb-8">
        <div className="text-2xl font-bold">
          <span className="block text-black">Internify</span>
          <span className="block text-orange-600 text-sm">Internship & Jobs Portal</span>
        </div>
        <button onClick={toggleMenu} className="text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600">
          <svg className="h-8 w-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
      </div>
      <nav aria-label="Global">
        <ul className="flex flex-col items-start gap-6 text-lg mb-8">
          <li>
            <Link onClick={toggleMenu} className="text-gray-800 transition hover:text-gray-600" to="/jobs-list">Jobs</Link>
          </li>
          {currentUser && !isAdmin && (
            <li>
              <Link onClick={toggleMenu} className="text-gray-800 transition hover:text-gray-600" to="/applied-jobs">Applied Jobs</Link>
            </li>
          )}
          {isAdmin && (
            <>
              <li>
                <Link onClick={toggleMenu} className="text-gray-800 transition hover:text-gray-600" to="/admin">Dashboard</Link>
              </li>
              <li>
                <Link onClick={toggleMenu} className="text-gray-800 transition hover:text-gray-600" to="/users">Users</Link>
              </li>
              
              <li>
                <Link onClick={toggleMenu} className="text-gray-800 transition hover:text-gray-600" to="/add-job">Add Job</Link>
              </li>
              <li>
                <Link onClick={toggleMenu} className="text-gray-800 transition hover:text-gray-600" to="/aj">Applied Jobs</Link>
              </li>
              <li>
                <Link onClick={toggleMenu} className="text-gray-800 transition hover:text-gray-600" to="/logs">Logs</Link>
              </li>
              <li>
                <Link onClick={toggleMenu} className="text-gray-800 transition hover:text-gray-600" to="/requested-jobs">Requested Jobs</Link>
              </li>
            </>
          )}
          {currentUser && (
            <li>
              <Link onClick={toggleMenu} className="text-gray-800 transition hover:text-gray-600" to="/profile">Profile</Link>
            </li>
          )}
        </ul>
      </nav>
      <div className="flex flex-col items-center gap-4">
        {currentUser ? (
          <button onClick={handleLogout} className="bg-red-600 text-white px-6 py-3 rounded w-full">
            Logout
          </button>
        ) : (
          <>
            <Link onClick={toggleMenu} to="/login" className="bg-blue-500 text-white px-4 py-2 rounded w-full text-center">
              Login
            </Link>
            <Link onClick={toggleMenu} to="/register" className="bg-gray-200 text-gray-800 px-4 py-2 rounded w-full text-center">
              Register
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
