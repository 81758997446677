import React, { useEffect, useState } from 'react';
import { db } from '../firebase/firebaseConfig';
import { collection, getDocs, updateDoc, doc, query, orderBy, limit, getDoc, deleteDoc, where } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import moment from 'moment';

const Admin = () => {
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [reportedJobs, setReportedJobs] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const jobsPerPage = 10;

  useEffect(() => {
    const fetchInitialJobs = async () => {
      setLoading(true);
      const q = query(collection(db, 'jobs'), orderBy('date', 'desc'), limit(100));
      const querySnapshot = await getDocs(q);
      const jobsData = await Promise.all(
        querySnapshot.docs.map(async (jobDoc) => {
          const job = { id: jobDoc.id, ...jobDoc.data() };
          const appliedJobsQuery = query(collection(db, 'appliedJobs'), where('jobId', '==', jobDoc.id));
          const appliedJobsSnapshot = await getDocs(appliedJobsQuery);
          const applyCount = appliedJobsSnapshot.size;
          return { ...job, applyCount };
        })
      );
      setJobs(jobsData);
      setFilteredJobs(jobsData);
      setLoading(false);
    };

    const fetchReportedJobs = async () => {
      const q = query(collection(db, 'reportedJobs'), orderBy('reportedAt', 'desc'));
      const querySnapshot = await getDocs(q);
      const reportedJobsData = await Promise.all(
        querySnapshot.docs.map(async reportDoc => {
          const reportData = reportDoc.data();
          const jobDoc = await getDoc(doc(db, "jobs", reportData.jobId));
          const userDoc = await getDoc(doc(db, "users", reportData.userId));
          return {
            id: reportDoc.id,
            jobTitle: jobDoc.exists() ? jobDoc.data().title : "Unknown",
            userName: userDoc.exists() ? userDoc.data().name : "Unknown",
            userEmail: userDoc.exists() ? userDoc.data().email : "Unknown",
            reason: reportData.reason,
            reportedAt: reportData.reportedAt,
            jobId: reportData.jobId
          };
        })
      );
      setReportedJobs(reportedJobsData);
    };

    fetchInitialJobs();
    fetchReportedJobs();
  }, []);

  const handleMakeInactive = async (jobId) => {
    const confirmed = window.confirm('Are you sure you want to make this job inactive?');
    if (confirmed) {
      await updateDoc(doc(db, 'jobs', jobId), { jobStatus: 'Inactive' });
      const updatedJobs = jobs.map(job => job.id === jobId ? { ...job, jobStatus: 'Inactive' } : job);
      setJobs(updatedJobs);
      setFilteredJobs(updatedJobs);
    }
  };

  const handleDelete = async (jobId) => {
    const confirmed = window.confirm('Are you sure you want to delete this job?');
    if (confirmed) {
      await deleteDoc(doc(db, 'jobs', jobId));
      setJobs(jobs.filter(job => job.id !== jobId));
      setFilteredJobs(filteredJobs.filter(job => job.id !== jobId));
    }
  };

  const handleCopy = (job) => {
    const jobLink = `https://internify.online/job/${job.id}`;
    let jobText = `Exciting Career Opportunities at ${job.company}!\n\nPosition: ${job.title}`;
  
    if (job.location) {
      jobText += `\nLocations: ${job.location}`;
    }
    if (job.experience) {
      jobText += `\nExperience: ${job.experience}`;
    }
    if (job.salary) {
      jobText += `\nSalary Range: ${job.salary}`;
    } else if (job.type.toLowerCase() === 'internship' && job.stipend) {
      jobText += `\nStipend: ${job.stipend}`;
    }
  
    jobText += `\n\nApply Now: ${jobLink}`;
  
        // WhatsApp channel section
        jobText += `\n\n-------------------------------------\n\nWhatsApp Group For More Internships, Jobs & Updates: https://beacons.ai/internify`;
  

       // Separate section for the ad
        jobText += `\n\n--------------------------------------\n\n🎓 Get your test-based certificate for just ₹49! 🏅✨ Click here to grab yours now: https://synergyworks.online/form 🚀`;
  

    if (navigator.clipboard) {
      navigator.clipboard.writeText(jobText).then(() => {
        alert('Job details copied to clipboard!');
      }, (err) => {
        console.error('Could not copy text: ', err);
      });
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = jobText;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        alert('Job details copied to clipboard!');
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }
      document.body.removeChild(textArea);
    }
  };

  const handleSearch = async () => {
    if (searchQuery) {
      setLoading(true);
      const querySnapshot = await getDocs(collection(db, 'jobs'));
      const jobsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      jobsData.sort((a, b) => {
        if (a.date && b.date) {
          return new Date(b.date.seconds * 1000) - new Date(a.date.seconds * 1000);
        }
        return 0;
      });

      const filtered = jobsData.filter(job =>
        job.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        job.company.toLowerCase().includes(searchQuery.toLowerCase())
      );

      setJobs(jobsData);
      setFilteredJobs(filtered);
      setCurrentPage(1);
      setLoading(false);
    }
  };

  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="container mx-auto px-4 sm:px-8">
      <h1 className="text-3xl font-bold mb-4">Admin Panel</h1>
      <div className="flex mb-4">
        <Link to="/add-job" className="bg-blue-500 text-white px-4 py-2 rounded mr-4">Add Job</Link>
        <Link to="/users" className="bg-green-500 text-white px-4 py-2 rounded">Manage Users</Link>
      </div>
      <div className="flex mb-4">
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search jobs by title or company"
          className="border rounded px-4 py-2 w-full"
        />
        <button onClick={handleSearch} className="bg-blue-500 text-white px-4 py-2 rounded ml-2">Search</button>
      </div>
      {loading ? (
        <div className="text-center">Loading...</div>
      ) : (
        <>
          <h2 className="text-2xl font-bold mb-4">Jobs</h2>
          <div className="overflow-x-auto">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Title
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Applications
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Company
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Category
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Location
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Salary
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Date Added
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentJobs.map(job => (
                  <tr key={job.id}>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <Link to={`/job/${job.id}`} className="text-blue-500 underline">
                        {job.title}
                      </Link>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">{job.applyCount}</p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">{job.company}</p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">{job.categories}</p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">{job.location}</p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">{job.salary}</p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {job.date ? moment(job.date.toDate()).format('Do MMMM YYYY, h:mm a') : 'N/A'}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className={`text-gray-900 whitespace-no-wrap ${job.jobStatus === 'Inactive' ? 'text-red-500' : ''}`}>
                        {job.jobStatus}
                      </p>
                    </td>

                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <div className="flex space-x-2">
                        <Link to={`/edit-job/${job.id}`} className="bg-yellow-500 text-white px-4 py-2 rounded">Edit</Link>
                        <button className="bg-red-500 text-white px-4 py-2 rounded" onClick={() => handleDelete(job.id)}>Delete</button>
                        <button className="bg-blue-500 text-white px-4 py-2 rounded" onClick={() => handleCopy(job)}>Copy</button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex justify-center mt-4">
            {Array.from({ length: Math.ceil(filteredJobs.length / jobsPerPage) }, (_, index) => (
              <button
                key={index}
                onClick={() => paginate(index + 1)}
                className={`mx-1 px-3 py-1 border rounded ${index + 1 === currentPage ? 'bg-blue-500 text-white' : 'bg-white text-blue-500'}`}
              >
                {index + 1}
              </button>
            ))}
          </div>
          <h2 className="text-2xl font-bold mb-4 mt-8">Reported Jobs</h2>
          <div className="overflow-x-auto">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Job Title
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    User Name
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Email ID
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Reason
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Reported At
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {reportedJobs.map(report => (
                  <tr key={report.id}>
<td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
  <Link to={`/job/${report.jobId}`} className="text-blue-500 underline">
    {report.jobTitle}
  </Link>
</td>

                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">{report.userName}</p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">{report.userEmail}</p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">{report.reason}</p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {report.reportedAt ? moment(report.reportedAt.toDate()).format('Do MMMM YYYY, h:mm a') : 'N/A'}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <div className="flex space-x-2">
                        <button className="bg-red-500 text-white px-4 py-2 rounded" onClick={() => handleMakeInactive(report.jobId)}>Make Inactive</button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default Admin;
