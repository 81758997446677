import React, { useEffect } from 'react';

const AdBanner = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
      atOptions = {
        'key' : '64318eeb6cc3ea5bfe475637cfbc3b3b',
        'format' : 'iframe',
        'height' : 50,
        'width' : 320,
        'params' : {}
      };
    `;
    
    const invokeScript = document.createElement('script');
    invokeScript.type = 'text/javascript';
    invokeScript.src = 'https://www.topcreativeformat.com/64318eeb6cc3ea5bfe475637cfbc3b3b/invoke.js';
    invokeScript.async = true;

    const adContainer = document.getElementById('ad-container');
    if (adContainer) {
      adContainer.appendChild(script);
      adContainer.appendChild(invokeScript);
    }
  }, []);

  return <div id="ad-container"></div>;
};

const JobList = () => {
  const jobs = [
    { id: '1', title: 'Frontend Developer', company: 'Company A', type: 'Job', location: 'City A', salary: '50,000' },
    { id: '2', title: 'Backend Developer', company: 'Company B', type: 'Internship', location: 'City B', salary: '60,000' },
  ];

  return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
        {jobs.map((job, index) => (
          <React.Fragment key={job.id}>
            <div className="rounded-lg shadow-md flex flex-col justify-between bg-white p-4">
              <div className="text-left">
                <h2 className="text-2xl font-bold mb-1">{job.title}</h2>
                <div className="text-gray-500 text-lg">{job.company}</div>
              </div>
              <div className="text-lg text-gray-600">
                {job.location} | {job.type} | ₹{job.salary}
              </div>
            </div>

            {/* Ad banner between job cards */}
            {(index + 1) % 1 === 0 && (
              <div key={`ad-${index}`} className="my-4">
                <AdBanner />
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default JobList;
