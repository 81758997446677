import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { db } from '../firebase/firebaseConfig';
import { useAuth } from '../context/AuthContext';
import ReactPaginate from 'react-paginate';
import moment from 'moment';

const AppliedJobs = () => {
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentUser } = useAuth();

  // Pagination states
  const [currentPage, setCurrentPage] = useState(0);
  const jobsPerPage = 5;

  useEffect(() => {
    const fetchAppliedJobs = async () => {
      if (currentUser) {
        try {
          const q = query(collection(db, 'appliedJobs'), where('userId', '==', currentUser.uid));
          const querySnapshot = await getDocs(q);
          const jobsData = querySnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
          }));
          setAppliedJobs(jobsData);
        } catch (error) {
          console.error('Error fetching applied jobs:', error);
          setError('Error fetching applied jobs.');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchAppliedJobs();
  }, [currentUser]);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const offset = currentPage * jobsPerPage;
  const currentJobs = appliedJobs.slice(offset, offset + jobsPerPage);
  const pageCount = Math.ceil(appliedJobs.length / jobsPerPage);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div style={{ maxWidth: '800px', margin: '0 auto', padding: '20px', backgroundColor: '#f9fafb', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
      <h1 style={{ fontSize: '2rem', fontWeight: 'bold', marginBottom: '1rem', textAlign: 'center' }}>Applied Jobs</h1>
      <div style={{ display: 'grid', gap: '1rem' }}>
        {appliedJobs.length === 0 ? (
          <p>No jobs applied yet.</p>
        ) : (
          currentJobs.map(job => (
            <div key={job.id} style={{ border: '1px solid #ddd', borderRadius: '8px', padding: '16px', backgroundColor: 'white', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
              <h2 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '8px' }}>{job.title}</h2>
              <p style={{ marginBottom: '8px', padding: '4px 8px', borderRadius: '10px', boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)', display: 'inline-block', backgroundColor: '#f1f1f1' }}>
                Status: {job.status}
              </p>
              <Link to={`/job/${job.jobId}`} style={{ color: '#007bff', textDecoration: 'underline', marginBottom: '8px', display: 'block' }}>
                View Job Details
              </Link>
              <p style={{ fontSize: '0.875rem', color: '#d9534f' }}>This job is applied in an external source, no status will be updated.</p>
            </div>
          ))
        )}
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
        <ReactPaginate
          previousLabel={'Previous'}
          nextLabel={'Next'}
          breakLabel={'...'}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={'pagination'}
          activeClassName={'active'}
          previousClassName={'page-item'}
          nextClassName={'page-item'}
          pageClassName={'page-item'}
          breakClassName={'page-item'}
          pageLinkClassName={'page-link'}
          previousLinkClassName={'page-link'}
          nextLinkClassName={'page-link'}
          breakLinkClassName={'page-link'}
        />
      </div>
      <style>
        {`
          .pagination {
            display: flex;
            list-style: none;
            padding: 0;
            margin: 0;
          }
          .page-item {
            margin: 0 4px;
          }
          .page-link {
            padding: 8px 16px;
            border: 1px solid #ddd;
            color: #007bff;
            cursor: pointer;
            text-decoration: none;
          }
          .page-link:hover {
            background-color: #f1f1f1;
          }
          .active .page-link {
            background-color: #007bff;
            color: white;
            border: 1px solid #007bff;
          }
        `}
      </style>
    </div>
  );
};

export default AppliedJobs;
