import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../firebase/firebaseConfig";
import { doc, getDoc, updateDoc, getDocs, collection, query, where, addDoc } from "firebase/firestore";
import ReactQuill from 'react-quill';
import CreatableSelect from 'react-select/creatable';
import 'react-quill/dist/quill.snow.css';

const EditJob = () => {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const [job, setJob] = useState({
    title: "",
    company: { value: "", label: "" },
    location: "",
    salary: "",
    salaryType: "",
    description: "",
    type: "Job",
    link: "",
    featured: false,
    experience: "",
    expiryDate: "",
    jobStatus: "Active",
    externalJob: false, // New state variable for "External Job"
    categories: [] // Job categories
  });
  const [allCategories, setAllCategories] = useState([]); // All available categories from Firestore
  const [allCompanies, setAllCompanies] = useState([]); // All available companies from Firestore
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    document.title = "Edit Job - Internify Job Portal";

    const fetchJobAndCategories = async () => {
      try {
        // Fetch job details
        const jobDoc = await getDoc(doc(db, "jobs", jobId));
        if (jobDoc.exists()) {
          const jobData = jobDoc.data();
          const [salary, salaryType] = jobData.salary.split(" ");
          const selectedCategories = jobData.categories || [];

          setJob({
            ...jobData,
            salary,
            salaryType: salaryType || "",
            externalJob: jobData.externalJob || false,
            company: { value: jobData.company, label: jobData.company },
            categories: selectedCategories.map(category => ({
              value: category,
              label: category
            }))
          });
        } else {
          setError("Job not found.");
          return;
        }

        // Fetch all categories
        const categoriesSnapshot = await getDocs(collection(db, "jobCategories"));
        const categoriesList = categoriesSnapshot.docs.map(doc => ({
          value: doc.data().name, // Fetch the category name as value
          label: doc.data().name // Fetch the category name as label
        }));
        setAllCategories(categoriesList);

        // Fetch all companies
        const companiesSnapshot = await getDocs(collection(db, "companycategories"));
        const companiesList = companiesSnapshot.docs.map(doc => ({
          value: doc.data().name, // Fetch the company name as value
          label: doc.data().name // Fetch the company name as label
        }));
        setAllCompanies(companiesList);

      } catch (err) {
        setError("Error fetching job details.");
      } finally {
        setLoading(false);
      }
    };

    fetchJobAndCategories();
  }, [jobId]);

  const formatSalary = (value) => {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleSalaryChange = (e) => {
    const value = e.target.value.replace(/,/g, '');
    setJob((prevJob) => ({
      ...prevJob,
      salary: formatSalary(value)
    }));
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setJob((prevJob) => ({
      ...prevJob,
      [name]: type === "checkbox" ? checked : value
    }));
  };

  const handleDescriptionChange = (value) => {
    setJob((prevJob) => ({
      ...prevJob,
      description: value
    }));
  };

  const handleCategoryChange = (selectedOptions) => {
    setJob((prevJob) => ({
      ...prevJob,
      categories: selectedOptions
    }));
  };

  const handleCreateCategory = async (inputValue) => {
    const newCategory = { value: inputValue, label: inputValue };
    setAllCategories(prevCategories => [...prevCategories, newCategory]);
    setJob((prevJob) => ({
      ...prevJob,
      categories: [...prevJob.categories, newCategory]
    }));
    await addDoc(collection(db, "jobCategories"), { name: inputValue });
  };

  const handleCompanyChange = async (selectedOption) => {
    try {
      const companiesRef = collection(db, "companycategories");
      const q = query(companiesRef, where("name", "==", selectedOption.value));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const existingCompany = querySnapshot.docs[0].data();
        setJob((prevJob) => ({
          ...prevJob,
          company: { value: existingCompany.name, label: existingCompany.name }
        }));
      } else {
        setJob((prevJob) => ({
          ...prevJob,
          company: selectedOption
        }));
      }
    } catch (error) {
      console.error("Error fetching company:", error);
    }
  };

  const handleCreateCompany = async (inputValue) => {
    const newCompany = { value: inputValue, label: inputValue };
    setJob((prevJob) => ({
      ...prevJob,
      company: newCompany
    }));

    try {
      const companiesRef = collection(db, "companycategories");
      await addDoc(companiesRef, { name: inputValue });
      setAllCompanies(prevCompanies => [...prevCompanies, newCompany]);
    } catch (error) {
      console.error("Error creating company:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Check if the company already exists
      const companiesRef = collection(db, "companycategories");
      const q = query(companiesRef, where("name", "==", job.company.value));
      const existingCompanies = await getDocs(q);

      if (existingCompanies.empty) {
        // If the company does not exist, create a new one
        await addDoc(companiesRef, { name: job.company.value });
      }

      const updatedJobData = {
        ...job,
        salary: job.salaryType ? `${job.salary} ${job.salaryType}` : job.salary,
        company: job.company.value, // Save company name, not ID
        categories: job.categories.map(category => category.label) // Save the category names
      };

      await updateDoc(doc(db, "jobs", jobId), updatedJobData);
      navigate("/admin");
    } catch (err) {
      console.error("Error updating job:", err);
      setError("Error updating job details.");
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Edit Job</h1>
      <form onSubmit={handleSubmit}>
        <input
          className="border p-2 w-full mb-2"
          type="text"
          name="title"
          placeholder="Job Title"
          value={job.title}
          onChange={handleChange}
        />
        <CreatableSelect
          value={job.company}
          onChange={handleCompanyChange}
          onCreateOption={handleCreateCompany}
          options={allCompanies} // List fetched companies
          placeholder="Select or create company..."
          className="react-select-container mb-2"
          classNamePrefix="react-select"
        />
        <input
          className="border p-2 w-full mb-2"
          type="text"
          name="location"
          placeholder="Location"
          value={job.location}
          onChange={handleChange}
        />
        <div className="flex mb-2">
          <input
            className="border p-2 w-full"
            type="text"
            name="salary"
            placeholder="Salary (INR)"
            value={job.salary}
            onChange={handleSalaryChange}
          />
          <select
            className="border p-2"
            name="salaryType"
            value={job.salaryType}
            onChange={handleChange}
          >
            <option value="">Select Salary Type</option>
            <option value="Monthly">Monthly</option>
            <option value="Yearly">Yearly</option>
          </select>
        </div>
        <ReactQuill
          className="border p-2 w-full mb-2"
          value={job.description}
          onChange={handleDescriptionChange}
          placeholder="Description"
        />
        <select
          className="border p-2 w-full mb-2"
          name="type"
          value={job.type}
          onChange={handleChange}
        >
          <option value="Job">Job</option>
          <option value="Internship">Internship</option>
        </select>
        <select
          className="border p-2 w-full mb-2"
          name="experience"
          value={job.experience}
          onChange={handleChange}
        >
          <option value="">Select Experience Level</option>
          <option value="Freshers">Freshers</option>
          <option value="0-1 years">0-1 years</option>
          <option value="2+ years">2+ years</option>
        </select>
        <label className="block mb-2">
          Expiry Date:
          <input
            className="border p-2 w-full"
            type="date"
            name="expiryDate"
            value={job.expiryDate}
            onChange={handleChange}
          />
        </label>
        <select
          className="border p-2 w-full mb-2"
          name="jobStatus"
          value={job.jobStatus}
          onChange={handleChange}
        >
          <option value="Active">Active</option>
          <option value="Inactive">Inactive</option>
        </select>
        <input
          className="border p-2 w-full mb-2"
          type="text"
          name="link"
          placeholder="Link"
          value={job.link}
          onChange={handleChange}
        />
        <div className="flex items-center mb-2">
          <input
            type="checkbox"
            name="featured"
            checked={job.featured}
            onChange={handleChange}
            className="mr-2"
          />
          <label>Featured Job</label>
        </div>
        <div className="flex items-center mb-2">
          <input
            type="checkbox"
            name="externalJob"
            checked={job.externalJob}
            onChange={handleChange}
            className="mr-2"
          />
          <label>External Job</label>
        </div>

        <label className="block mb-2">
          Job Categories:
          <CreatableSelect
            isMulti
            options={allCategories}
            value={job.categories}
            onChange={handleCategoryChange}
            onCreateOption={handleCreateCategory}
            placeholder="Select or create categories..."
            className="react-select-container"
            classNamePrefix="react-select"
          />
        </label>

        <button
          className="bg-blue-500 text-white px-4 py-2 rounded"
          type="submit"
        >
          Save Changes
        </button>
      </form>
    </div>
  );
};

export default EditJob;
