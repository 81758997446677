import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { db } from '../firebase/firebaseConfig';
import { doc, getDoc, setDoc } from 'firebase/firestore';

const Profile = () => {
  const { currentUser } = useAuth();
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    addressLine1: '',
    city: '',
    postalCode: '',
    email: '',
    jobTitle: '',
    company: '',
    workLocation: '',
    roleDescription: '',
    schoolOrUniversity: '',
  });
  const [missingFields, setMissingFields] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (currentUser) {
        try {
          const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
          let data = userDoc.exists() ? userDoc.data() : { email: currentUser.email };

          setUserData({
            firstName: data.firstName || '',
            lastName: data.lastName || '',
            addressLine1: data.addressLine1 || '',
            city: data.city || '',
            postalCode: data.postalCode || '',
            email: data.email || currentUser.email,
            jobTitle: data.jobTitle || '',
            company: data.company || '',
            workLocation: data.workLocation || '',
            roleDescription: data.roleDescription || '',
            schoolOrUniversity: data.schoolOrUniversity || '',
          });

          // Determine missing fields for alerting users
          const fields = [];
          if (!data.firstName) fields.push('First Name');
          if (!data.lastName) fields.push('Last Name');
          if (!data.addressLine1) fields.push('Address Line 1');
          if (!data.city) fields.push('City');
          if (!data.postalCode) fields.push('Postal Code');
          if (!data.jobTitle) fields.push('Job Title');
          if (!data.company) fields.push('Company');
          if (!data.workLocation) fields.push('Work Location');
          if (!data.roleDescription) fields.push('Role Description');
          if (!data.schoolOrUniversity) fields.push('School or University');
          setMissingFields(fields);

          // Send UID to the extension using window.postMessage
          window.postMessage({ uid: currentUser.uid }, '*');

        } catch (error) {
          console.error('Error fetching user profile:', error);
        }
      }
    };

    fetchUserProfile();
  }, [currentUser]);

  const handleInputChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = async () => {
    if (missingFields.length > 0) {
      setError('Please fill out all required fields.');
      return;
    }

    if (currentUser) {
      try {
        await setDoc(doc(db, 'users', currentUser.uid), userData, { merge: true });
        setMissingFields([]);
        setError('');
      } catch (error) {
        setError('Failed to save user data');
        console.error('Error saving user profile:', error);
      }
    }
  };

  return (
    <section className="bg-white">
      <div className="flex items-center justify-center px-8 py-8">
        <div className="max-w-md w-full">
          <h2 className="text-2xl font-bold text-center mt-4">Your Profile</h2>
          <div className="mt-8">
            {/* Personal Information Section */}
            <h3 className="text-lg font-bold text-gray-700">Personal Information</h3>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">First Name</label>
              <input
                type="text"
                name="firstName"
                value={userData.firstName}
                onChange={handleInputChange}
                className="mt-1 w-full h-12 rounded-lg border-gray-200 bg-white text-sm text-gray-700 shadow-md"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Last Name</label>
              <input
                type="text"
                name="lastName"
                value={userData.lastName}
                onChange={handleInputChange}
                className="mt-1 w-full h-12 rounded-lg border-gray-200 bg-white text-sm text-gray-700 shadow-md"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Address Line 1</label>
              <input
                type="text"
                name="addressLine1"
                value={userData.addressLine1}
                onChange={handleInputChange}
                className="mt-1 w-full h-12 rounded-lg border-gray-200 bg-white text-sm text-gray-700 shadow-md"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">City</label>
              <input
                type="text"
                name="city"
                value={userData.city}
                onChange={handleInputChange}
                className="mt-1 w-full h-12 rounded-lg border-gray-200 bg-white text-sm text-gray-700 shadow-md"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Postal Code</label>
              <input
                type="text"
                name="postalCode"
                value={userData.postalCode}
                onChange={handleInputChange}
                className="mt-1 w-full h-12 rounded-lg border-gray-200 bg-white text-sm text-gray-700 shadow-md"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                name="email"
                value={userData.email}
                className="mt-1 w-full h-12 rounded-lg border-gray-200 bg-gray-200 text-sm text-gray-700 shadow-md"
                disabled // Make this field non-editable
              />
            </div>

            {/* Work Experience Section */}
            <h3 className="text-lg font-bold text-gray-700 mt-8">Work Experience</h3>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Job Title</label>
              <input
                type="text"
                name="jobTitle"
                value={userData.jobTitle}
                onChange={handleInputChange}
                className="mt-1 w-full h-12 rounded-lg border-gray-200 bg-white text-sm text-gray-700 shadow-md"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Company</label>
              <input
                type="text"
                name="company"
                value={userData.company}
                onChange={handleInputChange}
                className="mt-1 w-full h-12 rounded-lg border-gray-200 bg-white text-sm text-gray-700 shadow-md"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Work Location</label>
              <input
                type="text"
                name="workLocation"
                value={userData.workLocation}
                onChange={handleInputChange}
                className="mt-1 w-full h-12 rounded-lg border-gray-200 bg-white text-sm text-gray-700 shadow-md"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Role Description</label>
              <textarea
                name="roleDescription"
                value={userData.roleDescription}
                onChange={handleInputChange}
                className="mt-1 w-full rounded-lg border-gray-200 bg-white text-sm text-gray-700 shadow-md"
                rows="4"
                required
              />
            </div>

            {/* Education Section */}
            <h3 className="text-lg font-bold text-gray-700 mt-8">Education</h3>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">School or University</label>
              <input
                type="text"
                name="schoolOrUniversity"
                value={userData.schoolOrUniversity}
                onChange={handleInputChange}
                className="mt-1 w-full h-12 rounded-lg border-gray-200 bg-white text-sm text-gray-700 shadow-md"
                required
              />
            </div>

            {/* Save Button */}
            {missingFields.length > 0 && (
              <button
                onClick={handleSave}
                className="w-full h-12 rounded-lg border border-blue-600 bg-blue-600 text-sm font-medium text-white shadow-md hover:bg-transparent hover:text-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600"
              >
                Save
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="mt-6 text-center">
        <a
          href="https://forms.gle/NHt6pGSTPfrjkzBK8"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button
            className="px-4 py-2 rounded-lg bg-red-600 text-white text-sm font-medium shadow-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-blue-600"
          >
            Facing any difficulties? Click here
          </button>
        </a>
      </div>
      <br></br>
      <br></br>
    </section>
  );
};

export default Profile;
