import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { db } from '../firebase/firebaseConfig';
import { doc, getDoc, setDoc, increment, Timestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { FiUser, FiMapPin, FiPhone, FiMail } from 'react-icons/fi';
import { FaInstagram, FaLinkedin, FaWhatsapp } from 'react-icons/fa';

const Waitlist = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    phone: '',
    location: '',
    tokenNumber: null,
  });
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [message, setMessage] = useState('');
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (currentUser) {
        try {
          const userDoc = await getDoc(doc(db, 'waitlist', currentUser.uid));
          if (userDoc.exists()) {
            setUserData({
              name: userDoc.data().name,
              email: userDoc.data().email,
              phone: userDoc.data().phone,
              location: userDoc.data().location,
              tokenNumber: userDoc.data().tokenNumber,
            });
            setRegistered(true);
            setMessage(
              `Thanks for registering, ${userDoc.data().name}! Your token is ${userDoc.data().tokenNumber}. We will get back to you once the access starts.`
            );
          } else {
            const profileDoc = await getDoc(doc(db, 'users', currentUser.uid));
            if (profileDoc.exists()) {
              const data = profileDoc.data();
              setUserData({
                name: data.username || '',
                email: currentUser.email || data.email || '',
                phone: data.phone || '',
                location: data.location || '',
                tokenNumber: null,
              });
            } else {
              setUserData({
                ...userData,
                email: currentUser.email || '', // Use email from Google Auth
              });
            }
          }
          setDataLoaded(true);
        } catch (error) {
          console.error('Error fetching user profile:', error);
        }
      } else {
        setMessage('Signup to register');
      }
    };

    fetchUserProfile();

    const countdown = () => {
      const targetDate = new Date('September 20, 2024 00:00:00').getTime();
      const now = new Date().getTime();
      const distance = targetDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTimeLeft({ days, hours, minutes, seconds });
    };

    const interval = setInterval(countdown, 1000);
    return () => clearInterval(interval);
  }, [currentUser, navigate]);

  useEffect(() => {
    const isMobile = window.innerWidth <= 768;

    const loadAdScript = (key, format, height, width, src) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = `
        atOptions = {
          'key' : '${key}',
          'format' : '${format}',
          'height' : ${height},
          'width' : ${width},
          'params' : {}
        };
      `;
      
      const invokeScript = document.createElement('script');
      invokeScript.type = 'text/javascript';
      invokeScript.src = src;
      invokeScript.async = true;

      const adContainer = document.getElementById('ad-container');
      if (adContainer) {
        adContainer.appendChild(script);
        adContainer.appendChild(invokeScript);
      }
    };

    if (isMobile) {
      loadAdScript(
        '64318eeb6cc3ea5bfe475637cfbc3b3b',
        'iframe',
        50,
        320,
        'https://seduceboozeblackmail.com/64318eeb6cc3ea5bfe475637cfbc3b3b/invoke.js'
      );
    } else {
      loadAdScript(
        'bc15b7e9355f97d4b12c2629809ce390',
        'iframe',
        90,
        728,
        'https://seduceboozeblackmail.com/bc15b7e9355f97d4b12c2629809ce390/invoke.js'
      );
    }

    return () => {
      const adContainer = document.getElementById('ad-container');
      if (adContainer) adContainer.innerHTML = '';
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setIsEditing(true);  // User is now editing
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
};


  const handleSave = async () => {
    if (!userData.name || !userData.email || !userData.phone || !userData.location) {
      setMessage('Please fill out all required fields.');
      return;
    }

    if (currentUser) {
      try {
        const userRef = doc(db, 'waitlist', currentUser.uid);
        const userDoc = await getDoc(userRef);

        if (!userDoc.exists()) {
          const tokenDoc = await getDoc(doc(db, 'tokens', 'counter'));
          let tokenNumber = 0;
          if (tokenDoc.exists()) {
            tokenNumber = tokenDoc.data().count;
          }

          await setDoc(doc(db, 'tokens', 'counter'), { count: increment(1) }, { merge: true });

          await setDoc(userRef, { 
            ...userData, 
            tokenNumber, 
            timestamp: Timestamp.now()  // Log the current timestamp
          }, { merge: true });

          await setDoc(doc(db, 'users', currentUser.uid), { ...userData, username: userData.name }, { merge: true }); // Save to user profile too

          setUserData({ ...userData, tokenNumber });
          setMessage(
            `Thanks for registering, ${userData.name}! Your token is ${tokenNumber}. We will get back to you once the access starts.`
          );
          setRegistered(true);
        }
      } catch (error) {
        setMessage('Failed to save your data.');
        console.error('Error saving data:', error);
      }
    } else {
      navigate(`/register?redirect=${encodeURIComponent('http://www.internify.online/waitlist')}`);
    }
  };

  const handleWhatsAppShare = () => {
    const shareText = `Hey! I just registered for early access to the Resume Builder - ATS Score beta on Internify. It's free! You should join too and get early access! Register here: https://www.internify.online/waitlist`;
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(shareText)}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <section className="min-h-screen bg-black text-white flex flex-col items-center justify-start px-4 pt-8 pb-8">
      <div className="text-center w-full max-w-md">
        {/* Ad Container (Common for both desktop and mobile) */}
        <div className="flex justify-center mb-4">
          <div id="ad-container"></div>
        </div>

        <p className="bg-[#EBFF00] text-black inline-block px-4 py-2 rounded-full mb-6">Coming Soon</p>
        <h1 className="text-4xl font-bold mb-4">Get early access</h1>
        {!registered && (
          <p className="mb-8">
            Register now to be amongst the first to experience Resume Builder - ATS Score for Free and gain early access!
          </p>
        )}

        {!currentUser ? (
          <button
            onClick={() => navigate(`/register?redirect=${encodeURIComponent('http://www.internify.online/waitlist')}`)}
            className="w-full h-12 bg-[#EBFF00] text-black rounded-lg font-semibold"
          >
            Signup to register
          </button>
        ) : registered ? (
          <p className="text-lg font-semibold" style={{ color: '#EBFF00' }}>{message}</p>
        ) : (
          dataLoaded && (
            <>
              <div className="mb-4 relative">
                <FiUser className="absolute top-1/2 transform -translate-y-1/2 left-3 text-gray-500" />
                <input
                  type="text"
                  name="name"
                  value={userData.name}
                  onChange={handleInputChange}
                  className="w-full h-12 pl-10 pr-4 rounded-lg text-white bg-gray-900 border-none placeholder-gray-500"
                  placeholder="Full name..."
                  disabled={isEditing ? false : (dataLoaded && userData.name !== '')}
                />
              </div>
              <div className="mb-4 relative">
                <FiMail className="absolute top-1/2 transform -translate-y-1/2 left-3 text-gray-500" />
                <input
                  type="email"
                  name="email"
                  value={userData.email}
                  className="w-full h-12 pl-10 pr-4 rounded-lg text-white bg-gray-900 border-none placeholder-gray-500"
                  placeholder="Email address..."
                  disabled={true} // Email is uneditable
                />
              </div>
              <div className="mb-4 relative">
                <FiPhone className="absolute top-1/2 transform -translate-y-1/2 left-3 text-gray-500" />
                <input
                  type="tel"
                  name="phone"
                  value={userData.phone}
                  onChange={handleInputChange}
                  className="w-full h-12 pl-10 pr-4 rounded-lg text-white bg-gray-900 border-none placeholder-gray-500"
                  placeholder="Phone number... (WhatsApp)"
                  disabled={isEditing ? false : (dataLoaded && userData.phone !== '')}
                />
              </div>
              <div className="mb-4 relative">
                <FiMapPin className="absolute top-1/2 transform -translate-y-1/2 left-3 text-gray-500" />
                <input
                  type="text"
                  name="location"
                  value={userData.location}
                  onChange={handleInputChange}
                  className="w-full h-12 pl-10 pr-4 rounded-lg text-white bg-gray-900 border-none placeholder-gray-500"
                  placeholder="Location (City)"
                  disabled={isEditing ? false : (dataLoaded && userData.location !== '')}
                />
              </div>

              <button
                onClick={handleSave}
                className="w-full h-12 bg-[#EBFF00] text-black rounded-lg font-semibold"
                style={{ backgroundColor: '#EBFF00', color: 'black' }}
              >
                Join Waitlist
              </button>
            </>
          )
        )}

        {registered ? null : (
          <div className="flex justify-center items-center mt-8">
            <p className="mr-4">Join 10,000+ others on the waitlist</p>
            <div className="flex">
              {/* Add user profile avatars here */}
            </div>
          </div>
        )}

        <div className="mt-4 text-center">
          <p className="text-gray-400 text-sm">LEFT UNTIL FULL RELEASE</p>
          <div className="flex justify-center mt-2">
            <div className="flex flex-col items-center mx-2">
              <p className="text-2xl font-bold">{timeLeft.days}</p>
              <p className="text-sm text-gray-400">Days</p>
            </div>
            <div className="flex flex-col items-center mx-2">
              <p className="text-2xl font-bold">{timeLeft.hours}</p>
              <p className="text-sm text-gray-400">Hours</p>
            </div>
            <div className="flex flex-col items-center mx-2">
              <p className="text-2xl font-bold">{timeLeft.minutes}</p>
              <p className="text-sm text-gray-400">Minutes</p>
            </div>
            <div className="flex flex-col items-center mx-2">
              <p className="text-2xl font-bold">{timeLeft.seconds}</p>
              <p className="text-sm text-gray-400">Seconds</p>
            </div>
          </div>
        </div>

        <div className="mt-8">
          <p>Follow us on social media to get updates:</p>
          <div className="flex justify-center mt-4 space-x-4">
            <a href="https://www.instagram.com/internify_online" target="_blank" rel="noopener noreferrer">
              <FaInstagram className="text-white text-2xl" />
            </a>
            <a href="https://www.linkedin.com/company/internify-online/" target="_blank" rel="noopener noreferrer">
              <FaLinkedin className="text-white text-2xl" />
            </a>
            <a href="https://beacons.ai/internify" target="_blank" rel="noopener noreferrer">
              <FaWhatsapp className="text-white text-2xl" />
            </a>
          </div>
        </div>

        {registered && (
          <div className="mt-8">
            <button
              onClick={handleWhatsAppShare}
              className="w-full h-12 bg-[#25D366] text-white rounded-lg font-semibold flex items-center justify-center"
            >
              <FaWhatsapp className="mr-2" /> Share this on WhatsApp (It's free!)
            </button>
            <div className="flex justify-center mb-4">
              <div id="ad-container"></div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Waitlist;
