import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { db } from "../firebase/firebaseConfig";
import { doc, getDoc, setDoc, collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { useAuth } from '../context/AuthContext';
import { Helmet } from 'react-helmet';

const RedirectJob = () => {
  const { jobId } = useParams();
  const [job, setJob] = useState(null);
  const [countdown, setCountdown] = useState(6);
  const [buttonVisible, setButtonVisible] = useState(false);
  const [reportPopup, setReportPopup] = useState(false);
  const [reportReason, setReportReason] = useState("");
  const [alreadyReported, setAlreadyReported] = useState(false);
  const [reportReasonType, setReportReasonType] = useState("jobExpired");
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const isWebView = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return (/(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(userAgent) ||
            userAgent.toLowerCase().indexOf('wv') > -1 ||
            window.ReactNativeWebView ||
            navigator.standalone ||
            window.matchMedia('(display-mode: standalone)').matches);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchJob = async () => {
      try {
        const jobDoc = await getDoc(doc(db, "jobs", jobId));
        if (jobDoc.exists()) {
          setJob(jobDoc.data());
        } else {
          console.error("No such document!");
          navigate('/');
        }
      } catch (error) {
        console.error("Error fetching job:", error);
        navigate('/');
      }
    };

    const checkIfReported = async () => {
      if (currentUser) {
        const q = query(
          collection(db, "reportedJobs"),
          where("userId", "==", currentUser.uid),
          where("jobId", "==", jobId)
        );
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          setAlreadyReported(true);
        }
      }
    };

    fetchJob();
    checkIfReported();
  }, [jobId, navigate, currentUser]);

  useEffect(() => {
    if (countdown === 0) {
      setButtonVisible(true);
      logApplication();
    } else {
      const timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [countdown]);

  useEffect(() => {
    if (!isWebView()) {
      return () => {};
    }
  }, []);

  useEffect(() => {
    const loadAd = () => {
      const adContainer = document.getElementById('ad-container');
  
      if (adContainer) {
        adContainer.style.width = "100%"; // Ensure the container has a width
        adContainer.style.minWidth = "300px"; // Add a minimum width to prevent 0-width error
        adContainer.style.textAlign = "center"; // Center the ad (optional)
  
        const adScript = document.createElement('script');
        adScript.async = true;
        adScript.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4408090261163070";
        adScript.crossOrigin = "anonymous";
  
        const adIns = document.createElement('ins');
        adIns.className = "adsbygoogle";
        adIns.style.display = "block";
        adIns.setAttribute("data-ad-format", "autorelaxed");
        adIns.setAttribute("data-ad-client", "ca-pub-4408090261163070");
        adIns.setAttribute("data-ad-slot", "3758244855");
  
        const adPushScript = document.createElement('script');
        adPushScript.innerHTML = "(adsbygoogle = window.adsbygoogle || []).push({});";
  
        adContainer.appendChild(adScript);
        adContainer.appendChild(adIns);
        adContainer.appendChild(adPushScript);
      } else {
        console.error('Ad container not found');
      }
    };
  
    const timer = setTimeout(loadAd, 2000);
  
    return () => {
      const adContainer = document.getElementById('ad-container');
      if (adContainer) {
        while (adContainer.firstChild) {
          adContainer.removeChild(adContainer.firstChild);
        }
      }
      clearTimeout(timer);
    };
  }, [jobId]);
  
  

  const logApplication = async () => {
    if (currentUser && job) {
      try {
        const appliedJobRef = doc(collection(db, "appliedJobs"));
        await setDoc(appliedJobRef, {
          userId: currentUser.uid,
          jobId: jobId,
          title: job.title,
          status: "Applied",
          appliedAt: new Date(),
        });
      } catch (error) {
        console.error("Error logging application:", error);
      }
    }
  };

  const handleManualRedirect = () => {
    if (job) {
      window.location.href = job.link;
    }
  };

  const handleReport = async () => {
    if (!currentUser) {
      setReportPopup(false);
      navigate(`/register?redirect=${window.location.href}`);
      return;
    }

    if (alreadyReported) {
      alert("You have already reported this job.");
      return;
    }

    const reason = reportReasonType === "others" ? reportReason : reportReasonType;

    if (reason) {
      try {
        await addDoc(collection(db, "reportedJobs"), {
          jobId,
          userId: currentUser.uid,
          reason: reason,
          reportedAt: new Date(),
        });
        setReportPopup(false);
        alert("Job reported successfully.");
      } catch (error) {
        console.error("Error reporting job:", error);
        alert("Failed to report the job.");
      }
    } else {
      alert("Please provide a reason for reporting the job.");
    }
  };

  return (
    <>
      <Helmet>
        <title>Redirecting to job application...</title>
      </Helmet>
      <div className="flex flex-col items-center justify-start h-screen px-4 sm:px-6 lg:px-8 pt-4">
        <h1 className="text-2xl font-bold mb-4">Apply now using the below link</h1>
        {buttonVisible ? (
          <button
            onClick={handleManualRedirect}
            className="px-4 py-2 rounded bg-blue-500 text-white"
          >
            Apply Now
          </button>
        ) : (
          <p className="mb-4">Apply link visible in {countdown} seconds</p>
        )}

        <div className="flex space-x-4 mt-4">
          <button
            onClick={() => setReportPopup(true)}
            className="px-4 py-2 rounded bg-red-500 text-white"
          >
            Report Job
          </button>
        </div>

        <p className="mt-2 text-sm text-gray-500">
          Please press the back button after clicking on apply now button if you are redirected towards an advertisement page.
        </p>

        {/* Google AdSense Ad */}
        <div id="ad-container" className="mt-4"></div>
      </div>

      {reportPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg w-80">
            <h3 className="text-xl font-semibold mb-4">Report Job</h3>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">
                Select Reason:
              </label>
              <select
                className="w-full p-2 border rounded-lg"
                value={reportReasonType}
                onChange={(e) => setReportReasonType(e.target.value)}
              >
                <option value="jobExpired">Job Expired</option>
                <option value="fakeJob">Fake Job</option>
                <option value="jobDoesn'tExist">Job Doesn't Exist</option>
                <option value="linkNotWorking">Link Not Working</option>
                <option value="others">Others</option>
              </select>
            </div>
            {reportReasonType === "others" && (
              <textarea
                className="w-full p-2 border rounded-lg mb-4"
                rows="4"
                placeholder="Reason for reporting this job"
                value={reportReason}
                onChange={(e) => setReportReason(e.target.value)}
              />
            )}
            <div className="flex justify-end space-x-2">
              <button className="px-4 py-2 bg-gray-500 text-white rounded-lg" onClick={() => setReportPopup(false)}>
                Cancel
              </button>
              <button className="px-4 py-2 bg-red-500 text-white rounded-lg" onClick={handleReport}>
                Report
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RedirectJob;
