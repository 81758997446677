import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { db } from "../firebase/firebaseConfig";
import { doc, getDoc, collection, getDocs, query, where, orderBy, limit, addDoc } from "firebase/firestore";
import { useAuth } from "../context/AuthContext";
import { FaWhatsapp } from "react-icons/fa";
import { MdContentCopy, MdOutlineReportProblem } from "react-icons/md";
import { HiOutlineUserGroup } from "react-icons/hi";
import { AiOutlineClockCircle } from "react-icons/ai";
import { FiShare } from "react-icons/fi";
import ExternalJobNote from './ExternalJobNote';
import AdvertisementBanner from './AdvertisementBanner';

const JobDetails = () => {
  const { jobId } = useParams();
  const [job, setJob] = useState(null);
  const [recentJobs, setRecentJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [alreadyApplied, setAlreadyApplied] = useState(false);
  const { currentUser } = useAuth();
  const [isExpired, setIsExpired] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [applyCount, setApplyCount] = useState(0);
  const [daysLeft, setDaysLeft] = useState(null);
  const [reportPopup, setReportPopup] = useState(false);
  const [reportReason, setReportReason] = useState("");
  const [alreadyReported, setAlreadyReported] = useState(false);
  const [reportReasonType, setReportReasonType] = useState("jobExpired");
  const [showSharePopup, setShowSharePopup] = useState(false);
  const navigate = useNavigate();

  // Function to parse the date string into a Date object
  const parseDateString = (dateInput) => {
    if (dateInput instanceof Date) {
      return dateInput.toISOString();
    }
    if (typeof dateInput === 'object' && 'seconds' in dateInput && 'nanoseconds' in dateInput) {
      const dateObj = new Date(dateInput.seconds * 1000 + dateInput.nanoseconds / 1000000);
      return dateObj.toISOString();
    }
    if (typeof dateInput === 'string') {
      const dateObj = new Date(dateInput);
      return isNaN(dateObj) ? null : dateObj.toISOString(); // Handle invalid dates
    }
    console.error("Unrecognized date format:", dateInput);
    return null;
  };

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const jobDoc = await getDoc(doc(db, "jobs", jobId));
        if (jobDoc.exists()) {
          const jobData = jobDoc.data();
          setJob({
            ...jobData,
            datePosted: parseDateString(jobData.date),
            expiryDate: jobData.expiryDate ? parseDateString(jobData.expiryDate) : null,
          });

          const currentDate = new Date();
          const expiryDate = jobData.expiryDate ? new Date(parseDateString(jobData.expiryDate)) : null;
          if (jobData.jobStatus === 'Inactive' || (expiryDate && expiryDate < currentDate)) {
            setIsExpired(true);
            setIsActive(false);
            setDaysLeft("Expired");
          } else {
            setIsExpired(false);
            setIsActive(true);
            if (expiryDate) {
              const timeDiff = expiryDate - currentDate;
              const daysRemaining = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
              setDaysLeft(daysRemaining > 0 ? daysRemaining : "Expired");
            }
          }
        } else {
          console.error("No such document!");
          setError("Job not found.");
        }
      } catch (error) {
        console.error("Error fetching job:", error);
        setError("Error fetching job details.");
      } finally {
        setLoading(false);
      }
    };

    const fetchRecentJobs = async () => {
      try {
        const q = query(
          collection(db, "jobs"),
          orderBy("date", "desc"),
          limit(4)
        );
        const jobSnapshot = await getDocs(q);
        const jobsData = jobSnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setRecentJobs(jobsData);
      } catch (error) {
        console.error("Error fetching recent jobs:", error);
      }
    };

    const fetchApplyCount = async () => {
      try {
        const q = query(
          collection(db, "appliedJobs"),
          where("jobId", "==", jobId)
        );
        const applySnapshot = await getDocs(q);
        setApplyCount(applySnapshot.size);
      } catch (error) {
        console.error("Error fetching apply count:", error);
      }
    };

    const checkIfApplied = async () => {
      if (currentUser) {
        const q = query(
          collection(db, "appliedJobs"),
          where("userId", "==", currentUser.uid),
          where("jobId", "==", jobId)
        );
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          setAlreadyApplied(true);
        }
      }
    };

    const checkIfReported = async () => {
      if (currentUser) {
        const q = query(
          collection(db, "reportedJobs"),
          where("userId", "==", currentUser.uid),
          where("jobId", "==", jobId)
        );
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          setAlreadyReported(true);
        }
      }
    };

    fetchJob();
    fetchRecentJobs();
    fetchApplyCount();
    checkIfApplied();
    checkIfReported();
  }, [jobId, currentUser]);

  useEffect(() => {
    const loadAd = () => {
      const adContainer = document.getElementById('ad-container');
  
      if (adContainer) {
        const adScript = document.createElement('script');
        adScript.async = true;
        adScript.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4408090261163070";
        adScript.crossOrigin = "anonymous";
  
        const adIns = document.createElement('ins');
        adIns.className = "adsbygoogle";
        adIns.style.display = "block";
        adIns.style.textAlign = "center";
        adIns.setAttribute("data-ad-layout", "in-article");
        adIns.setAttribute("data-ad-format", "fluid");
        adIns.setAttribute("data-ad-client", "ca-pub-4408090261163070");
        adIns.setAttribute("data-ad-slot", "2348154364");
  
        const adPushScript = document.createElement('script');
        adPushScript.innerHTML = "(adsbygoogle = window.adsbygoogle || []).push({});";
  
        adContainer.appendChild(adScript);
        adContainer.appendChild(adIns);
        adContainer.appendChild(adPushScript);
      } else {
        console.error('Ad container not found');
      }
    };
  
    const timer = setTimeout(loadAd, 2000);
  
    return () => {
      const adContainer = document.getElementById('ad-container');
      if (adContainer) {
        while (adContainer.firstChild) {
          adContainer.removeChild(adContainer.firstChild);
        }
      }
      clearTimeout(timer);
    };
  }, [jobId]);
  
  
  
  

  const handleApply = async () => {
    if (job) {
      if (job.externalJob) {
        if (currentUser) {
          navigate(`/redirect/${jobId}`);
        } else {
          window.open(job.link, '_blank');
        }
      } else if (currentUser) {
        navigate(`/redirect/${jobId}`);
      }
    }
  };

  const handleCopyLink = () => {
    const jobUrl = window.location.href;
    const shareText = `${job.title} | ${job.location}\n\nCompany: ${job.company}\n${job.salary ? `Salary: ${job.salary}\n\n` : ''}Apply at ${jobUrl}`;
    navigator.clipboard.writeText(shareText)
      .then(() => {
        alert("Job link copied to clipboard!");
      })
      .catch((error) => {
        console.error("Error copying link:", error);
        alert("Failed to copy the link.");
      });
  };

  const handleShareWhatsApp = () => {
    const jobUrl = window.location.href;
    const shareText = `${job.title} | ${job.location}\n\nCompany: ${job.company}\n${job.salary ? `Salary: ${job.salary}\n\n` : ''}Apply at ${jobUrl}`;
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(shareText)}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleReport = async () => {
    if (!currentUser) {
      setReportPopup(false);
      navigate(`/register?redirect=${window.location.href}`);
      return;
    }

    if (alreadyReported) {
      alert("You have already reported this job.");
      return;
    }

    const reason = reportReasonType === "others" ? reportReason : reportReasonType;

    if (reason) {
      try {
        await addDoc(collection(db, "reportedJobs"), {
          jobId,
          userId: currentUser.uid,
          reason: reason,
          reportedAt: new Date(),
        });
        setReportPopup(false);
        alert("Job reported successfully.");
      } catch (error) {
        console.error("Error reporting job:", error);
        alert("Failed to report the job.");
      }
    } else {
      alert("Please provide a reason for reporting the job.");
    }
  };

  const handleShareClick = () => {
    setShowSharePopup(true);
  };

  const closeSharePopup = () => {
    setShowSharePopup(false);
  };

  useEffect(() => {
    // Load Google Ads after 2 seconds
    const loadAds = () => {
      if (window.adsbygoogle) {
        window.adsbygoogle.push({});
      }
    };

    const timer = setTimeout(loadAds, 2000); // Delay of 2 seconds

    return () => clearTimeout(timer); // Cleanup timer
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const structuredData = {
    "@context": "http://schema.org",
    "@type": "JobPosting",
    "title": job.title,
    "description": job.description,
    "datePosted": job.datePosted,
    "validThrough": job.expiryDate,
    "employmentType": job.type,
    "hiringOrganization": {
      "@type": "Organization",
      "name": job.company,
      "sameAs": job.companyWebsite,
      "logo": job.companyLogo
    },
    "jobLocation": {
      "@type": "Place",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": job.streetAddress,
        "addressLocality": job.location,
        "postalCode": job.postalCode,
        "addressCountry": job.addressCountry
      }
    },
    "baseSalary": {
      "@type": "MonetaryAmount",
      "currency": "INR",
      "value": {
        "@type": "QuantitativeValue",
        "value": job.salary,
        "unitText": "YEAR"
      }
    },
    "identifier": {
      "@type": "PropertyValue",
      "name": "Internify",
      "value": jobId
    },
    "applicantLocationRequirements": {
      "@type": "Country",
      "name": job.location
    },
    "jobLocationType": "TELECOMMUTE",
    "industry": job.industry,
    "qualifications": job.qualifications
  };

  return (
    <div className="flex flex-col mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8 mt-4 pb-16">
      <Helmet>
        <title>{`${job.title} at ${job.company} - Internify`}</title>
        <meta name="description" content={`Apply for ${job.title} at Internify job portal.`} />
        <meta property="og:title" content={`${job.title} at ${job.company} - Internify`} />
        <meta property="og:description" content={job.location} />
        <meta property="og:image" content="/preview.png" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <link rel="icon" href="/favicon.png" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <AdvertisementBanner />
      <div id="ad-container" className="mt-4"></div>

      {isExpired && (
        <div className="bg-yellow-200 text-yellow-800 p-4 rounded-lg shadow-md mb-4">
          This job is expired or no longer active.
        </div>
      )}
      <div className="flex flex-col lg:flex-row">
        <div className="lg:w-2/3 p-4 bg-white shadow-lg rounded-lg mb-4 lg:mb-0">
          <div className="mb-4">
            <h1 className="text-3xl font-bold mb-4">{job.title}</h1>
            <div className="flex flex-col space-y-4">
              <div className="flex items-center">
                <HiOutlineUserGroup className="text-gray-500 w-6 h-6 mr-2" />
                <div>
                  <div className="text-sm font-semibold">Applied</div>
                  <div className="text-lg font-bold">{applyCount}</div>
                </div>
              </div>
              {daysLeft !== null && (
                <div className="flex items-center">
                  <AiOutlineClockCircle className="text-gray-500 w-6 h-6 mr-2" />
                  <div>
                    <div className="text-sm font-semibold">Application Deadline</div>
                    <div className="text-lg font-bold">{daysLeft === "Expired" ? "Expired" : `${daysLeft} days left`}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex space-x-4 mb-4">
            <button
              onClick={handleShareClick}
              className="px-4 py-2 rounded flex items-center bg-gray-200 text-gray-800"
            >
              <FiShare className="w-5 h-5 mr-2 lg:mr-0" />
              <span className="hidden lg:inline">Share</span>
            </button>
            <button
              onClick={() => setReportPopup(true)}
              className="px-4 py-2 rounded bg-red-500 text-white flex items-center"
            >
              <MdOutlineReportProblem className="w-5 h-5 mr-2 lg:mr-0" />
              <span className="hidden lg:inline">Report Job</span>
            </button>
            <div className="hidden lg:block">
  {job.externalJob ? (
    <button
      onClick={handleApply}
      className={`button ${!isActive ? 'disabled-button' : ''}`}
      disabled={!isActive}
    >
      {isExpired ? "Job Expired" : "Apply Now"}
      <svg fill="currentColor" viewBox="0 0 24 24" className="icon">
        <path clipRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z" fillRule="evenodd"></path>
      </svg>
    </button>
  ) : currentUser ? (
    <button
      onClick={handleApply}
      className={`button ${!isActive ? 'disabled-button' : ''}`}
      disabled={!isActive}
    >
      {isExpired ? "Job Expired" : alreadyApplied ? "ReApply Job" : "Apply Now"}
      <svg fill="currentColor" viewBox="0 0 24 24" className="icon">
        <path clipRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z" fillRule="evenodd"></path>
      </svg>
    </button>
  ) : (
    <Link to={`/register?redirect=${window.location.href}`} className="button">
      Login/Register to Apply
      <svg fill="currentColor" viewBox="0 0 24 24" className="icon">
        <path clipRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z" fillRule="evenodd"></path>
      </svg>
    </Link>
  )}
</div>

          </div>
          <div className="p-4 bg-blue-100 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-2">{job.company}</h2>
            <div className="flex items-center text-gray-700 mb-2">
              <span className="mr-2">📍</span>
              <span>{job.location}</span>
            </div>
            <div className="flex items-center text-gray-700 mb-2">
              <span className="mr-2">💼</span>
              <span>{job.type}</span>
            </div>
            {job.salary && (
              <div className="flex items-center text-gray-700 mb-2">
                <span className="mr-2">💵</span>
                <span>Salary: ₹{job.salary}</span>
              </div>
            )}
          </div>
          <div className="p-4 bg-white rounded-lg shadow-md mt-4">
  <h3 className="text-xl font-semibold mb-2">Minimum Qualification</h3>

  {/* Google AdSense Ad before the description */}
  <div>
    <ins className="adsbygoogle"
         style={{ display: 'block' }}
         data-ad-client="ca-pub-4408090261163070"
         data-ad-slot="2223508191"
         data-ad-format="fluid"
         data-ad-layout-key="-gw-3+1f-3d+2z"></ins>
    <script>
      {`(adsbygoogle = window.adsbygoogle || []).push({});`}
    </script>
  </div>

  {/* Job description */}
  <div className="text-gray-700" dangerouslySetInnerHTML={{ __html: job.description }} />
</div>



          <ExternalJobNote />
        </div>
        <div className="lg:w-1/3 p-4">
          <h2 className="text-2xl font-bold mb-4">Recent Jobs</h2>
          <div className="space-y-4">
          {recentJobs
  .filter((recentJob) => recentJob.id !== jobId)
  .slice(0, 3)
  .map((recentJob, index) => (
    <React.Fragment key={recentJob.id}>
      <div className="bg-white shadow-lg rounded-lg p-4">
        <h3 className="text-lg font-bold">{recentJob.title}</h3>
        <p className="text-gray-600">{recentJob.company}</p>
        <p className="text-gray-600">{recentJob.location}</p>
        <Link to={`/job/${recentJob.id}`} className="text-blue-500 underline" target="_blank">
          View Details
        </Link>
      </div>

      {/* Google AdSense Ad */}
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4408090261163070" crossOrigin="anonymous"></script>
      <ins className="adsbygoogle"
          style={{ display: 'block' }}
          data-ad-client="ca-pub-4408090261163070"
          data-ad-slot="2640604301"
          data-ad-format="auto"
          data-full-width-responsive="true"></ins>
      <script>
          (adsbygoogle = window.adsbygoogle || []).push({});
      </script>
    </React.Fragment>
  ))}

          </div>
        </div>
      </div>
      {showSharePopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg w-80">
            <h3 className="text-xl font-semibold mb-4">{job.title}</h3>
            <div className="flex justify-center mb-4">
              <button onClick={handleCopyLink} className="px-4 py-2 rounded-full bg-gray-200 text-gray-800 mx-2">
                <MdContentCopy className="w-5 h-5" />
              </button>
              <button onClick={handleShareWhatsApp} className="px-4 py-2 rounded-full bg-green-500 text-white mx-2">
                <FaWhatsapp className="w-5 h-5" />
              </button>
            </div>
            <button className="px-4 py-2 bg-gray-500 text-white rounded-lg" onClick={closeSharePopup}>
              Close
            </button>
          </div>
        </div>
      )}
      {reportPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg w-80">
            <h3 className="text-xl font-semibold mb-4">Report Job</h3>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">
                Select Reason:
              </label>
              <select
                className="w-full p-2 border rounded-lg"
                value={reportReasonType}
                onChange={(e) => setReportReasonType(e.target.value)}
              >
                <option value="jobExpired">Job Expired</option>
                <option value="fakeJob">Fake Job</option>
                <option value="jobDoesn'tExist">Job Doesn't Exist</option>
                <option value="linkNotWorking">Link Not Working</option>
                <option value="others">Others</option>
              </select>
            </div>
            {reportReasonType === "others" && (
              <textarea
                className="w-full p-2 border rounded-lg mb-4"
                rows="4"
                placeholder="Reason for reporting this job"
                value={reportReason}
                onChange={(e) => setReportReason(e.target.value)}
              />
            )}
            <div className="flex justify-end space-x-2">
              <button className="px-4 py-2 bg-gray-500 text-white rounded-lg" onClick={() => setReportPopup(false)}>
                Cancel
              </button>
              <button className="px-4 py-2 bg-red-500 text-white rounded-lg" onClick={handleReport}>
                Report
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Apply Now button for mobile view */}
<div className="fixed bottom-0 left-0 w-full bg-white shadow-lg py-4 flex items-center justify-center lg:hidden z-50">
  {job.externalJob ? (
    <button
      onClick={handleApply}
      className={`button ${!isActive ? 'disabled-button' : ''}`}
      disabled={!isActive}
    >
      {isExpired ? "Job Expired" : "Apply Now"}
      <svg fill="currentColor" viewBox="0 0 24 24" className="icon">
        <path clipRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z" fillRule="evenodd"></path>
      </svg>
    </button>
  ) : currentUser ? (
    <button
      onClick={handleApply}
      className={`button ${!isActive ? 'disabled-button' : ''}`}
      disabled={!isActive}
    >
      {isExpired ? "Job Expired" : alreadyApplied ? "ReApply Job" : "Apply Now"}
      <svg fill="currentColor" viewBox="0 0 24 24" className="icon">
        <path clipRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z" fillRule="evenodd"></path>
      </svg>
    </button>
  ) : (
    <Link to={`/register?redirect=${window.location.href}`} className="button">
      Login/Register to Apply
      <svg fill="currentColor" viewBox="0 0 24 24" className="icon">
        <path clipRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z" fillRule="evenodd"></path>
      </svg>
    </Link>
  )}
</div>

      <style>{`
/* Base styles for the button */
.button {
  position: relative;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  padding-block: 0.5rem;
  padding-inline: 1.25rem;
  background-color: rgb(0 107 179);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
  gap: 10px;
  font-weight: bold;
  outline: none;
  overflow: hidden;
  font-size: 15px;
  animation: pulse 1.5s ease-out infinite;
  width: 100%; /* Full width by default */
  max-width: 100%;
}

/* Disabled button styles */
.disabled-button {
  background-color: #9ca3af; /* Grey color for disabled button */
  cursor: not-allowed;
  animation: none; /* Remove pulse animation for disabled button */
}

/* Media query for mobile */
@media (max-width: 768px) {
  .button {
    width: 80%; /* Set width to 80% on mobile devices */
    margin: 0 auto; /* Center the button */
  }
}

.icon {
  width: 24px;
  height: 24px;
  transition: all 0.3s ease-in-out;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    border-color: #ffffff4d;
  }
  50% {
    transform: scale(1.05);
    border-color: #fff9;
  }
  100% {
    transform: scale(1);
    border-color: #ffffff4d;
  }
}

.button::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  opacity: 0.6;
  animation: shine 1.5s ease-out infinite;
}

@keyframes shine {
  0% {
    left: -100px;
  }
  60% {
    left: 100%;
  }
  to {
    left: 100%;
  }
}
      `}</style>
    </div>
  );
};

export default JobDetails;
