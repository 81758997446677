import React, { useState, useEffect } from 'react';
import jsPDF from 'jspdf';

const steps = [
  'Company Details',
  'Contact Information',
  'Permissions & Agreement',
  'Confirmation',
];

const LoiForm = () => {
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    companyName: '',
    companyWebsite: '',
    companyAddress: '',
    contactPerson: '',
    contactEmail: '',
    contactTitle: '',
    placeOfSigning: '',
    date: new Date().toLocaleDateString(),
  });

  useEffect(() => {
    const savedData = localStorage.getItem('formData');
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleNext = () => {
    localStorage.setItem('formData', JSON.stringify(formData));
    setStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted', formData);
  };

  const wrapText = (doc, text, x, y, maxWidth, lineHeight) => {
    const words = text.split(' ');
    let line = '';
    words.forEach((word) => {
      const testLine = line + word + ' ';
      const testWidth = doc.getTextWidth(testLine);
      if (testWidth > maxWidth && line !== '') {
        doc.text(line, x, y);
        line = word + ' ';
        y += lineHeight;
      } else {
        line = testLine;
      }
    });
    doc.text(line, x, y);
    return y + lineHeight;
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    const currentDateTime = new Date().toLocaleString();

    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text('Letter of Intent (LOI)', 20, 20);

    doc.setFont("helvetica", "normal");
    const yOffset = wrapText(doc, `This LOI is made and entered into on this ${formData.date} ("Effective Date") by and between:`, 20, 30, 170, 10);

    doc.setFont("helvetica", "bold");
    doc.text(`${formData.companyName}`, 20, yOffset + 10);
    doc.setFont("helvetica", "normal");
    const yOffset2 = wrapText(doc, `${formData.companyAddress}`, 20, yOffset + 20, 170, 10);
    wrapText(doc, `${formData.companyWebsite}`, 20, yOffset2 + 10, 170, 10);
    doc.text('(Hereinafter referred to as "Partner")', 20, yOffset2 + 20);

    doc.setFont("helvetica", "bold");
    doc.text('RECITALS', 20, yOffset2 + 40);

    doc.setFont("helvetica", "normal");
    const recitals = [
      'WHEREAS, Internify operates a job portal at www.internify.online (the "Platform") that connects job seekers with employment opportunities;',
      'WHEREAS, the Partner seeks to post job openings on the Platform and collaborate with Internify to attract potential candidates;',
      'WHEREAS, both parties desire to enter into this LOI to outline the terms and conditions of their partnership.',
      'NOW, THEREFORE, in consideration of the mutual promises and covenants contained herein, the parties agree as follows:'
    ];

    let currentYOffset = yOffset2 + 50;
    recitals.forEach((text) => {
      currentYOffset = wrapText(doc, text, 20, currentYOffset, 170, 10);
    });

    const sections = [
      { title: '1. Purpose of the LOI', text: 'The purpose of this LOI is to establish a partnership between Internify and the Partner for the purpose of posting job openings on the Platform and facilitating the recruitment process.' },
      { title: '2. Term of the LOI', text: 'This LOI shall commence on the Effective Date and continue until terminated by either party with 30 days\' written notice.' },
      {
        title: '3. Roles and Responsibilities', text: [
          'Internify\'s Responsibilities:',
          '- Provide the Partner with the ability to post job openings on the Platform at no cost.',
          '- Promote the Partner\'s job openings to potential candidates through the Platform.',
          '- Direct all candidates to apply through the link provided by the Partner.',
          '- Feature the Partner’s logo and name on the Platform’s “Our Partners” section.',
          'Partner\'s Responsibilities:',
          '- Provide Internify with job posting links, company logo, and a brief description to be displayed on the Platform.',
          '- Grant Internify permission to use the Partner’s name, logo, and description for promotional purposes on the Platform and in marketing materials.',
          '- Acknowledge that all candidate applications will be managed by the Partner directly.'
        ]
      },
      { title: '4. Confidentiality', text: 'Both parties agree to maintain the confidentiality of any proprietary or confidential information shared under this LOI, except where disclosure is required by law or agreed upon by both parties.' },
      { title: '5. Termination', text: 'Either party may terminate this LOI with 30 days\' written notice to the other party. Upon termination, Internify will remove the Partner\'s information from the Platform.' },
      { title: '6. Intellectual Property', text: 'Each party retains ownership of its respective intellectual property, including logos, trademarks, and copyrighted materials. This LOI does not transfer any intellectual property rights from one party to the other.' },
      { title: '7. Limitation of Liability', text: 'Neither party shall be liable for any indirect, incidental, or consequential damages arising out of or related to this LOI. Internify\'s liability, if any, shall be limited to the amount of fees paid by the Partner, which in this case is zero.' },
      { title: '8. Governing Law', text: 'This LOI shall be governed by and construed in accordance with the laws of Karnataka, India.' },
      { title: '9. Amendments', text: 'Any amendments or modifications to this LOI must be made in writing and signed by both parties.' },
      { title: '10. Entire LOI', text: 'This LOI constitutes the entire understanding between the parties concerning the subject matter hereof and supersedes all prior agreements, understandings, and representations.' },
      { title: 'IN WITNESS WHEREOF, the parties hereto have executed this LOI as of the Effective Date.', text: '' }
    ];

    sections.forEach((section) => {
      if (currentYOffset >= 270) { // New page if near the bottom
        doc.addPage();
        currentYOffset = 20;
      }
      doc.setFont("helvetica", "bold");
      currentYOffset = wrapText(doc, section.title, 20, currentYOffset, 170, 10);

      if (Array.isArray(section.text)) {
        doc.setFont("helvetica", "normal");
        section.text.forEach((line) => {
          if (line.startsWith('-')) {
            currentYOffset = wrapText(doc, line, 30, currentYOffset, 150, 10); // Indent for bullet points
          } else {
            currentYOffset = wrapText(doc, line, 20, currentYOffset, 170, 10);
          }
          if (currentYOffset >= 270) {
            doc.addPage();
            currentYOffset = 20;
          }
        });
      } else {
        doc.setFont("helvetica", "normal");
        currentYOffset = wrapText(doc, section.text, 20, currentYOffset, 170, 10);
      }
    });

    currentYOffset += 40;
    doc.text(`Please sign above`, 20, currentYOffset);
    doc.text(`${formData.contactPerson}`, 20, currentYOffset + 10);
    doc.text(`Designation: ${formData.contactTitle}`, 20, currentYOffset + 20);
    doc.text(`Place of Signing: ${formData.placeOfSigning}`, 20, currentYOffset + 30);

    // Add digital signature section
    currentYOffset += 50;
    doc.text(`Abey Paul`, 20, currentYOffset);
    doc.text(`Onboarding Head, Internify`, 20, currentYOffset + 10);
    doc.text(`[Digitally signed - ${currentDateTime}]`, 20, currentYOffset + 20);

    return doc;
  };

  const downloadPDF = () => {
    const doc = generatePDF();
    doc.save(`${formData.companyName}_LOI.pdf`);
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">{steps[step]}</h2>

      <form onSubmit={handleSubmit}>
        {step === 0 && (
          <div>
            <div className="mb-6">
              <label className="block text-gray-700 text-lg font-semibold mb-2">Company Name</label>
              <input
                name="companyName"
                type="text"
                value={formData.companyName}
                onChange={handleChange}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 text-lg font-semibold mb-2">Company Website</label>
              <input
                name="companyWebsite"
                type="url"
                value={formData.companyWebsite}
                onChange={handleChange}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 text-lg font-semibold mb-2">Company Address</label>
              <input
                name="companyAddress"
                type="text"
                value={formData.companyAddress}
                onChange={handleChange}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>
        )}

        {step === 1 && (
          <div>
            <div className="mb-6">
              <label className="block text-gray-700 text-lg font-semibold mb-2">Contact Person</label>
              <input
                name="contactPerson"
                type="text"
                value={formData.contactPerson}
                onChange={handleChange}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 text-lg font-semibold mb-2">Official Email</label>
              <input
                name="contactEmail"
                type="email"
                value={formData.contactEmail}
                onChange={handleChange}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 text-lg font-semibold mb-2">Designation</label>
              <input
                name="contactTitle"
                type="text"
                value={formData.contactTitle}
                onChange={handleChange}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 text-lg font-semibold mb-2">Place of Signing</label>
              <input
                name="placeOfSigning"
                type="text"
                value={formData.placeOfSigning}
                onChange={handleChange}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>
        )}

        {step === 2 && (
          <div>
            <div className="mb-6">
              <label className="block text-gray-700 text-lg font-semibold mb-2">Permissions</label>
              <div className="flex items-center">
                <input
                  name="agreeToTerms"
                  type="checkbox"
                  checked={formData.agreeToTerms}
                  onChange={handleChange}
                  className="mr-3"
                />
                <span className="text-md text-gray-600">
                  I agree to display the company logo, name, and description on Internify’s website.
                </span>
              </div>
            </div>
            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-4">Agreement Terms</h3>
              <p className="text-gray-600 text-md">
                By completing and submitting this form, <strong>{formData.companyName}</strong> agrees to partner with Internify for the purpose of posting job openings on the Internify platform. The following terms apply:
              </p>
              <ul className="list-disc pl-6 text-gray-600 text-md mt-2">
                <li>All applications will be directed to the company’s website.</li>
                <li>Use of Logo and Name: The company grants Internify permission to display its logo, name, and brief description on Internify’s website and marketing materials.</li>
                <li>Free Service: This partnership is offered at no cost to the company.</li>
                <li>Termination: Either party may terminate this agreement with 30 days' notice.</li>
              </ul>
            </div>
          </div>
        )}

        {step === 3 && (
          <div>
            <div className="mb-6">
              <label className="block text-gray-700 text-lg font-semibold mb-2">Date</label>
              <input
                name="date"
                type="text"
                value={formData.date}
                onChange={handleChange}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500"
                readOnly
              />
            </div>
            <div className="mb-6">
              <button
                type="button"
                onClick={downloadPDF}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-lg focus:outline-none focus:shadow-outline w-full mb-4"
              >
                Download LOI as PDF
              </button>
            </div>
          </div>
        )}

        <div className="flex justify-between mt-8">
          {step > 0 && (
            <button
              type="button"
              onClick={handleBack}
              className="bg-gray-300 hover:bg-gray-400 text-gray-700 font-bold py-2 px-6 rounded-lg focus:outline-none focus:shadow-outline"
            >
              Back
            </button>
          )}
          {step < steps.length - 1 && (
            <button
              type="button"
              onClick={handleNext}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-lg focus:outline-none focus:shadow-outline"
            >
              Next
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default LoiForm;
