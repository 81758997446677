import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth, db } from '../firebase/firebaseConfig';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { setDoc, doc } from 'firebase/firestore';
import { useAuth } from '../context/AuthContext';
import moment from 'moment-timezone';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(true); // State to manage password visibility
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const location = useLocation();

  const baseUrl = "https://www.internify.online";
  const redirectUrl = new URLSearchParams(location.search).get('redirect') || '/jobs-search';
  const finalRedirectUrl = redirectUrl.startsWith('http') ? redirectUrl : `${baseUrl}${redirectUrl}`;

  if (currentUser) {
    window.location.href = finalRedirectUrl;
  }

  const retryFirestoreWrite = async (userData, retries = 3) => {
    for (let attempt = 0; attempt < retries; attempt++) {
      try {
        await setDoc(doc(db, 'users', userData.uid), userData);
        return;
      } catch (error) {
        console.error(`Attempt ${attempt + 1} failed:`, error);
        if (attempt === retries - 1) throw error;
      }
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      const registrationDate = moment().tz('Asia/Kolkata').toISOString();
      const userData = {
        uid: user.uid,
        email,
        registrationDate,
      };

      await retryFirestoreWrite(userData);
      console.log('User data successfully written to Firestore');

      window.location.href = finalRedirectUrl;
    } catch (error) {
      console.error('Error registering user:', error);
      if (error.code === 'auth/email-already-in-use') {
        setError('Email is already in use.');
      } else {
        setError(error.message);
      }
    }
  };

  return (
    <section className="min-h-screen flex items-center justify-center bg-white px-4">
      <div className="w-full max-w-md">
        <h1 className="text-3xl font-bold text-left mb-1">
          Hi, Welcome! <span role="img" aria-label="wave">👋</span>
        </h1>
        <p className="text-left text-gray-500 mb-6">
          Create your free account
        </p>

        {error && (
          <div role="alert" className="text-red-500 text-left mb-4">
            {error}
          </div>
        )}

        <form onSubmit={handleRegister} className="space-y-6">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email address</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-3 border rounded-lg text-gray-700 focus:ring-blue-500 focus:border-blue-500 text-base"
              required
            />
          </div>

          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">Password</label>
            <div className="relative">
              <input
                type={showPassword ? 'text' : 'password'} // Toggle between text and password type
                id="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-3 border rounded-lg text-gray-700 focus:ring-blue-500 focus:border-blue-500 text-base"
                required
              />
              {/* Toggle Button */}
              <button
                type="button"
                className="absolute right-3 top-3 text-gray-600"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? 'Hide' : 'Show'}
              </button>
            </div>
          </div>

          <div className="flex items-start">
            <input
              type="checkbox"
              id="terms"
              className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
              required
            />
            <label htmlFor="terms" className="ml-2 text-sm text-gray-700">
              By Signing up, you agree to the <a href="/terms" className="text-blue-600">Terms of Service</a> and <a href="/privacy" className="text-blue-600">Privacy Policy</a>.
            </label>
          </div>

          <button
            type="submit"
            className="w-full py-3 px-4 text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 font-medium text-base"
          >
            Sign up
          </button>
        </form>

        <p className="text-center text-sm text-gray-600 mt-6">
          Already have an account? <a href="/login" className="text-blue-600">Log in</a>
        </p>
      </div>
    </section>
  );
};

export default Register;
