import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase/firebaseConfig";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AddSEO = () => {
  const [cities, setCities] = useState("");
  const [jobTitles, setJobTitles] = useState("");
  const [publishDate, setPublishDate] = useState("");
  const [generatedLinks, setGeneratedLinks] = useState([]); // State to store the generated links
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Add SEO - Internify Job Portal";
  }, []);

  const handleAddSEO = async (e) => {
    e.preventDefault();
    
    const cityArray = cities.split(',').map(city => city.trim());
    const jobTitleArray = jobTitles.split(',').map(jobTitle => jobTitle.trim());

    const newLinks = []; // Array to store the new links

    for (const city of cityArray) {
      for (const jobTitle of jobTitleArray) {
        const pageTitle = `${jobTitle} Jobs in ${city} - Internify`;
        const metaDescription = `Find the latest ${jobTitle.toLowerCase()} jobs in ${city} at Internify. Explore top job opportunities for ${jobTitle.toLowerCase()} professionals in various industries. Apply now and advance your career!`;
        const metaKeywords = `${jobTitle.toLowerCase()} jobs, ${city} jobs, ${jobTitle.toLowerCase()} careers, ${jobTitle.toLowerCase()} openings, ${jobTitle.toLowerCase()} vacancies, Internify jobs, ${city} ${jobTitle.toLowerCase()} jobs`;
        const seoContent = `<p>Are you looking for ${jobTitle.toLowerCase()} job opportunities in ${city}? Internify offers a wide range of ${jobTitle.toLowerCase()} jobs in various industries. Our platform connects you with top companies seeking skilled ${jobTitle.toLowerCase()} professionals. Apply now and take the next step in your career!</p>`;
        const pageIdentifier = `${jobTitle.toLowerCase().replace(/\s+/g, '-')}-jobs-${city.toLowerCase().replace(/\s+/g, '-')}`;

        await addDoc(collection(db, "seo"), {
          pageTitle,
          metaDescription,
          metaKeywords,
          seoContent,
          publishDate,
          pageIdentifier,
          date: serverTimestamp(),
        });

        // Add the generated link to the newLinks array
        newLinks.push(`https://jobs.synergyworks.online/jobs-list?seo=${pageIdentifier}`);
      }
    }

    setGeneratedLinks(newLinks); // Update the state with the new links
  };

  return (
    <>
      <div className="max-w-4xl mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">Add SEO</h1>
        <form onSubmit={handleAddSEO}>
          <textarea
            className="border p-2 w-full mb-2"
            placeholder="Enter Cities (comma separated)"
            value={cities}
            onChange={(e) => setCities(e.target.value)}
          />
          <textarea
            className="border p-2 w-full mb-2"
            placeholder="Enter Job Titles (comma separated)"
            value={jobTitles}
            onChange={(e) => setJobTitles(e.target.value)}
          />
          <label className="block mb-2">
            Publish Date:
            <input
              className="border p-2 w-full"
              type="date"
              value={publishDate}
              onChange={(e) => setPublishDate(e.target.value)}
            />
          </label>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded"
            type="submit"
          >
            Add SEO
          </button>
        </form>
        <div className="mt-4">
          <h2 className="text-xl font-bold mb-2">Generated Links:</h2>
          <ul>
            {generatedLinks.map((link, index) => (
              <li key={index}>
                <a href={link} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                  {link}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default AddSEO;
