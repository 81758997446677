import React from 'react';

const About = () => {
  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">About Us</h1>
      <p className="mb-4">
        Welcome to <strong>Internify</strong>, your trusted job portal for the best internship and job opportunities. Our mission is to bridge the gap between talented individuals and the right career opportunities, providing a platform where aspiring professionals can find the internships and jobs that align with their skills and career goals.
      </p>
      
      <h2 className="text-2xl font-bold mb-4">Our Vision</h2>
      <p className="mb-4">
        At <strong>Internify</strong>, we envision a world where every individual has access to the best career opportunities, regardless of their location or background. We aim to empower job seekers by connecting them with companies that value talent, passion, and potential.
      </p>

      <h2 className="text-2xl font-bold mb-4">What We Offer</h2>
      <p className="mb-4">
        Our platform offers a wide range of internships and job listings across various industries, ensuring that you can find the opportunity that best suits your needs. Whether you're a student looking for an internship or a professional seeking a new job, <strong>Internify</strong> is here to help you every step of the way.
      </p>

      <h2 className="text-2xl font-bold mb-4">Our Commitment</h2>
      <p className="mb-4">
        We are committed to providing a user-friendly and efficient platform for job seekers and employers alike. Our team is dedicated to ensuring that our users have access to the latest job listings and that employers can easily find the right candidates for their roles.
      </p>

      <h2 className="text-2xl font-bold mb-4">Get in Touch</h2>
      <p className="mb-4">
        If you have any questions, suggestions, or feedback, feel free to reach out to us. We’re here to assist you and ensure that you have the best experience on our platform.
      </p>
    </div>
  );
};

export default About;
