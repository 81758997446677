import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Terms and Conditions</h1>
      <p className="mb-4">
        Welcome to Internify. By accessing or using our job portal, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.
      </p>

      <h2 className="text-2xl font-bold mb-4">Beta Disclaimer</h2>
      <p className="mb-4">
        Please note that the Internify Job Portal is currently in beta. As such, you may experience occasional bugs, glitches, or other issues as we continue to develop and improve our platform. We appreciate your understanding and feedback during this phase.
      </p>
      
      <h2 className="text-2xl font-bold mb-4">1. Acceptance of Terms</h2>
      <p className="mb-4">
        By accessing and using the Internify job portal, you accept and agree to be bound by the terms and provisions of this agreement. In addition, when using this job portal's particular services, you shall be subject to any posted guidelines or rules applicable to such services.
      </p>
      
      <h2 className="text-2xl font-bold mb-4">2. Description of Service</h2>
      <p className="mb-4">
        Internify provides users with access to job listings, recruitment services, and other resources related to employment opportunities. All services provided by Internify are for personal and non-commercial use only.
      </p>
      
      <h2 className="text-2xl font-bold mb-4">3. User Responsibilities</h2>
      <p className="mb-4">
        You are responsible for your use of the job portal, and for any use of the job portal made using your account. You agree not to use the job portal for any unlawful or prohibited purpose. You agree to comply with all local rules regarding online conduct and acceptable content.
      </p>
      <p className="mb-4">
        Specifically, you agree not to:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Use the job portal for any illegal activities or in violation of any laws.</li>
        <li>Post any incomplete, false, or inaccurate information about yourself or your organization.</li>
        <li>Engage in any activity that could harm or interfere with the operation of the job portal.</li>
        <li>Use any automated system or software to extract data from the job portal for commercial purposes.</li>
        <li>Harass, abuse, or harm another person, including sending unwelcomed communications to others.</li>
      </ul>
      
      <h2 className="text-2xl font-bold mb-4">4. Account Registration</h2>
      <p className="mb-4">
        To access certain features of the job portal, you may need to register for an account. When you register, you agree to provide accurate, current, and complete information. You are responsible for safeguarding your account information and for any activity that occurs under your account.
      </p>
      <p className="mb-4">
        Your responsibilities include:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Maintaining the confidentiality of your login credentials.</li>
        <li>Notifying us immediately of any unauthorized use of your account or any other breach of security.</li>
        <li>Ensuring that you log out from your account at the end of each session.</li>
      </ul>
      
      <h2 className="text-2xl font-bold mb-4">5. Privacy Policy</h2>
      <p className="mb-4">
        Your use of the job portal is also governed by our Privacy Policy. Please review our Privacy Policy to understand our practices regarding the collection, use, and disclosure of your personal information.
      </p>
      
      <h2 className="text-2xl font-bold mb-4">6. Job Listings</h2>
      <p className="mb-4">
        Internify does not guarantee the accuracy, completeness, or usefulness of any job listings, nor does it adopt, endorse, or accept responsibility for the accuracy or reliability of any job listings posted by users or third parties.
      </p>
      <p className="mb-4">
        We are not responsible for:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>The content of any job listings, including the legality, reliability, or accuracy of the job listings.</li>
        <li>Any employment decisions made by users based on the job listings provided on the job portal.</li>
        <li>Any direct or indirect damages or loss caused by reliance on any job listings available on the job portal.</li>
      </ul>

      <h2 className="text-2xl font-bold mb-4">7. External Job Listings</h2>
      <p className="mb-4">
        Some job listings on Internify Job Portal may originate from external job boards such as Naukri, LinkedIn, and others. We do not have control over these external job listings and do not claim any copyright over the content provided by these external sources. Our intention is to facilitate job searches by aggregating listings from various sources for your convenience.
      </p>
      <p className="mb-4">
        If you are a copyright holder and believe that any content on our site infringes on your copyrights, please contact us at <a href="mailto:onboarding@internify.online" className="text-blue-500">onboarding@internify.online</a>. We will promptly address your concerns and remove any infringing content upon verification.
      </p>
      
      <h2 className="text-2xl font-bold mb-4">8. Intellectual Property</h2>
      <p className="mb-4">
        All content on the job portal, including text, graphics, logos, and images, is the property of Internify or its content suppliers and is protected by applicable intellectual property laws.
      </p>
      <p className="mb-4">
        You may not:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Copy, reproduce, modify, create derivative works from, distribute, or publicly display any content from the job portal without our prior written consent.</li>
        <li>Use any trademarks, logos, or other proprietary information (including images, text, page layout, or form) of Internify without our express written consent.</li>
      </ul>
      
      <h2 className="text-2xl font-bold mb-4">9. Termination</h2>
      <p className="mb-4">
        Internify reserves the right to terminate your access to the job portal, without any advance notice, for conduct that we believe violates these Terms and Conditions or is harmful to other users of the job portal, us, or third parties, or for any other reason.
      </p>
      <p className="mb-4">
        Upon termination:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>You will no longer have access to your account or any content you have posted on the job portal.</li>
        <li>Any rights granted to you under these Terms and Conditions will immediately cease.</li>
        <li>We reserve the right to delete or deactivate your account and all related information and files.</li>
      </ul>
      
      <h2 className="text-2xl font-bold mb-4">10. Changes to Terms</h2>
      <p className="mb-4">
        We reserve the right to update or modify these Terms and Conditions at any time without prior notice. Your continued use of the job portal after any such changes constitutes your acceptance of the new Terms and Conditions.
      </p>
      <p className="mb-4">
        Changes to these terms may include, but are not limited to:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Updates to our services and features.</li>
        <li>Changes in applicable laws or regulations.</li>
        <li>Adjustments for technical or security reasons.</li>
      </ul>
      
      <h2 className="text-2xl font-bold mb-4">11. Disclaimer of Warranties</h2>
      <p className="mb-4">
        The job portal is provided on an "as is" and "as available" basis. We make no representations or warranties of any kind, express or implied, as to the operation of the job portal or the information, content, materials, or products included on the job portal.
      </p>
      <p className="mb-4">
        To the fullest extent permissible by applicable law, we disclaim all warranties, express or implied, including, but are not limited to, implied warranties of merchantability and fitness for a particular purpose.
      </p>
      
      <h2 className="text-2xl font-bold mb-4">12. Limitation of Liability</h2>
      <p className="mb-4">
        In no event shall Internify, its affiliates, or their respective officers, directors, employees, agents, suppliers, or licensors be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the job portal.
      </p>
      
      <h2 className="text-2xl font-bold mb-4">13. Indemnification</h2>
      <p className="mb-4">
        You agree to indemnify, defend, and hold harmless Internify, its affiliates, officers, directors, employees, agents, suppliers, and licensors from and against any claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys' fees) that such parties may incur as a result of or arising from your (or anyone using your account's) violation of these Terms and Conditions.
      </p>
      
      <h2 className="text-2xl font-bold mb-4">14. Governing Law</h2>
      <p className="mb-4">
        These Terms and Conditions and any disputes related to these Terms and Conditions or the job portal will be governed by and construed in accordance with the laws of India, without regard to its conflict of law principles.
      </p>
      
      <h2 className="text-2xl font-bold mb-4">15. Entire Agreement</h2>
      <p className="mb-4">
        These Terms and Conditions, together with our Privacy Policy, constitute the entire agreement between you and Internify regarding the use of the job portal, superseding any prior agreements between you and Internify relating to your use of the job portal.
      </p>
      
      <h2 className="text-2xl font-bold mb-4">16. Severability</h2>
      <p className="mb-4">
        If any provision of these Terms and Conditions is found to be invalid or unenforceable by a court of competent jurisdiction, such provision will be enforced to the maximum extent permissible, and the remaining provisions of these Terms and Conditions will remain in full force and effect.
      </p>
      
      <h2 className="text-2xl font-bold mb-4">17. Waiver</h2>
      <p className="mb-4">
        No waiver of any term of these Terms and Conditions will be deemed a further or continuing waiver of such term or any other term, and Internify's failure to assert any right or provision under these Terms and Conditions will not constitute a waiver of such right or provision.
      </p>
      
      <h2 className="text-2xl font-bold mb-4">18. Assignment</h2>
      <p className="mb-4">
        You may not assign your rights or obligations under these Terms and Conditions without our prior written consent. We may assign our rights and obligations under these Terms and Conditions at any time without notice to you.
      </p>
      
      <h2 className="text-2xl font-bold mb-4">19. Third-Party Links</h2>
      <p className="mb-4">
        The job portal may contain links to third-party websites or services that are not owned or controlled by Internify. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You further acknowledge and agree that Internify shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods, or services available on or through any such websites or services.
      </p>
      
      <h2 className="text-2xl font-bold mb-4">20. Communications</h2>
      <p className="mb-4">
        By creating an account on our job portal, you agree to subscribe to newsletters, marketing or promotional materials, and other information we may send. However, you may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.
      </p>
      
      <h2 className="text-2xl font-bold mb-4">21. Use of Data for Marketing and Analytics</h2>
      <p className="mb-4">
        We may use your data for marketing purposes, such as sending you promotional emails or notifications about our products and services. We also use analytics tools, including but not limited to Microsoft Clarity, Google Analytics, Tawk Chat Bot, and web push notifications, to understand user behavior and improve our platform. These tools collect information such as your IP address, browser type, pages visited, and other usage data.
      </p>
      <p className="mb-4">
        For marketing purposes, we may:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Analyze your preferences and behavior to tailor marketing messages.</li>
        <li>Use cookies and similar technologies to track your interactions with our site and ads.</li>
        <li>Share data with third-party marketing partners to improve our advertising efforts.</li>
      </ul>

      <h2 className="text-2xl font-bold mb-4">22. Advertising Disclaimer</h2>
      <p className="mb-4">
        Our website relies on ad revenue. If there are any misleading ads displayed, we are not responsible. However, reporting such ads can help us to avoid them in the future. Please note that we depend on third-party advertisers and therefore have no control over the ads displayed.
      </p>
      
      <h2 className="text-2xl font-bold mb-4">Contact Information</h2>
      <p className="mb-4">
        If you have any questions about these Terms and Conditions, please contact us at:
        <a href="mailto:onboarding@internify.online" className="text-blue-500"> onboarding@internify.online</a>
      </p>
      <p className="mb-4">
        For any further assistance, please feel free to reach out to our customer support team through the contact information provided above. We are committed to addressing your concerns and ensuring the best possible experience with our job portal.
      </p>
      <p className="mb-4">
        We value your feedback and are always here to help with any questions or concerns you may have about our terms and conditions.
      </p>
    </div>
  );
};

export default TermsAndConditions;
