import React, { useEffect, useState } from 'react';
import { db } from '../firebase/firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import moment from 'moment-timezone';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [todaysUsers, setTodaysUsers] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [changeFromYesterday, setChangeFromYesterday] = useState(0);
  const [experienceKPI, setExperienceKPI] = useState({
    freshers: 0,
    zeroToOne: 0,
    oneToTwo: 0,
    twoPlus: 0,
  });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        // Fetch all user documents
        const querySnapshot = await getDocs(collection(db, 'users'));
        const usersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Log total users fetched
        console.log(`Total users fetched: ${usersData.length}`);

        // Filter out users with invalid registration dates
        const validUsers = usersData.filter(user => moment(user.registrationDate, moment.ISO_8601, true).isValid());

        // Log total valid users
        console.log(`Total valid users: ${validUsers.length}`);

        // Sort users by registration date (newest first)
        validUsers.sort((a, b) => new Date(b.registrationDate) - new Date(a.registrationDate));

        // Calculate KPIs using IST
        const today = moment.tz('Asia/Kolkata').startOf('day');
        const yesterday = moment.tz('Asia/Kolkata').subtract(1, 'days').startOf('day');
        const todaysUsersCount = validUsers.filter(user =>
          moment.tz(user.registrationDate, 'Asia/Kolkata').isSame(today, 'day')
        ).length;
        const yesterdaysUsersCount = validUsers.filter(user =>
          moment.tz(user.registrationDate, 'Asia/Kolkata').isSame(yesterday, 'day')
        ).length;

        // Normalize experience data and log each user's experience for debugging
        validUsers.forEach((user, index) => {
          const normalizedExperience = user.experience?.trim().toLowerCase() || '';
          console.log(`User ${index + 1}: ${user.name || user.username}, Experience: "${normalizedExperience}"`);
        });

        // Calculate experience-based KPIs
        const experienceCounts = {
          freshers: validUsers.filter(user => user.experience?.trim().toLowerCase() === 'freshers').length,
          zeroToOne: validUsers.filter(user => user.experience?.trim().toLowerCase() === '0-1 yrs').length,
          oneToTwo: validUsers.filter(user => user.experience?.trim().toLowerCase() === '1-2 yrs').length,
          twoPlus: validUsers.filter(user => user.experience?.trim().toLowerCase() === '2+ yrs').length,
        };

        // Log experience data for debugging
        console.log("Experience KPI Data: ", experienceCounts);

        // Set state with the results
        setUsers(validUsers);
        setTodaysUsers(todaysUsersCount);
        setTotalUsers(validUsers.length);
        setChangeFromYesterday(
          yesterdaysUsersCount > 0
            ? (((todaysUsersCount - yesterdaysUsersCount) / yesterdaysUsersCount) * 100).toFixed(2)
            : 0
        );
        setExperienceKPI(experienceCounts);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  return (
    <div className="container mx-auto px-4 sm:px-8">
      <h1 className="text-3xl font-bold mb-4">Registered Users</h1>
      <div className="mb-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="p-4 bg-blue-500 text-white rounded-lg shadow-sm flex justify-between items-center">
          <div>
            <div className="text-sm font-semibold">Today's Registrations</div>
            <div className="text-3xl font-bold">{todaysUsers}</div>
            <div className="text-xs">
              {changeFromYesterday !== 0 ? (
                <span>{changeFromYesterday}% from yesterday</span>
              ) : (
                <span>No data for yesterday</span>
              )}
            </div>
          </div>
        </div>
        <div className="p-4 bg-blue-500 text-white rounded-lg shadow-sm flex justify-between items-center">
          <div>
            <div className="text-sm font-semibold">Total Registrations</div>
            <div className="text-3xl font-bold">{totalUsers}</div>
          </div>
        </div>
        <div className="p-4 bg-blue-500 text-white rounded-lg shadow-sm flex justify-between items-center">
          <div>
            <div className="text-sm font-semibold">Experience Levels</div>
            <div className="text-sm">Freshers: {experienceKPI.freshers}</div>
            <div className="text-sm">0-1 yrs: {experienceKPI.zeroToOne}</div>
            <div className="text-sm">1-2 yrs: {experienceKPI.oneToTwo}</div>
            <div className="text-sm">2+ yrs: {experienceKPI.twoPlus}</div>
          </div>
        </div>
      </div>
      <div className="py-8">
        <div className="overflow-x-auto">
          <div className="min-w-full shadow rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Email
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Location
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Phone Number
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Registration Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => (
                  <tr key={user.id}>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">{user.username || user.name}</p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">{user.email}</p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">{user.location}</p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">{user.phone}</p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {moment.tz(user.registrationDate, 'Asia/Kolkata').isValid()
                          ? moment.tz(user.registrationDate, 'Asia/Kolkata').format('Do MMMM YYYY, h:mm a')
                          : 'Invalid date'}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
