import React, { useEffect, useState } from 'react';
import { db } from '../firebase/firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';

const Home = () => {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const fetchJobs = async () => {
      const querySnapshot = await getDocs(collection(db, 'jobs'));
      const jobsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setJobs(jobsData);
    };

    fetchJobs();
  }, []);

  return (
    <div className="p-4">
      <h1 className="text-3xl font-bold mb-4">Job Listings</h1>
      {jobs.map(job => (
        <div key={job.id} className="mb-4 p-4 border rounded">
          <h2 className="text-xl font-bold"><Link to={`/job/${job.id}`}>{job.title}</Link></h2>
          <p>{job.company}</p>
          <p>{job.location}</p>
          <p>{job.salary}</p>
        </div>
      ))}
    </div>
  );
};

export default Home;
