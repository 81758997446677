// src/components/ReelLayout.js
import React from 'react';
import { Outlet } from 'react-router-dom';

const ReelLayout = () => {
  return (
    <div className="w-full h-full overflow-hidden">
      <Outlet />
    </div>
  );
};

export default ReelLayout;
